import React from 'react'
import {
  //   Filter,
  List,
  //   SearchInput,
  Datagrid,
  DateField,
  TextField,
  FunctionField,
  TopToolbar,
  // EditButton,
} from 'react-admin'
import { Box } from '@material-ui/core'
import CreditAdd from './CreditAdd'

const ListActions = (props) => {
  const { className, appUserId } = props
  return (
    <TopToolbar className={className}>
      <CreditAdd appUserId={appUserId} />
    </TopToolbar>
  )
}

// export const OrganisationsFilter = (props) => (
//   <Filter {...props}>
//     <SearchInput
//       // label="Name/Sku"
//       source="name"
//       alwaysOn
//       variant="standard"
//       fullWidth
//       placeholder="Credit Type"
//       style={{ minWidth: '200px' }}
//     />
//   </Filter>
// )

const AppUserCreditList = (props) => {
  const { appUserId, ...rest } = props

  return (
    <Box p="10px 60px">
      <div>Credit List</div>
      <List
        {...rest}
        resource="app_user_credit"
        // filters={<OrganisationsFilter />}
        filter={{ appUserId }}
        perPage={25}
        actions={<ListActions appUserId={appUserId} />}
        empty={false}
        // bulkActionButtons={false}
        sort={{ field: 'id', order: 'DESC' }}
      >
        <Datagrid optimized path="app_users">
          <TextField source="id" />
          <TextField source="type" label="Type" />
          <TextField source="code" label="Code" />
          <TextField source="description" label="Description" />
          <FunctionField
            label="Amount"
            render={(record) => `$${(record.amount / 100).toFixed(2)}`}
          />
          <FunctionField
            label="Initial Amount"
            render={(record) => `$${(record.initial_amount / 100).toFixed(2)}`}
          />
          <DateField source="created_at" label="Created date" type="date" />
          <DateField source="expires_at" label="Expiry date" type="date" />
        </Datagrid>
      </List>
    </Box>
  )
}

export default AppUserCreditList
