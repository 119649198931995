import React, { useState, useEffect } from 'react'
import {
  Chip,
  Tooltip,
  Button,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Select,
  MenuItem,
  InputLabel,
} from '@material-ui/core'
import {
  List,
  Datagrid,
  FunctionField,
  TextField,
  Filter,
  SearchInput,
  ReferenceInput,
  SelectInput,
  DateInput,
  DateField,
  useDataProvider,
  useNotify,
} from 'react-admin'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import SupplierDefaultImg from '../../../../assets/supplier_default.png'
import { getDisplayName, getDisplayAddress } from '../../../../utils/utils'
import CheckoutForm from '../offerDetailsTab/checkout'

const OfferSimulator = (props) => {
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [clientSecret, setClientSecret] = useState('')
  const [appUser, setAppUser] = useState('')
  const [client, setClient] = useState('')
  const [billingAddress, setBillingAddress] = useState('')
  const [deliveryAddress, setDeliveryAddress] = useState('')
  const [appUsers, setAppUsers] = useState([])
  const [clients, setClients] = useState([])
  const [clientAddresses, setClientAddresses] = useState([])

  //   console.log('APPUSER', appUser, 'CLIENT', client, 'BADD', billingAddress, 'DADD', deliveryAddress)

  window.history.replaceState({}, document.title, '/#/simulator')

  // const [stripePromise, setStripePromise] = useState(
  //   loadStripe(
  //     'pk_test_51O0bpuG3YCdXCMUgwPE4XhbWpm3vK2tFli1MCd1V1Y19Xal9TgWEAb1zbu1quX2L5qzzjRWVfnQxbIDtmPpSEywW00hzmcxsnx',
  //   ),
  // )

  const [stripePromise, setStripePromise] = useState(
    loadStripe('pk_test_7BPkJ7gmF2ZhluSHZXLIAVNz00grdjoD2D'),
  )

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const appearance = {
    theme: 'stripe',
  }
  const options = {
    clientSecret,
    appearance,
  }

  useEffect(() => {
    return () => {}
  }, [])

  useEffect(() => {
    const getData = async () => {
      try {
        const { data: userData } = await dataProvider.getAppUsers()
        !abortController.signal.aborted && setAppUsers(userData.app_user)
      } catch (error) {
        console.log(error)
      }
    }
    const abortController = new AbortController()
    getData()
    return () => {
      abortController.abort()
    }
  }, [dataProvider, notify])

  useEffect(() => {
    const getData = async () => {
      try {
        const { data: clientsTemp } = await dataProvider.getList('organisation', {
          pagination: { page: 1, perPage: 100 },
          sort: { field: 'name', order: 'ASC' },
          filter: { member_id: appUser.id },
        })
        !abortController.signal.aborted && setClients(clientsTemp)
      } catch (error) {
        console.log(error)
      }
    }
    const abortController = new AbortController()
    getData()
    return () => {
      abortController.abort()
    }
  }, [dataProvider, notify, appUser])

  useEffect(() => {
    const getData = async () => {
      try {
        const { data: clientsTemp } = await dataProvider.getList('address', {
          pagination: { page: 1, perPage: 100 },
          sort: { field: 'type', order: 'ASC' },
          filter: { organisation_id: client.id },
        })
        !abortController.signal.aborted && setClientAddresses(clientsTemp)
      } catch (error) {
        console.log(error)
      }
    }
    const abortController = new AbortController()
    getData()
    return () => {
      abortController.abort()
    }
  }, [dataProvider, notify, appUser, client])

  // console.log(appUser)

  const getPI = async ({ clientQuoteId, quantity }) => {
    // console.log('GETPI', offerId, quantity, open)
    await dataProvider
      .getPaymentIntent('catalogue', {
        billingAddressId: billingAddress.id,
        clientQuoteId: clientQuoteId,
        deliveryAddressId: deliveryAddress.id,
        notes: '',
        quantity,
        shippingMethod: 'Sea Freight',
        buyerId: appUser.id,
        clientId: client.id,
        items: [
          {
            clientQuoteId,
            variants: [
              {
                quantity: parseInt(quantity / 3),
                options: [
                  {
                    option_id: 526,
                    value: 'S',
                  },
                ],
              },
              {
                quantity: parseInt(quantity / 3),
                options: [
                  {
                    option_id: 526,
                    value: 'M',
                  },
                ],
              },
              {
                quantity: quantity - parseInt(quantity / 3) * 2,
                options: [
                  {
                    option_id: 526,
                    value: 'L',
                  },
                ],
              },
            ],
          },
        ],
      })
      .then((result) => {
        // console.log('Success:', result)
        setClientSecret(result.data.paymentIntentSecret)
      })
      .catch((error) => {
        console.error('Error:', error)
        notify(error.message.split('.')[0], { type: 'warning' })
      })
    // console.log(res)
  }

  useEffect(() => {
    const setStripe = async () => {
      // const stripePromise = loadStripe(
      //   'pk_test_51O0bpuG3YCdXCMUgwPE4XhbWpm3vK2tFli1MCd1V1Y19Xal9TgWEAb1zbu1quX2L5qzzjRWVfnQxbIDtmPpSEywW00hzmcxsnx',
      // )
      const stripePromise = loadStripe('pk_test_7BPkJ7gmF2ZhluSHZXLIAVNz00grdjoD2D')
      setStripePromise(stripePromise)
    }
    setStripe()
  }, [])

  const ProductFilter = (props) => (
    <Filter {...props}>
      <SearchInput
        // label="Name/Sku"
        source="name"
        alwaysOn
        variant="standard"
        fullWidth
        placeholder="Product Name"
        style={{ minWidth: '200px' }}
      />
      <ReferenceInput
        source="supplier"
        reference="suppliers"
        // sort={{ field: 'id', order: 'ASC' }}
        alwaysOn
        style={{ minWidth: '200px' }}
        variant="standard"
      >
        <SelectInput optionText="name" fullWidth />
      </ReferenceInput>
      <ReferenceInput
        source="sca"
        reference="users"
        label="SCA"
        // sort={{ field: 'id', order: 'ASC' }}
        filter={{ roles: { role: { code: { _in: ['sca', 'sca-manager'] } } } }}
        style={{ minWidth: '200px' }}
        alwaysOn
        variant="standard"
      >
        <SelectInput optionText={(val) => getDisplayName(val)} fullWidth />
      </ReferenceInput>
      <ReferenceInput
        source="category"
        reference="category"
        // sort={{ field: 'id', order: 'ASC' }}
        style={{ minWidth: '200px' }}
        alwaysOn
        variant="standard"
      >
        <SelectInput optionText="name" fullWidth />
      </ReferenceInput>
      <DateInput
        source="date_gte"
        label="Quote date after"
        alwaysOn
        variant="standard"
        fullWidth
        style={{ minWidth: '120px' }}
      />
      <DateInput
        source="date_lte"
        label="Quote date before"
        alwaysOn
        variant="standard"
        fullWidth
        style={{ minWidth: '120px' }}
      />
    </Filter>
  )

  return (
    <Box>
      <span> Purchase Parameters</span>
      <Box display="flex" flexDirection="row">
        <InputLabel
          style={{ marginTop: '10px', fontSize: '10px', marginLeft: '20px' }}
          id="appUserLabel"
        >
          User:
        </InputLabel>
        <Select
          style={{ width: '250px' }}
          value={appUser}
          labelId="appUserLabel"
          renderValue={(item) => getDisplayName(item, 20, 20, false)}
          onChange={(e) => setAppUser(e.target.value)}
        >
          {appUsers?.map((item) => (
            <MenuItem value={item} key={item.id}>
              {getDisplayName(item, 20, 20, true)}
            </MenuItem>
          ))}
        </Select>
        <InputLabel
          style={{ marginTop: '10px', fontSize: '10px', marginLeft: '20px' }}
          id="clientLabel"
        >
          Client:
        </InputLabel>
        <Select
          labelId="clientLabel"
          style={{ width: '250px', marginLeft: '25px' }}
          value={client}
          //   renderValue={(item) => getDisplayAddress(item)}
          onChange={(e) => setClient(e.target.value)}
        >
          {clients?.map((item) => (
            <MenuItem value={item} key={item.id}>
              {item?.name}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box display="flex" flexDirection="row">
        <InputLabel
          style={{ marginTop: '10px', fontSize: '10px', marginLeft: '20px' }}
          id="billingAddressLabel"
        >
          Billing address:
        </InputLabel>
        <Select
          labelId="billingAddressLabel"
          style={{ width: '300px', marginLeft: '25px' }}
          value={billingAddress}
          renderValue={(item) => getDisplayAddress(item)}
          onChange={(e) => setBillingAddress(e.target.value)}
        >
          {clientAddresses?.map((item) => (
            <MenuItem value={item} key={item.id}>
              {getDisplayAddress(item)}
            </MenuItem>
          ))}
        </Select>
        <InputLabel
          style={{ marginTop: '10px', fontSize: '10px', marginLeft: '20px' }}
          id="deliveryAddressLabel"
        >
          Delivery address:
        </InputLabel>
        <Select
          labelId="deliveryAddressLabel"
          style={{ width: '300px', marginLeft: '25px' }}
          value={deliveryAddress}
          renderValue={(item) => getDisplayAddress(item)}
          onChange={(e) => setDeliveryAddress(e.target.value)}
        >
          {clientAddresses?.map((item) => (
            <MenuItem value={item} key={item.id}>
              {getDisplayAddress(item)}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <List
        {...props}
        basePath="/simulator"
        resource="products"
        filter={{
          status: 'PRIVATE',
          is_catalogue: true,
          is_active: true,
        }}
        filters={props.basePath === '/products' ? <ProductFilter /> : null}
        actions={false}
        perPage={25}
        sort={{ field: 'id', order: 'DESC' }}
        empty={false}
        bulkActionButtons={false}
      >
        <Datagrid
          path="products"
          //   rowClick={(id, resource, record) => (record?.is_catalogue ? null : 'edit')}
          //   rowStyle={rowStyle}
        >
          <TextField source="id" label="Id" />
          <FunctionField
            label="Photo"
            render={(record) => (
              <img
                style={{ maxHeight: '50px' }}
                src={record.thumbnail_photo?.url || SupplierDefaultImg}
                // alt=""
                alt={<img src={SupplierDefaultImg} alt="" />}
              />
            )}
          />
          <FunctionField
            label="Product Name"
            render={(record) => {
              const name = record.name
              return name?.length > 40 ? (
                <Tooltip title={name}>
                  <span>{`${name?.substring(0, 40)}...`}</span>
                </Tooltip>
              ) : (
                name
              )
            }}
          />
          <FunctionField
            label="Categories"
            render={(record) =>
              record.product_categories?.map((cat) => (
                <Chip key={`cats${cat.id}`} label={cat.category.name} />
              ))
            }
          />
          {/* <TextField source="status" label="Status" /> */}
          <TextField
            source="product_quotes[0].scm_quotes[0].prices[0].quantity"
            label="MOQ"
            sortable={false}
          />
          <FunctionField
            render={(record) =>
              record.product_quotes?.length > 0 &&
              record.product_quotes[0].scm_quotes?.length > 0 &&
              record.product_quotes[0].scm_quotes[0].prices?.length > 0
                ? `$ ${(record.product_quotes[0].prices[0].unit_price / 100).toFixed(2)}`
                : ''
            }
            label="Price"
          />
          {/* <TextField source="product_quotes[0].prices[0].unit_price" label="Price" /> */}
          <FunctionField render={(record) => getDisplayName(record.sca)} label="SCA" />
          <DateField source="created_at" label="Created Date" />
          <FunctionField
            render={(record) => (
              <Button
                disabled={!appUser || !client || !deliveryAddress || !billingAddress}
                onClick={async (e) => {
                  e.stopPropagation()
                  // console.log(record)
                  handleOpen()
                  setLoading(true)

                  await getPI({
                    offerId: record.id,
                    clientQuoteId: record.product_quotes[0].scm_quotes[0].id,
                    quantity:
                      record.product_quotes?.length > 0 &&
                      record.product_quotes[0].scm_quotes?.length > 0 &&
                      record.product_quotes[0].scm_quotes[0].prices?.length > 0
                        ? record.product_quotes[0].scm_quotes[0].prices[0].quantity
                        : 0,
                  })
                  setLoading(false)
                }}
              >
                Purchase
              </Button>
            )}
          />
          <MakePurchaseDialog
            open={open}
            handleClose={handleClose}
            clientSecret={clientSecret}
            options={options}
            stripePromise={stripePromise}
            loading={loading}
          />
        </Datagrid>
      </List>
    </Box>
  )
}

const MakePurchaseDialog = ({
  open,
  handleClose,
  clientSecret,
  options,
  stripePromise,
  loading,
}) => {
  //   console.log(!!clientSecret, options, stripePromise)
  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogContent>
        {loading ? (
          <Box>Loading ... </Box>
        ) : (
          <Box>
            <DialogTitle>Make a Purchase</DialogTitle>
            {clientSecret && open && (
              <Elements options={options} stripe={stripePromise}>
                <CheckoutForm />
              </Elements>
            )}
          </Box>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default OfferSimulator
