import gql from 'graphql-tag'

const consumerOrderGetQuery = `
  id
  total_amount
  status
  platform
  contact_name
  contact_email
  contact_phone
  delivery_address
  total_quantity
  created_at
  updated_at
  order_number
  tracking_url
  tracking_number
  invoices {
    id
    invoice {
      id
      amount_due
      status
      invoice_payments {
        id
        amount
        status
      }
    }
  }
  seller {
    id
    forename
    surname
    email
  }
  consumer {
    id
    forename
    surname
    email
  }
  itemsCount: items_aggregate {
    aggregate { count }
  }
  items {
    id
    quantity
    amount
    saleable_product_sale_allocations {
      id
      quantity
      profit
      amount
      offer_id
      organisation_inventory {
        id
        owner {
          id
          name
        }
      }
    }
    seller {
      id
      forename
      surname
      email
    }
    product_variant {
      id
      variant {
        id
        sku
        values {
          id
          option_name
          value
          option{
            id
            name
          }
          option_value {
            id
            value
          }
        }
      }
    }                  
    saleable_product {
      id
      name
      description
      sku
      status
      created_at
      brand
      primary_photo {
        id
        filename
        url
      }
    }
  }
`

export const consumerOrderDelete = ({ context, params }) => {
  return {
    ...context,
    variables: {
      ...params,
    },
    query: gql`
          mutation deleteOrder {
            delete_saleable_product_sale_allocation(where: {saleable_product_sale: {order_id: {_eq: ${params.id}}}}) {
              affected_rows
            }
            delete_saleable_product_sale_transfer(where: {saleable_product_sale: {order_id: {_eq: ${params.id}}}}) {
              affected_rows
            }
            delete_saleable_product_sale(where: {order_id: {_eq: ${params.id}}}) {
              affected_rows
            }
            delete_consumer_order(where: {id: {_eq: ${params.id}}}) {
              affected_rows
            }
          }
        `,
    parseResponse: ({ data, errors }) => ({
      data,
      errors,
    }),
  }
}

export const consumerOrderGet = ({ context, params }) => {
  // const validUntil = new Date()
  // // validUntil.setTime(validUntil.getTime() + 300000) // 5 minutes

  return {
    ...context,
    variables: {
      // ...params,
    },
    query: gql`
      query getConsumerOrder {
        consumer_order_by_pk(id: ${params.id}) {
          ${consumerOrderGetQuery}
        }
      }
    `,
    parseResponse: ({ data }) => {
      if (data && data.consumer_order_by_pk) {
        // console.log("data", data)
        return {
          data: data.consumer_order_by_pk,
          // validUntil,
        }
      }
      return false
    },
  }
}

export const consumerOrderList = ({ context, params }) => {
  const offset = (params.pagination.page - 1) * params.pagination.perPage
  const variables = {}

  if (params.filter.status || params.id || params.filter.type) {
    variables.where = {
      ...(params.filter.id && { id: { _eq: params.filter.id } }),
    }
  }

  return {
    ...context,
    variables,
    query: gql`
      query getConsumerOrders ($where: consumer_order_bool_exp) {
       consumer_order(where: $where, limit: ${
         params.pagination.perPage
       }, offset: ${offset}, order_by: { ${
      params.sort.field
    }: ${params.sort.order.toLowerCase()} }) {
            ${consumerOrderGetQuery}
          }
          consumer_order_aggregate(where: $where) {
            aggregate {
              count
            }
          }
        }
    `,
    parseResponse: ({ data }) => {
      if (data?.consumer_order) {
        // console.log("data", data)
        return {
          data: data.consumer_order,
          total: data.consumer_order_aggregate.aggregate.count,
          // validUntil,
        }
      }
      return false
    },
  }
}
