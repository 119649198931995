import React, { useState } from 'react'
import {
  useDataProvider,
  useNotify,
  useRefresh,
  FormWithRedirect,
  SelectInput,
  TextInput,
  DateInput,
  required,
} from 'react-admin'
import {
  Box,
  Button,
  Dialog,
  //   DialogTitle,
  DialogActions,
  DialogContent,
} from '@material-ui/core'
import Confirm from '../../components/Confirm'
import NumberFormat from '../../components/CustomNumberFormat'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  mainStyle: {
    paddingBottom: '0px',
    minWidth: '100px',
  },
})
const AppUserCreditAddForm = ({ appUserId }) => {
  const [open, setOpen] = useState(false)
  const dataProvider = useDataProvider()
  const classes = useStyles()
  const notify = useNotify()
  const refresh = useRefresh()

  const handleSubmit = async (submission) => {
    const params = {
      app_user_id: appUserId,
      initial_amount: submission.amount * 100,
      type: submission.type,
      description: submission.description,
      expires_at: submission.expiresAt,
    }

    try {
      await dataProvider.create('app_user_credit', {
        data: params,
      })

      setOpen(false)
      refresh()

      // notify('Created Successfully.', 'info')
    } catch (error) {
      console.log(error)
      notify(error.message.split('.')[0], { type: 'warning' })
    }
  }

  return (
    <div>
      <Button onClick={() => setOpen(true)}>Add Credit</Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <FormWithRedirect
          render={(formProps) => (
            <div>
              <DialogContent>
                <Box display="flex">
                  <SelectInput
                    validate={[required()]}
                    variant="standard"
                    label="Type"
                    source="type"
                    choices={[
                      { id: 0, name: 'Commission', value: 'COMMISSION_COUPON' },
                      { id: 1, name: 'Promotion', value: 'PROMOTION' },
                    ]}
                    optionText="name"
                    optionValue="value"
                    fullWidth
                    style={{ marginRight: '10px' }}
                  />
                  <NumberFormat
                    className={classes.mainStyle}
                    label="Amount"
                    displayType="input"
                    thousandSeparator
                    decimalScale={2}
                    fixedDecimalScale
                    isNumericString
                    source="amount"
                    validate={required()}
                    prefix="$ "
                    style={{ paddingBottom: '0px' }}
                  />
                  <DateInput
                    // validate={[required()]}
                    variant="standard"
                    label="Expiry Date (1 year if left blank)"
                    source="expiresAt"
                    style={{ marginLeft: '10px' }}
                  />
                </Box>
                <TextInput
                  //   validate={[required()]}
                  variant="standard"
                  label="Description"
                  source="description"
                  minRows={3}
                  multiline
                  fullWidth
                />
              </DialogContent>
              <DialogActions>
                <Confirm
                  redirect={null}
                  record={formProps.record}
                  basePath={formProps.basePath}
                  label="SAVE"
                  undoable
                  invalid={formProps.invalid}
                  handleSubmit={async (submission) => {
                    await handleSubmit(submission)
                  }}
                  saving={formProps.saving}
                  disabled={formProps.saving || formProps.invalid}
                  resource="app_user_credit"
                />
                <Button onClick={() => setOpen(false)}>Cancel</Button>
              </DialogActions>
            </div>
          )}
        />
      </Dialog>
    </div>
  )
}

export default AppUserCreditAddForm
