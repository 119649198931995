import React from 'react'
import { Box, Typography } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/CheckCircleOutlined'
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined'
import { useFormState } from 'react-final-form'
// import AutocompleteArrayFieldInput from '../../components/fieldInputs/AutocompleteArrayFieldInput'
import CategoriesFieldInput from '../../components/fieldInputs/CategoriesFieldInput'
import TextFieldInput from '../../components/fieldInputs/TextFieldInput'
import NumberFieldInput from '../../components/fieldInputs/NumberFieldInput'
import SelectFieldInput from '../../components/fieldInputs/SelectFieldInput'
import TagSelectWithCreate from '../../components/fieldInputs/TagSelectWithCreate'
import AutocompleteArrayWithCreate from '../../components/fieldInputs/AutocompleteArrayWithCreate'
import AutocompleteFieldInput from '../../components/fieldInputs/AutocompleteFieldInput'
import RelatedProductsSelection from './RelatedProductsSelection'
import ProductBannersEdit from './ProductBannersEdit'
import ProductSizeChartEdit from './ProductSizeChartEdit'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  spacer: {
    height: '10px',
  },
  contentBox: {
    backgroundColor: 'white',
    padding: '20px 20px !important',
    borderRadius: '8px',
    border: 'solid 1px #CDCDCD',
  },
}))

const GeneralDetails = () => {
  const classes = useStyles()
  const { values } = useFormState()

  // console.log(values)

  const prodOptions = values.product_quote.options.map((el) => ({ id: el.id, value: el.name }))
  const optionsValues = [
    values.product_quote?.product_sizes?.length > 0 && { id: 0, value: 'Size' },
    ...prodOptions,
  ]

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Box className={classes.contentBox}>
        <Box display="flex" alignItems="center" justifyContent="space-between" mb="20px">
          <Typography variant="h5">Organization</Typography>
          {values.brand ? (
            <CheckIcon style={{ color: 'green' }} />
          ) : (
            <CancelOutlinedIcon style={{ color: 'red' }} />
          )}
        </Box>
        <AutocompleteFieldInput
          variant="standard"
          fullWidth
          fieldLabel="Brand"
          referenceOnConflict={{
            constraint: '^brand_name_key^',
            update_columns: ['^name^'],
          }}
          referenceField="name"
          resource="saleable_product"
          source="brand"
          elementName="brand"
          reference="brand"
          id={values.id}
          // onSave={() => checkFormValid(values)}
          // classes={{ container: classes.categoryInput }}
        />
        <CategoriesFieldInput
          variant="standard"
          fullWidth
          fieldLabel="Categories"
          formField="chosenCategories"
          oldField={'sp_categories'}
          resourceName="saleable_product_categories"
          fieldName="category_id"
          referenceField="saleable_product_id"
          referenceId={values.id}
          classes={{ container: classes.categoryInput }}
          onConflict={{
            on_conflict: {
              constraint: 'saleable_product_categories_saleable_product_id_category_id_key',
              update_columns: ['saleable_product_id', 'category_id'],
            },
          }}
        />

        <div className={classes.spacer} />
        <TagSelectWithCreate
          variant="standard"
          fullWidth
          reference="tags"
          fieldLabel="Tags"
          // validate={required()}
          data={values.chosenTags}
          oldData={values.sp_tags}
          resourceName="saleable_product_tags"
          fieldName="tag_id"
          referenceField="saleable_product_id"
          referenceId={values.id}
          source="chosenTags"
        />
        <div className={classes.spacer} />
        <AutocompleteArrayWithCreate
          source="chosenActivities"
          resource="system_dictionary"
          resourceFilter="activity"
          label="Activities"
          freeSolo
          fieldName="activity"
          referenceField="saleable_product_id"
          referenceId={values?.id}
          resourceName="saleable_product_activities"
          oldData="activities"
          onConflict={{
            on_conflict: {
              constraint: 'saleable_product_activities_saleable_product_id_activity_key',
              update_columns: ['activity', 'saleable_product_id'],
            },
          }}
        />
      </Box>

      <Box className={classes.contentBox} style={{ marginTop: '30px' }}>
        <Box display="flex" flexDirection="column">
          <Box display="flex" alignItems="center" justifyContent="space-between" mb="20px">
            <Typography variant="h5">HTS Code and Duty Rate</Typography>
            {values.hts_code && values.dutyRate > -1 ? (
              <CheckIcon style={{ color: 'green' }} />
            ) : (
              <CancelOutlinedIcon style={{ color: 'red' }} />
            )}
          </Box>
          <div className={classes.spacer} />
          <Box display="flex">
            <TextFieldInput
              variant="standard"
              // fullWidth
              field="hts_code"
              resource="saleable_product"
              id={values.id}
              label="HTS code:"
              // validate={required()}
              value={values.hts_code}
              className={classes.fieldInputLeftNarrow}
            />
            <NumberFieldInput
              className={classes.fieldInputLeftNarrow}
              label="Duty rate:"
              value={values?.dutyRate}
              resource="saleable_product"
              field="duty_rate"
              defaultValue={0}
              decimalScale={2}
              fixedDecimalScale
              parseMultiplier={100}
              suffix=" %"
              id={values.id}
              // onSave={() => saveFactoryQuote({ values, dataProvider })}
            />
          </Box>
        </Box>
      </Box>

      <Box className={classes.contentBox} style={{ marginTop: '30px' }}>
        <Box display="flex" flexDirection="column">
          <Box display="flex" alignItems="center" justifyContent="space-between" mb="20px">
            <Typography variant="h5">Layout</Typography>
            {values.options_group_by ? (
              <CheckIcon style={{ color: 'green' }} />
            ) : (
              <CancelOutlinedIcon style={{ color: 'red' }} />
            )}
          </Box>
          <div className={classes.spacer} />
          <SelectFieldInput
            variant="standard"
            field="options_group_by"
            resource="saleable_product"
            id={values.id}
            label="Order form options grouped by"
            choices={optionsValues}
            value={values?.options_group_by}
            optionText="value"
            optionValue="value"
            style={{ width: '240px', minWidth: '240px' }}
          />
        </Box>
      </Box>
      <Box className={classes.contentBox} style={{ marginTop: '30px' }}>
        <Box display="flex" flexDirection="column">
          <Box display="flex" alignItems="center" justifyContent="space-between" mb="20px">
            <Typography variant="h5">Related Products</Typography>
            {values.related_products?.length > 4 ? (
              <CheckIcon style={{ color: 'green' }} />
            ) : (
              <CancelOutlinedIcon style={{ color: 'red' }} />
            )}
          </Box>
          <RelatedProductsSelection />
        </Box>
      </Box>
      <Box className={classes.contentBox} style={{ marginTop: '30px' }}>
        <Box display="flex" flexDirection="column">
          <Box display="flex" alignItems="center" justifyContent="space-between" mb="20px">
            <Typography variant="h5">Product Banners</Typography>
            {values.banners?.length > 0 ? (
              <CheckIcon style={{ color: 'green' }} />
            ) : (
              <CancelOutlinedIcon style={{ color: 'red' }} />
            )}
          </Box>
          <ProductBannersEdit />
        </Box>
      </Box>
      <Box className={classes.contentBox} style={{ marginTop: '30px' }}>
        <Box display="flex" flexDirection="column">
          <Box display="flex" alignItems="center" justifyContent="space-between" mb="20px">
            <Typography variant="h5">Product Size Chart</Typography>
            {/* {values.banners?.length > 0 ? (
              <CheckIcon style={{ color: 'green' }} />
            ) : (
              <CancelOutlinedIcon style={{ color: 'red' }} />
            )} */}
          </Box>
          <ProductSizeChartEdit id={values.id} />
        </Box>
      </Box>
    </Box>
  )
}

export default GeneralDetails
