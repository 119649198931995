import React from 'react'
import { Box, IconButton } from '@material-ui/core'
// import useStyles from '../styles/index'
import {
  List,
  Datagrid,
  TextField,
  FunctionField,
  DateField,
  Pagination,
  useDataProvider,
  useNotify,
  useRefresh,
} from 'react-admin'
import moment from 'moment'
import get from 'lodash/get'
import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined'

const OfferManagement = (props) => {
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const refresh = useRefresh()
  // const classes = useStyles()
  // console.log('OMP', props)

  const DefaultPagination = (props) => {
    // console.log(props)
    return (
      <Pagination
        count={Object.keys(props.data).length}
        rowsPerPageOptions={[25, 50, 100]}
        {...props}
      />
    )
  }

  return (
    <Box p="0px 60px">
      {/* <Grid container spacing={2}>
        <Grid item xs={3} md={3}>
          <StatBox />
        </Grid>
        <Grid item xs={3} md={3}>
          <StatBox />
        </Grid>
        <Grid item xs={3} md={3}>
          <StatBox />
        </Grid>
        <Grid item xs={3} md={3}>
          <StatBox />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={3} md={3}>
          <StatBox />
        </Grid>
        <Grid item xs={3} md={3}>
          <StatBox />
        </Grid>
        <Grid item xs={3} md={3}>
          <StatBox />
        </Grid>
        <Grid item xs={3} md={3}>
          <StatBox />
        </Grid>
      </Grid> */}

      <Box>
        <List
          {...props}
          // sort={{ field: 'id', order: 'DESC' }}
          actions={null}
          perPage={25}
          // disableSyncWithLocation
          bulkActionButtons={false}
          resource="offerPurchases"
          basePath="/offer"
          empty={false}
          filter={{ offerId: props.id, status: 'PAYMENT_RECEIVED' }}
          pagination={<DefaultPagination />}
        >
          <Datagrid>
            <FunctionField
              label="Order # (Date)"
              render={(record) =>
                `${get(record, 'id', '')} (${moment(get(record, 'created_at', '')).format(
                  'YYYY-MM-DD',
                )})`
              }
            />
            <TextField source="buyer_id" label="User (Purchaser) ID" />
            <TextField label="Quantity" source="quantity" />
            <FunctionField
              label="Order Total"
              render={(record) => `$${(get(record, 'amount', '') / 100).toFixed(2)}`}
            />
            <DateField label="Next Withdrawal Date" source="nextWithdrawal" />
            <TextField label="Status" source="status" />
            <FunctionField
              label="Approve Payment"
              render={(record) => (
                <div>
                  {record.status === 'CONFIRMED' && (
                    <IconButton
                      onClick={async () => {
                        await dataProvider.update('offer_purchase', {
                          data: { id: record.id, status: 'PAYMENT_RECEIVED' },
                        })
                        notify('Updated', { type: 'info' })
                        refresh()
                      }}
                      style={{ maxWidth: '50px', maxHeight: '50px' }}
                    >
                      <CheckCircleOutlineOutlined fontSize="small" style={{ color: 'FF0000' }} />
                    </IconButton>
                  )}
                </div>
              )}
            />
          </Datagrid>
        </List>
      </Box>
    </Box>
  )
}

export default OfferManagement
