import gql from 'graphql-tag'
// import set from 'lodash/set'
// import { sanitizeResource } from '../getResponseParser'

export const offerPurchasesList = ({ context, params }) => {
  const offset = (params.pagination.page - 1) * params.pagination.perPage
  const variables = {}
  const offerStatus = params.filter?.offerStatus
    ? params.filter?.status?.replace(/"\^|\^"/g, '')
    : null

  // console.log(params.filter)

  if (
    params.filter &&
    (params.filter?.status ||
      offerStatus ||
      params.filter?.id ||
      params.filter?.name ||
      params.filter?.q ||
      params.filter?.offerId)
  ) {
    variables.where = {
      ...(params.filter.id && { id: { _eq: params.filter.id } }),
      // ...(params.filter.offer_id && { offer_id: { _eq: params.filter.offer_id } }),
      ...(offerStatus && { offer: { status: { _eq: offerStatus } } }),
      ...(params.filter.offerId && { offer_id: { _eq: params.filter?.offerId } }),
      ...(params.filter.name && {
        offer: {
          name: { _ilike: `%${params.filter.name || params.filter.q}%` },
        },
      }),
    }
  }

  return {
    ...context,
    variables,
    query: gql`
          query getOfferPurchases ($where: offer_purchase_bool_exp) {
           offer_purchase(where: $where, limit: ${
             params.pagination.perPage
           }, offset: ${offset}, order_by: { ${
      params.sort.field
    }: ${params.sort.order.toLowerCase()} }) {
              id
              amount
              quantity
              status
              platform
              created_at
              updated_at
              brand
              required_delivery_date
              client_price
              client_quantity
              purchase_order_number
              purchase_order_date
              purchase_order_file {
                id
                filename
                url
              }
              client_sku
              client {
                id
                name
                stripe_connected_account_id
                stripe_customer_account_id
              }
              buyer_id
              buyer {
                id
                forename
                surname
                email
              }
              items {
                id
                is_custom_order
                is_sample_order
                amount
                quantity
                unit_price
                variant {
                  id
                  values {
                    id
                    option {
                      id
                      name
                    }
                    option_value {
                      id
                      value
                    }
                  }
                }
              }
              offer {
                id
                created_at
                status
                pieces_per_carton
                cartons_per_pallet
                cartons_per_container_20ft
                cartons_per_container_40ft
                cartons_per_container_40ft_hc
                allow_order_splitting_by_size
                packaging_type
                product_dimensions {
                  id
                  length
                  height
                  gross_weight
                  width
                }
                packaging_dimensions {
                  id
                  length
                  height
                  gross_weight
                  width
                }
                carton_dimensions {
                  id
                  length
                  height
                  width
                  gross_weight
                }
                saleable_products {
                  id
                }
                sca {
                  id
                  surname
                  forename
                  email
                  username
                }
                name
                thumbnail {
                  id
                  filename
                  url
                }
                primary_photo {
                  id
                  filename
                  url
                }
                client_quote {
                  id
                  warranty_term
                  warranty_conditions
                  client_id
                  client {
                    id
                    name
                    stripe_connected_account_id
                    stripe_customer_account_id
                  }
                  product_quote {
                    id
                    quantity
                    price
                    valid_date
                    sample_cost
                    custom_sample_cost
                    sample_lead_time
                    tooling_cost
                    other_requirements
                    prices (order_by: {quantity: asc_nulls_last}) {
                      id
                      quantity
                      unit_price
                      lead_time
                      est_landed_cost_air
                  est_landed_cost_sea
                  total_cbm
                  total_cartons
                  total_duties
                  shipment_weight
                    }
                    product {
                      id
                      product_declarations {
                        id
                        declaration
                        file {
                          id
                          filename
                          url
                        }
                      }
                    }
                    supplier {
                      id
                      name
                      sca_user {
                        id
                        forename
                        surname
                        email
                        username
                      }
                      bank_detail {
                        id
                        beneficiary_address_id
                        beneficiary_name
                        branch_address
                        branch_name
                        clearing_code
                        currency
                        bank_id
                        account_number
                        swift_code
                        tax_number
                      }
                    }
                    lead_time
                    certificates {
                      id
                      certification {
                        id
                        name
                        type
                        notifying_body
                        number
                        valid_end_date
                        valid_start_date
                        document {
                          id
                          filename
                          url
                        }
                      }
                    }
                    quote {
                      id
                      filename
                      url
                    }
                  }
                }
                retail_price
                marketing_budget
                duty_rate
                logistics_estimate
                inspection_estimate
                payment_processing_fees
                supply_chain_fees
                marketing_budget
                bulk_software_fees
                bulk_commission_fees
                bulk_partner_fees
                bulk_royalties_fees
                moq
                notes
                freight_method
                incoterms
                consignee_id
                destination_address_id
                estimated_ROI
                hts_code
                inspection_checklist {
                  id
                  filename
                  url
                }
                inspection_level
                inspection_type
                inspection_work_days
                inspection_quote_file {
                  id
                  filename
                  url
                }
                investor_price_per_piece
                quality_agency_id
                logistics_agency_id
                logistics_disclaimer
                logistics_lead_time
                logistics_lead_time_to_port
                logistics_lead_time_to_warehouse
                logistics_quote_valid_date
                logistics_tracking_number
                logistics_tracking_url
                logistics_carrier
                logistics_tracking_number
                logistics_tracking_url
                logistics_carrier
                logistics_quote_file {
                  id
                  filename
                  url
                }
                long_description
                minimum_purchase_value
                minimum_purchase_quantity
                supplier_pickup_address_id
                supplier_contact_id
                supplier_factory_address_id
                sku
                short_description
                sca_id
                shipper_id
                warranty
                upc_code
                upc_code_photo {
                  id
                  filename
                  url
                }
                offer_categories (where:{deleted_at: {_is_null: true}}) {
                  id
                  category {
                    id
                    name
                  }
                }
                offer_features (where:{deleted_at: {_is_null: true}}) {
                  id
                  feature {
                    id
                    name
                    value
                  }
                }
                offer_files (where:{deleted_at: {_is_null: true}}) {
                  id
                  type
                  file {
                    id
                    filename
                    url
                  }
                }
                offer_payment_terms (where:{deleted_at: {_is_null: true}}) {
                  id
                  milestone
                  type
                  value
                  types {
                    type
                  }
                }
                offer_tags (where:{deleted_at: {_is_null: true}}) {
                  id
                  tag {
                    id
                    name
                  }
                }
              }
            }
            offer_purchase_aggregate (where: $where) {
              aggregate {
                count
              }
            }
          }
        `,
    parseResponse: ({ data }) => {
      if (data && data.offer_purchase) {
        // console.log('data', data.offer_purchase, data.offer_purchase_aggregate.aggregate.count)
        return {
          data: data.offer_purchase,
          total: data.offer_purchase_aggregate.aggregate.count,
        }
      }
      return false
    },
  }
}

export const offerPurchaseDelete = ({ context, params }) => {
  return {
    ...context,
    variables: {
      // ...params,
    },
    query: gql`
          mutation deleteOfferPurchases {
            delete_offer_purchase(
              where: { offer_id: { _eq: ${params.offerId} } }
            ) {
              affected_rows
            }
          }
        `,
    parseResponse: ({ data, errors }) => ({
      data,
      errors,
    }),
  }
}
