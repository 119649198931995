import gql from 'graphql-tag'
// import set from 'lodash/set'
// import { sanitizeResource } from '../getResponseParser'

export const offerCategoriesDelete = ({ context, params }) => {
  return {
    ...context,
    variables: {
      ...params,
    },
    query: gql`
      mutation delOfferCats($delCats: [Int!], $currentTime: timestamptz!) {
        update_offer_categories(
          where: { id: { _in: $delCats } }
          _set: { deleted_at: $currentTime }
        ) {
          affected_rows
        }
      }
    `,
    parseResponse: ({ data, errors }) => ({
      data,
      errors,
    }),
  }
}

export const offerFeaturesDelete = ({ context, params }) => {
  return {
    ...context,
    variables: {
      ...params,
    },
    query: gql`
      mutation delOfferFeats($delFeats: [Int!], $currentTime: timestamptz!) {
        update_offer_features(
          where: { id: { _in: $delFeats } }
          _set: { deleted_at: $currentTime }
        ) {
          affected_rows
        }
      }
    `,
    parseResponse: ({ data, errors }) => ({
      data,
      errors,
    }),
  }
}

export const offerSpecificationsDelete = ({ context, params }) => {
  return {
    ...context,
    variables: {
      ...params,
    },
    query: gql`
      mutation delOfferSpecs($delSpecs: [Int!], $currentTime: timestamptz!) {
        update_product_quote_specification(
          where: { id: { _in: $delSpecs } }
          _set: { deleted_at: $currentTime }
        ) {
          affected_rows
        }
      }
    `,
    parseResponse: ({ data, errors }) => ({
      data,
      errors,
    }),
  }
}

export const offerFilesDelete = ({ context, params }) => {
  return {
    ...context,
    variables: {
      ...params,
    },
    query: gql`
      mutation delOfferFiles($delFiles: [Int!], $currentTime: timestamptz!) {
        update_offer_file(where: { id: { _in: $delFiles } }, _set: { deleted_at: $currentTime }) {
          affected_rows
        }
      }
    `,
    parseResponse: ({ data, errors }) => ({
      data,
      errors,
    }),
  }
}

export const offerTrackingEventDelete = ({ context, params }) => {
  return {
    ...context,
    variables: {
      // ...params,
    },
    query: gql`
          mutation deleteOfferTrackingEvents {
            delete_offer_tracking_event(
              where: { offer_id: { _eq: ${params.offerId} } }
            ) {
              affected_rows
            }
          }
        `,
    parseResponse: ({ data, errors }) => ({
      data,
      errors,
    }),
  }
}

export const offerTrackingEventList = ({ context, params, variables }) => {
  return {
    ...context,
    variables,
    query: gql`
        query getOfferTrackingEvents {
          offer_tracking_event(order_by: {sequence:asc_nulls_last} where: {offer_id: {_eq: ${params.id}}}) {
            id
            offer_id
            date
            event
            attachments
            status
            sequence
            confirmed
            type
            offer {
              id
              status
            }
          }
        }
      `,
    parseResponse: ({ data }) => {
      if (data && data.offer_tracking_event) {
        return {
          data: data.offer_tracking_event,
        }
      }
      return false
    },
  }
}

export const offerTrackingEventCreate = ({ context, params, variables }) => {
  let jsonParams = JSON.stringify(params)
  jsonParams = jsonParams.replace(/"(\w+)":/g, '$1:')
  jsonParams = jsonParams.substr(1, jsonParams.length - 2)
  jsonParams = jsonParams.replace(/"\^/g, '')
  jsonParams = jsonParams.replace(/\^"/g, '')

  return {
    ...context,
    variables,
    query: gql`
          mutation upsertOfferTrackingEvent {
            insert_offer_tracking_event_one(
              ${jsonParams}
            ) {
              id
            }
          }        `,
    parseResponse: ({ data, errors }) => ({
      data: { id: data.insert_offer_tracking_event_one?.id },
      errors,
    }),
  }
}
