import React, { useState } from 'react'
import { useDataProvider, useNotify, Create, FormWithRedirect, Edit } from 'react-admin'
import {
  Box,
  Button,
  // CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
} from '@material-ui/core'
import { useFormState } from 'react-final-form'
import OfferList from '../../offers/offer/OfferList'
import CreateOfferForm from './CreateOfferForm'
import CreateStockForm from './CreateStockForm'
import OfferEditDetails from './OfferEditDetails'
import {
  GetCalculatedExpensesFields,
  GetCalculatedIncomeFields,
} from '../../offers/offer/components/CostsAndPricing'

const useStyles = makeStyles(() => ({
  editBox: {
    margin: 0,
    padding: 0,
  },
}))

const OffersForm = (props) => {
  const classes = useStyles()
  const { saleableProductId, ...rest } = props
  return (
    <Edit
      {...rest}
      resource="saleableProducts"
      actions={null}
      component="div"
      id={saleableProductId}
      mutationMode="pessimistic"
      className={classes.editBox}
    >
      <EditForm id={saleableProductId} />
    </Edit>
  )
}

const EditForm = (props) => {
  // const dataProvider = useDataProvider()
  const { record } = props

  // console.log(record)

  return (
    <FormWithRedirect
      {...props}
      validate={(values) => {
        // console.log(values)
        const errors = {}
        values.products?.length < 1 ? (errors.products = 'Required at least one product') : null
        !values.feedback ? (errors.feedback = 'Required feedback') : null
        // values.status !== 'IN_PROCESS' ? (errors.status = 'Request status not valid') : null
        return errors
      }}
      initialValues={{
        activeOffer: record.offers?.length > 0 ? record.offers[0] : null,
        activeVariants: record.variants,
      }}
      render={(formProps) => (
        <Box p="0px 20px">
          <FormData formProps={formProps} />
        </Box>
      )}
    />
  )
}

const FormData = () => {
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const [createStockOpen, setCreateStockOpen] = useState(false)
  const [createOfferOpen, setCreateOfferOpen] = useState(false)

  const { values } = useFormState()

  // console.log(values)

  const calcUnitPrice = ({
    data,
    prices,
    dutiesMultiplier,
    pkgOptions,
    colourModifier,
    quantity,
  }) => {
    const selectedPkgOpt = pkgOptions.find(
      (el) => el.packagingType === data?.poPackagingOption && el.sizeName === data?.size,
    )

    // console.log('DATA', data)

    const activePrice = prices.find((item) => quantity >= item?.quantity)?.fobPrice

    // console.log('ACTIVEFOBPRICE', activePrice)
    // console.log('SELPKGOPT', selectedPkgOpt)
    const basePrice =
      activePrice +
      (colourModifier || 0) +
      (selectedPkgOpt?.packagingModifier || 0) +
      (selectedPkgOpt?.sizeModifier || 0)
    // console.log('BASEPRICE', basePrice)

    const dutiesPrice = basePrice * dutiesMultiplier

    // console.log('DUTIESPRICE', dutiesPrice)

    const lclCost =
      data?.incoterms === 'FOB'
        ? 0
        : data?.shippingMethod === 'Air Freight'
        ? selectedPkgOpt?.lclAir || 0
        : selectedPkgOpt?.lclSea || 0

    // console.log('LCL', lclCost)

    const finalPrice = dutiesPrice + lclCost

    // console.log('FINALPRICE', finalPrice)

    return finalPrice
  }

  const handleCreateStock = async ({ submission }) => {
    // console.log('SUB', submission)
    // console.log('PRD', values)

    try {
      const params = {
        name: values.name || '',
        short_description: values.description || null,
        long_description: values.long_description || null,
        primary_photo_id: values.primary_photo?.id || null,
        thumbnail_photo_id: values.primary_photo?.id || null,
        hts_code: values.hts_code || null,
        saleable_product_id: values.id,
        destination_address_id: submission.delivery_address_id,
        quote_id: values.product_quote?.id,
        freight_method: 'Courier',
        type: '^STOCK^',
        incoterms: 'FOB',
        moq: submission.quantity,
        status: '^SELLING^',
        sca_id: process.env.REACT_APP_DEFAULT_ADMIN || 377,
        retail_price: Math.round(submission.retailPrice * 100),
        batches: {
          data: submission.orderVariants?.map((el) => ({
            quantity: el.quantity,
            stock: el.quantity,
            saleable_product_variant_id: el.saleable_product_variant_id,
            saleable_product_id: values.id,
          })),
        },
        organisation_inventories: {
          data: {
            organisation_id: process.env.REACT_APP_DEFAULT_ORG || 39,
            stock: submission.quantity,
            saleable_product_id: values.id,
          },
        },
      }

      // console.log(params)

      await dataProvider.create('offer', { objects: params })
      notify('Save Complete', { type: 'info' })

      // console.log('RESP', resp)
    } catch (error) {
      console.log(error)
      notify(error.message.split('.')[0], { type: 'warning' })
      return error
    }
  }

  const handleCreateOffer = async ({ submission }) => {
    // console.log('SUB', submission)
    // console.log('PRD', values)

    const {
      qualityEstimate,
      internationalFreightEstimate,
      dropshippingEstimate,
      // duty_rate,
      quantity,
      price,
      // pieces_per_carton,
      supplyChainFees,
      bulkSoftwareFees,
      bulkCommissionFees,
      bulkPartnerFees,
      paymentProcessingFees,
      bulkRoyaltiesFees,
      marketingFees,
      commissionFees,
      consumerPartnerFees,
      consumerPaymentFees,
      consumerServiceFees,
      consumerSoftwareFees,
      consumerRoyaltiesFees,
      retailPrice,
      // moq,
      // minimum_purchase_quantity,
    } = submission

    const factoryTotal = quantity * price || 0
    const qualityTotal = qualityEstimate || 0

    const landedTotal = qualityTotal // + dutiesTotal + logisticsTotal

    const supplyChainTotal = (supplyChainFees / 100) * factoryTotal

    const factoryLanded = landedTotal + factoryTotal + supplyChainTotal

    const calculatedExpensesFields = GetCalculatedExpensesFields({
      bulkSoftwareFees,
      bulkCommissionFees,
      dropshippingEstimate,
      factoryTotal,
      qualityTotal,
      factoryLanded,
      quantity,
      bulkPartnerFees,
      paymentProcessingFees,
      bulkRoyaltiesFees,
    })

    // console.log(calculatedExpensesFields)

    const clientCostUnit = calculatedExpensesFields.clientCostUnit
    const consumerDropshippingPrice = calculatedExpensesFields.consumerDropshippingPrice

    const calculatedIncomeFields = GetCalculatedIncomeFields({
      commissionFees,
      marketingFees,
      retailPrice,
      clientCostTotal: calculatedExpensesFields.clientCostTotal,
      quantity,
      dropshippingEstimate,
      consumerPartnerFees,
      consumerPaymentFees,
      consumerServiceFees,
      consumerSoftwareFees,
      consumerRoyaltiesFees,
    })

    const clientIncomeUnit = calculatedIncomeFields.grossMarginUnit
    const clientProfitUnit = clientIncomeUnit - clientCostUnit

    // console.log(calculatedIncomeFields)

    const estimatedROI = calculatedIncomeFields.buyersReturnUnit

    try {
      const params = {
        name: values.name || '',
        short_description: values.description || null,
        long_description: values.long_description || null,
        primary_photo_id: values.primary_photo?.id || null,
        thumbnail_photo_id: values.primary_photo?.id || null,
        hts_code: values.hts_code || null,
        saleable_product_id: values.id,
        destination_address_id: submission.delivery_address_id,
        quote_id: values.product_quote?.id,
        freight_method: 'Courier',
        type: '^PUBLIC^',
        incoterms: 'FOB',
        moq: submission.quantity,
        status: '^PENDING_SUBMISSION^',
        marketing_agency_id: process.env.REACT_APP_DEFAULT_MKT || 1,
        consumer_marketing_rate: Math.round(marketingFees * 100),
        consumer_commission_rate: Math.round(commissionFees * 100),
        consumer_software_rate: Math.round(consumerSoftwareFees * 100),
        consumer_payment_rate: Math.round(consumerPaymentFees * 100),
        consumer_partner_rate: Math.round(consumerPartnerFees * 100),
        consumer_service_rate: Math.round(consumerServiceFees * 100),
        consumer_royalties_rate: Math.round(consumerRoyaltiesFees * 100),
        dropshipping_estimate: Math.round(dropshippingEstimate * 100),
        sca_id: process.env.REACT_APP_DEFAULT_ADMIN || 377,
        minimum_purchase_quantity: submission.minimum_purchase_quantity || 1,
        duty_rate: Math.round(submission.duty_rate * 100),
        investor_price_per_piece: Math.round(clientCostUnit * 100),
        investor_income: Math.round(clientIncomeUnit * 100),
        investor_profit: Math.round(clientProfitUnit * 100),
        group_purchase_price: Math.round(consumerDropshippingPrice * 100),
        retail_price: Math.round(submission.retailPrice * 100),
        inspection_estimate: Math.round(qualityTotal * 100),
        logistics_estimate: Math.round(internationalFreightEstimate * 100),
        payment_processing_fees: Math.round(paymentProcessingFees * 100),
        supply_chain_fees: Math.round(supplyChainFees * 100),
        bulk_software_fees: Math.round(bulkSoftwareFees * 100),
        bulk_commission_fees: Math.round(bulkCommissionFees * 100),
        bulk_partner_fees: Math.round(bulkPartnerFees * 100),
        bulk_royalties_fees: Math.round(bulkRoyaltiesFees * 100),
        supply_chain_fees_destination: submission.supply_chain_fees_destination,
        quality_fees_destination: submission.quality_fees_destination,
        bulk_payment_fees_destination: submission.bulk_payment_fees_destination,
        bulk_software_fees_destination: submission.bulk_software_fees_destination,
        bulk_commission_fees_destination: submission.bulk_commission_fees_destination,
        bulk_partner_fees_destination: submission.bulk_partner_fees_destination,
        bulk_royalties_fees_destination: submission.bulk_royalties_fees_destination,
        international_freight_fees_destination: submission.international_freight_fees_destination,
        manufacturing_lead_time: submission.manufacturing_lead_time,
        estimated_ROI: Math.round(estimatedROI * 100),
        warranty: submission.warranty,
        terms_conditions: values.terms_conditions,
        // logistics_lead_time: freightLeadTime,
        batches: {
          data: submission.orderVariants?.map((el) => ({
            quantity: el.quantity,
            saleable_product_variant_id: el.saleable_product_variant_id,
            saleable_product_id: values.id,
          })),
        },
        platforms: {
          data: submission.platforms?.map((el) => ({
            platform: el,
          })),
        },
        tracking_events: {
          data: [
            {
              event: 'Payment - Factory Deposit - Production Start',
              sequence: 1,
              type: 'Production',
            },
            {
              event: 'Goods Pick-up Successful',
              sequence: 2,
              type: 'Shipping',
            },
            {
              event: 'Warehouse Arrival',
              sequence: 3,
              type: 'Shipping',
            },
          ],
        },
      }

      // console.log(params)
      await dataProvider.create('offer', { objects: params })
      notify('Save Complete', { type: 'info' })

      // console.log('RESP', resp)
    } catch (error) {
      console.log(error)
      notify(error.message.split('.')[0], { type: 'warning' })
      return error
    }
  }

  return (
    <Box>
      <Box
        display="flex"
        m="5px 50px"
        justifyContent={values.activeOffer ? 'flex-end' : 'space-between'}
      >
        {!values.activeOffer && (
          <Button
            disabled
            style={{
              border: 'solid 1px #CDCDCD',
              backgroundColor: '#FFF',
              borderRadius: '6px',
            }}
            onClick={() => setCreateOfferOpen(true)}
          >
            No active offer
          </Button>
        )}
        <Box display="flex">
          <Button
            disabled={values.activeVariants < 1}
            style={{
              border: 'solid 1px #CDCDCD',
              backgroundColor: '#FFF',
              borderRadius: '6px',
            }}
            onClick={() => setCreateStockOpen(true)}
          >
            Create Stock
          </Button>
          {!values.activeOffer && (
            <Button
              disabled={values.activeVariants < 1}
              style={{
                marginLeft: '10px',
                border: 'solid 1px #CDCDCD',
                backgroundColor: '#FFF',
                borderRadius: '6px',
              }}
              onClick={() => setCreateOfferOpen(true)}
            >
              Create Offer
            </Button>
          )}
        </Box>
      </Box>
      <Box>
        {values.activeOffer && (
          <Edit
            resource="offer"
            basePath="/saleableProducts"
            actions={null}
            component="div"
            id={values.activeOffer.id}
            mutationMode="pessimistic"
          >
            <FormWithRedirect
              initialValues={{
                ...values.activeOffer,
                // materials: values.activeOffer.product_quote?.product?.materials.map
                materials:
                  values.product_quote?.product?.materials.map((el) => ({
                    ...el,
                    estCost: `$${(el.estimated_cost / 100).toFixed(2)}`,
                    estimatedCost: el.estimated_cost / 100,
                    perPiece: `${el.quantity_per_piece} ${el.unit_of_measurement}`,
                    orderQuantityRequired: el.quantity_per_piece * values.activeOffer.moq,
                    estimatedOrderCost: (el.estimated_cost / 100) * values.activeOffer.moq,
                  })) || [],
                supplyChainFees: values.activeOffer.supply_chain_fees / 100 || 0,
                bulkSoftwareFees: values.activeOffer.bulk_software_fees / 100 || 0,
                bulkCommissionFees: values.activeOffer.bulk_commission_fees / 100 || 0,
                bulkPartnerFees: values.activeOffer.bulk_partner_fees / 100 || 0,
                paymentProcessingFees: values.activeOffer.payment_processing_fees / 100 || 0,
                bulkRoyaltiesFees: values.activeOffer.bulk_royalties_fees / 100 || 0,
                quantity: values.activeOffer.moq,
                retailPrice:
                  values.activeOffer?.retailPrice ||
                  values.activeOffer?.retail_price / 100 ||
                  values.retailPrice ||
                  values.retail_price / 100,
                price: values.activeOffer.saleable_product?.unit_cost / 100 || values.unitCost || 0,
                duty_rate: values.activeOffer.duty_rate / 100 || values.dutyRate / 100 || 0,
                consumerServiceFees: values.activeOffer.consumer_service_rate / 100 || 0,
                consumerRoyaltiesFees: values.activeOffer.consumer_royalties_rate / 100 || 0,
                marketingFees: values.activeOffer.consumer_marketing_rate / 100 || 0,
                fulfillmentFees: values.fulfillmentFees || values.fulfillment_fees / 100,
                commissionFees: values.activeOffer.consumer_commission_rate / 100 || 0,
                consumerSoftwareFees: values.activeOffer.consumer_software_rate / 100 || 0,
                consumerPartnerFees: values.activeOffer.consumer_partner_rate / 100 || 0,
                consumerPaymentFees: values.activeOffer.consumer_payment_rate / 100 || 0,
                dropshippingEstimate: values.activeOffer.dropshipping_estimate / 100 || 0,
                dropshipping_fees_destination: values.dropshipping_fees_destination,
                consumer_marketing_fees_destination: values.consumer_marketing_fees_destination,
                consumer_software_fees_destination: values.consumer_software_fees_destination,
                consumer_payment_fees_destination: values.consumer_payment_fees_destination,
                consumer_partner_fees_destination: values.consumer_partner_fees_destination,
                consumer_service_fees_destination: values.consumer_service_fees_destination,
                consumer_royalties_fees_destination: values.consumer_royalties_fees_destination,
                internationalFreightEstimate: values.activeOffer.logistics_estimate / 100 || 0,
                qualityEstimate: values.activeOffer.inspection_estimate / 100 || 0,
                chosenPlatforms: values.activeOffer.platforms?.map((el) =>
                  el.platform.toUpperCase(),
                ),
              }}
              render={(formProps) => (
                <OfferEditDetails offerData={values.activeOffer} formProps={formProps} />
              )}
            />
          </Edit>
        )}
      </Box>
      <Box p="0 20px !important">
        <OfferList
          basePath="/offer"
          resource="offer"
          // showCreate
          title="All Offers"
          saleableProductId={values.id}
        />
      </Box>
      {createStockOpen && (
        <Dialog
          fullWidth
          maxWidth="xl"
          open={createStockOpen || false}
          onClose={() => setCreateStockOpen(false)}
          aria-label="CreateSaleableProductStock"
        >
          <DialogTitle>Create new Stock</DialogTitle>
          <DialogContent>
            <Create resource="saleable_product_batch" basePath="/saleableProduct">
              <FormWithRedirect
                // {...props}
                // validate={(values) => {
                //   const errors = {}
                //   !values.selectedURL ? (errors.selectedURL = 'No image selected') : null
                //   return errors
                // }}
                initialValues={{
                  orderVariants: [{}],
                  retailPrice: values.retail_price / 100,
                  minimum_purchase_quantity: 1,
                  price: values.unit_cost / 100,
                  duty_rate: values.duty_rate * 100 || 0,
                  dropshippingEstimate: values.dropshipping_estimate / 100,
                  consumerServiceFees: values.consumer_service_fees / 100,
                  consumerSoftwareFees: values.consumer_software_fees / 100,
                  consumerPartnerFees: values.consumer_partner_fees / 100,
                  consumerPaymentFees: values.consumer_payment_fees / 100,
                  consumerRoyaltiesFees: values.consumer_royalties_fees / 100,
                  marketingFees: values.marketing_fees / 100,
                  commissionFees: values.commission_fees / 100,
                  fulfillmentFees: values.fulfillment_fees / 100,
                  dropshipping_fees_destination: values.dropshipping_fees_destination,
                  consumer_marketing_fees_destination: values.consumer_marketing_fees_destination,
                  consumer_software_fees_destination: values.consumer_software_fees_destination,
                  consumer_payment_fees_destination: values.consumer_payment_fees_destination,
                  consumer_partner_fees_destination: values.consumer_partner_fees_destination,
                  consumer_service_fees_destination: values.consumer_service_fees_destination,
                  consumer_royalties_fees_destination: values.consumer_royalties_fees_destination,
                  platforms: ['Cliquestock'],
                  delivery_address_id: process.env.REACT_APP_DEFAULT_ADDRESS || 124,
                }}
                render={(formProps) => (
                  <CreateStockForm
                    saleableProductData={values}
                    formProps={formProps}
                    handleCreateStock={handleCreateStock}
                  />
                )}
              />
            </Create>
          </DialogContent>
        </Dialog>
      )}
      {createOfferOpen && (
        <Dialog
          fullWidth
          maxWidth="xl"
          open={createOfferOpen || false}
          onClose={() => setCreateOfferOpen(false)}
          aria-label="CreateSaleableProductOffer"
        >
          <DialogTitle>Create new Offer</DialogTitle>
          <DialogContent>
            <Create resource="variant" basePath="/saleableProduct">
              <FormWithRedirect
                // {...props}
                // validate={(values) => {
                //   const errors = {}
                //   !values.selectedURL ? (errors.selectedURL = 'No image selected') : null
                //   return errors
                // }}
                initialValues={{
                  orderVariants: [{}],
                  materials:
                    values.product_quote?.product?.materials?.map((el) => ({
                      ...el,
                      estCost: `$${(el.estimated_cost / 100).toFixed(2)}`,
                      estimatedCost: el.estimated_cost / 100,
                      perPiece: `${el.quantity_per_piece} ${el.unit_of_measurement}`,
                    })) || [],
                  retailPrice: values.retail_price / 100,
                  minimum_purchase_quantity: 1,
                  price: values.unit_cost / 100,
                  duty_rate: values.duty_rate * 100 || 0,
                  dropshippingEstimate: values.dropshipping_estimate / 100,
                  consumerServiceFees: values.consumer_service_fees / 100,
                  consumerSoftwareFees: values.consumer_software_fees / 100,
                  consumerPartnerFees: values.consumer_partner_fees / 100,
                  consumerPaymentFees: values.consumer_payment_fees / 100,
                  consumerRoyaltiesFees: values.consumer_royalties_fees / 100,
                  marketingFees: values.marketing_fees / 100,
                  commissionFees: values.commission_fees / 100,
                  fulfillmentFees: values.fulfillment_fees / 100,
                  dropshipping_fees_destination: values.dropshipping_fees_destination,
                  consumer_marketing_fees_destination: values.consumer_marketing_fees_destination,
                  consumer_software_fees_destination: values.consumer_software_fees_destination,
                  consumer_payment_fees_destination: values.consumer_payment_fees_destination,
                  consumer_partner_fees_destination: values.consumer_partner_fees_destination,
                  consumer_service_fees_destination: values.consumer_service_fees_destination,
                  consumer_royalties_fees_destination: values.consumer_royalties_fees_destination,
                  platforms: ['Cliquestock'],
                  delivery_address_id: process.env.REACT_APP_DEFAULT_ADDRESS || 124,
                }}
                render={(formProps) => (
                  <CreateOfferForm
                    saleableProductData={values}
                    formProps={formProps}
                    handleCreateOffer={handleCreateOffer}
                    calcUnitPrice={calcUnitPrice}
                  />
                )}
              />
            </Create>
          </DialogContent>
        </Dialog>
      )}
    </Box>
  )
}

export default OffersForm
