import React from 'react'
import { FormWithRedirect, Edit, useNotify, useDataProvider } from 'react-admin'
import { Box, Card } from '@material-ui/core'
// import CustomToolbar from '../../components/CustomToolbar'
import FormData from './FormData'
import OrganisationList from './OrganisationList'
import CreditList from './CreditList'
import NotificationList from './NotificationList'
import { hasChangeValues } from '../../../utils/utils'
// import { GSFeed } from '../../components/GSFeed'

const UserEdit = (props) => (
  <Edit {...props}>
    <UserForm />
  </Edit>
)

const UserForm = (props) => {
  const dataProvider = useDataProvider()
  const notify = useNotify()

  // console.log(props)

  const handleSubmit = async ({ record, dirtyFields }) => {
    // console.log(record, formState)
    if (!dirtyFields) {
      return null
    }
    const changeRecord = hasChangeValues(record, dirtyFields)
    if (!changeRecord || Object.keys(changeRecord).length < 2) {
      return null
    }
    // console.log(changeRecord)

    try {
      await dataProvider.update('app_users', {
        data: changeRecord,
      })
      notify('Update Complete', { type: 'info' })
    } catch (error) {
      notify(error.message, { type: 'warning' })
    }
  }

  return (
    <Box>
      <FormWithRedirect
        {...props}
        validate={(values) => {
          const errors = {}
          if (values.username?.length > 16) {
            errors.username = 'Must be 16 characters or less'
          }
          return errors
        }}
        render={(formProps) => (
          <Card>
            <Box display="flex">
              <Box flex={4}>
                <FormData
                  id={formProps.record.id}
                  handleSubmit={handleSubmit}
                  formProps={formProps}
                />
                <CreditList basePath="/app_users" appUserId={formProps.record.id} />
                <OrganisationList basePath="/app_users" appUserId={formProps.record.id} />
                {formProps.record.member_organisations?.map((item) => (
                  <Box key={`notlist${item.id}`} p="60px">
                    <span>{item.organisation.name} Notifications</span>
                    <NotificationList basePath="/app_users" appUserCode={item.code} />
                  </Box>
                ))}
              </Box>
              {/* <Box flex={1}>
                <GSFeed feedType="user" id={props.record?.code} />
              </Box> */}
            </Box>
          </Card>
        )}
      />
    </Box>
  )
}

export default UserEdit
