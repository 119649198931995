import React, { useState } from 'react'
import { TextInput, useDataProvider, useNotify } from 'react-admin'
import { Box, CircularProgress, IconButton } from '@material-ui/core'
import { useFormState } from 'react-final-form'
import BorderColorIcon from '@material-ui/icons/BorderColorOutlined'
import CancelOutlined from '@material-ui/icons/CancelOutlined'
import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined'
import NumberFormat from '../../../components/CustomNumberFormat'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  tableTitle: {
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '18px',
    color: '#333333',
    marginBottom: '20px',
  },
  filesLabel: {
    fontSize: '12px',
    fontWeight: '600',
    marginBottom: '8px',
  },
  numberInput: {
    width: '120px',
    height: '36px',
    textAlign: 'center',
  },
  textInput: {
    width: '120px',
    height: '36px',
    marginLeft: '4px',
  },
}))

const ConfirmationArea = (props) => {
  const classes = useStyles()
  const notify = useNotify()
  const dataProvider = useDataProvider()
  const [editing, setEditing] = useState(false)
  const [saving, setSaving] = useState(false)
  const { values } = useFormState()

  const { fieldForm, buyersReturn, clientCostUnit, clientIncomeUnit } = props

  // console.log(values)

  return (
    <Box m="20px 0px">
      <Box display="flex" justifyContent="space-between">
        <span className={classes.tableTitle}>Confirm Cost Structure</span>
        {fieldForm && (
          <div>
            {editing ? (
              <div>
                {saving && <CircularProgress style={{ width: '20px', height: '20px' }} />}
                <IconButton
                  onClick={async () => {
                    // console.log(values)
                    setSaving(true)

                    try {
                      const spParams = {
                        id: values.saleable_product_id,
                        consumer_service_fees: parseInt(values.consumerServiceFees * 100),
                        consumer_royalties_fees: parseInt(values.consumerRoyaltiesFees * 100),
                        marketing_fees: parseInt(values.marketingFees * 100),
                        commission_fees: parseInt(values.commissionFees * 100),
                        consumer_software_fees: parseInt(values.consumerSoftwareFees * 100),
                        consumer_partner_fees: parseInt(values.consumerPartnerFees * 100),
                        consumer_payment_fees: parseInt(values.consumerPaymentFees * 100),
                        dropshipping_fees_destination: values.dropshipping_fees_destination,
                        consumer_marketing_fees_destination:
                          values.consumer_marketing_fees_destination,
                        consumer_software_fees_destination:
                          values.consumer_software_fees_destination,
                        consumer_payment_fees_destination: values.consumer_payment_fees_destination,
                        consumer_partner_fees_destination: values.consumer_partner_fees_destination,
                        consumer_service_fees_destination: values.consumer_service_fees_destination,
                        consumer_royalties_fees_destination:
                          values.consumer_royalties_fees_destination,
                      }

                      const ofParams = {
                        id: values.id,
                        investor_price_per_piece: parseInt(clientCostUnit * 100),
                        investor_profit: parseInt((clientIncomeUnit - clientCostUnit) * 100),
                        investor_income: parseInt(clientIncomeUnit * 100),
                        estimated_ROI: parseInt(buyersReturn * 100),
                        logistics_estimate: parseInt(values.internationalFreightEstimate * 100),
                        inspection_estimate: parseInt(values.qualityEstimate * 100),
                        payment_processing_fees: parseInt(values.paymentProcessingFees * 100),
                        supply_chain_fees: parseInt(values.supplyChainFees * 100),
                        bulk_software_fees: parseInt(values.bulkSoftwareFees * 100),
                        bulk_commission_fees: parseInt(values.bulkCommissionFees * 100),
                        bulk_partner_fees: parseInt(values.bulkPartnerFees * 100),
                        bulk_royalties_fees: parseInt(values.bulkRoyaltiesFees * 100),
                        supply_chain_fees_destination: values.supply_chain_fees_destination,
                        quality_fees_destination: values.quality_fees_destination,
                        bulk_payment_fees_destination: values.bulk_payment_fees_destination,
                        bulk_software_fees_destination: values.bulk_software_fees_destination,
                        bulk_commission_fees_destination: values.bulk_commission_fees_destination,
                        bulk_partner_fees_destination: values.bulk_partner_fees_destination,
                        bulk_royalties_fees_destination: values.bulk_royalties_fees_destination,
                        international_freight_fees_destination:
                          values.international_freight_fees_destination,
                      }

                      await dataProvider.update('saleable_product', { data: spParams })
                      await dataProvider.update('offer', { data: ofParams })
                      setEditing(false)
                      setSaving(false)
                      notify('Saved Successfully.', { type: 'info' })
                    } catch (error) {
                      setEditing(false)
                      setSaving(false)
                      notify(error.message.split('.')[0], { type: 'warning' })
                    }
                  }}
                  style={{ marginLeft: '30px', maxWidth: '50px', maxHeight: '50px' }}
                >
                  <CheckCircleOutlineOutlined fontSize="small" style={{ color: 'FF0000' }} />
                </IconButton>

                <IconButton
                  onClick={() => {
                    setEditing(false)
                  }}
                  style={{ maxWidth: '50px', maxHeight: '50px' }}
                >
                  <CancelOutlined fontSize="small" />
                </IconButton>
              </div>
            ) : (
              <IconButton
                onClick={() => {
                  setEditing(true)
                }}
                style={{ marginLeft: '30px', maxWidth: '50px', maxHeight: '50px' }}
              >
                <BorderColorIcon fontSize="small" />
              </IconButton>
            )}
          </div>
        )}
      </Box>

      <Box display="flex" mt="10px">
        <Box flex={1} display="flex" flexDirection="column">
          <span className={classes.filesLabel}>Supply Chain Operations</span>
          <Box display="flex">
            <NumberFormat
              className={classes.numberInput}
              displayType="input"
              thousandSeparator
              decimalScale={2}
              fixedDecimalScale
              isNumericString
              suffix="%"
              label="Supply Chain Fees:"
              source="supplyChainFees"
              disabled={fieldForm && !editing}
            />
            <TextInput
              className={classes.textInput}
              variant="standard"
              fullWidth
              source="supply_chain_fees_destination"
              disabled={fieldForm && !editing}
              label="Destination"
              InputLabelProps={{
                style: {
                  fontSize: '9px',
                  color: '#AAAAAA',
                },
              }}
            />
          </Box>
          <Box display="flex">
            <NumberFormat
              className={classes.numberInput}
              displayType="input"
              thousandSeparator
              decimalScale={2}
              fixedDecimalScale
              isNumericString
              prefix="$ "
              label="Quality Estimate:"
              source="qualityEstimate"
              disabled={fieldForm && !editing}
            />
            <TextInput
              className={classes.textInput}
              variant="standard"
              fullWidth
              source="quality_fees_destination"
              disabled={fieldForm && !editing}
              label="Destination"
              InputLabelProps={{
                style: {
                  fontSize: '9px',
                  color: '#AAAAAA',
                },
              }}
            />
          </Box>
          <Box display="flex">
            <NumberFormat
              className={classes.numberInput}
              displayType="input"
              thousandSeparator
              decimalScale={2}
              fixedDecimalScale
              isNumericString
              prefix="$ "
              label="Dropshipping Est.:"
              source="dropshippingEstimate"
              disabled={fieldForm && !editing}
            />
            <TextInput
              className={classes.textInput}
              variant="standard"
              fullWidth
              source="dropshipping_fees_destination"
              disabled={fieldForm && !editing}
              label="Destination"
              InputLabelProps={{
                style: {
                  fontSize: '9px',
                  color: '#AAAAAA',
                },
              }}
            />
          </Box>
          <Box display="flex">
            <NumberFormat
              className={classes.numberInput}
              displayType="input"
              thousandSeparator
              decimalScale={2}
              fixedDecimalScale
              isNumericString
              prefix="$ "
              label="Int'l Freight Est.:"
              source="internationalFreightEstimate"
              disabled={fieldForm && !editing}
            />
            <TextInput
              className={classes.textInput}
              variant="standard"
              fullWidth
              source="international_freight_fees_destination"
              disabled={fieldForm && !editing}
              label="Destination"
              InputLabelProps={{
                style: {
                  fontSize: '9px',
                  color: '#AAAAAA',
                },
              }}
            />
          </Box>
          {/* <NumberFormat
            className={classes.numberInput}
            displayType="input"
            thousandSeparator
            decimalScale={2}
            fixedDecimalScale
            isNumericString
            label="Retail Price:"
            prefix="$ "
            source="retailPrice"
            disabled={fieldForm && !editing}
          />
          <NumberFormat
            className={classes.numberInput}
            displayType="input"
            thousandSeparator
            decimalScale={0}
            fixedDecimalScale
            isNumericString
            label="Min. Purchase Qty:"
            source="minimum_purchase_quantity"
            disabled={fieldForm && !editing}
          /> */}
        </Box>
        <Box flex={1} display="flex" flexDirection="column">
          <span className={classes.filesLabel}>Supply Chain Service Fees</span>
          <Box display="flex">
            <NumberFormat
              className={classes.numberInput}
              displayType="input"
              thousandSeparator
              decimalScale={2}
              fixedDecimalScale
              isNumericString
              suffix="%"
              label="Payment Fees:"
              source="paymentProcessingFees"
              disabled={fieldForm && !editing}
            />
            <TextInput
              className={classes.textInput}
              variant="standard"
              fullWidth
              source="bulk_payment_fees_destination"
              disabled={fieldForm && !editing}
              label="Destination"
              InputLabelProps={{
                style: {
                  fontSize: '9px',
                  color: '#AAAAAA',
                },
              }}
            />
          </Box>
          <Box display="flex">
            <NumberFormat
              className={classes.numberInput}
              displayType="input"
              thousandSeparator
              decimalScale={2}
              fixedDecimalScale
              isNumericString
              suffix="%"
              label="Software Fees:"
              source="bulkSoftwareFees"
              disabled={fieldForm && !editing}
            />
            <TextInput
              className={classes.textInput}
              variant="standard"
              fullWidth
              source="bulk_software_fees_destination"
              disabled={fieldForm && !editing}
              label="Destination"
              InputLabelProps={{
                style: {
                  fontSize: '9px',
                  color: '#AAAAAA',
                },
              }}
            />
          </Box>
          <Box display="flex">
            <NumberFormat
              className={classes.numberInput}
              displayType="input"
              thousandSeparator
              decimalScale={2}
              fixedDecimalScale
              isNumericString
              suffix="%"
              label="Commission Fees:"
              source="bulkCommissionFees"
              disabled={fieldForm && !editing}
            />
            <TextInput
              className={classes.textInput}
              variant="standard"
              fullWidth
              source="bulk_commission_fees_destination"
              disabled={fieldForm && !editing}
              label="Destination"
              InputLabelProps={{
                style: {
                  fontSize: '9px',
                  color: '#AAAAAA',
                },
              }}
            />
          </Box>
          <Box display="flex">
            <NumberFormat
              className={classes.numberInput}
              displayType="input"
              thousandSeparator
              decimalScale={2}
              fixedDecimalScale
              isNumericString
              suffix="%"
              label="Partner Fees:"
              source="bulkPartnerFees"
              disabled={fieldForm && !editing}
            />
            <TextInput
              className={classes.textInput}
              variant="standard"
              fullWidth
              source="bulk_partner_fees_destination"
              disabled={fieldForm && !editing}
              label="Destination"
              InputLabelProps={{
                style: {
                  fontSize: '9px',
                  color: '#AAAAAA',
                },
              }}
            />
          </Box>
          <Box display="flex">
            <NumberFormat
              className={classes.numberInput}
              displayType="input"
              thousandSeparator
              decimalScale={2}
              fixedDecimalScale
              isNumericString
              suffix="%"
              label="Royalties Fees:"
              source="bulkRoyaltiesFees"
              disabled={fieldForm && !editing}
            />
            <TextInput
              className={classes.textInput}
              variant="standard"
              fullWidth
              source="bulk_royalties_fees_destination"
              disabled={fieldForm && !editing}
              label="Destination"
              InputLabelProps={{
                style: {
                  fontSize: '9px',
                  color: '#AAAAAA',
                },
              }}
            />
          </Box>
        </Box>
        <Box flex={1} display="flex" flexDirection="column">
          <span className={classes.filesLabel}>Selling Fees</span>
          <Box display="flex">
            <NumberFormat
              className={classes.numberInput}
              displayType="input"
              thousandSeparator
              decimalScale={2}
              fixedDecimalScale
              isNumericString
              suffix="%"
              label="Marketing Fees:"
              source="marketingFees"
              disabled={fieldForm && !editing}
            />
            <TextInput
              className={classes.textInput}
              variant="standard"
              fullWidth
              source="consumer_marketing_fees_destination"
              disabled={fieldForm && !editing}
              label="Destination"
              InputLabelProps={{
                style: {
                  fontSize: '9px',
                  color: '#AAAAAA',
                },
              }}
            />
          </Box>
          <Box display="flex">
            <NumberFormat
              className={classes.numberInput}
              displayType="input"
              thousandSeparator
              decimalScale={2}
              fixedDecimalScale
              isNumericString
              suffix="%"
              label="Seller Commission:"
              source="commissionFees"
              disabled={fieldForm && !editing}
            />
            <TextInput
              className={classes.textInput}
              variant="standard"
              fullWidth
              disabled
              source="consumerCommissionDestination"
              label={false}
            />
          </Box>
          <Box display="flex">
            <NumberFormat
              className={classes.numberInput}
              displayType="input"
              thousandSeparator
              decimalScale={2}
              fixedDecimalScale
              isNumericString
              suffix="%"
              label="Software Fees:"
              source="consumerSoftwareFees"
              disabled={fieldForm && !editing}
            />
            <TextInput
              className={classes.textInput}
              variant="standard"
              fullWidth
              source="consumer_software_fees_destination"
              disabled={fieldForm && !editing}
              label="Destination"
              InputLabelProps={{
                style: {
                  fontSize: '9px',
                  color: '#AAAAAA',
                },
              }}
            />
          </Box>
          <Box display="flex">
            <NumberFormat
              className={classes.numberInput}
              displayType="input"
              thousandSeparator
              decimalScale={2}
              fixedDecimalScale
              isNumericString
              suffix="%"
              label="Payment Fees:"
              source="consumerPaymentFees"
              disabled={fieldForm && !editing}
            />
            <TextInput
              className={classes.textInput}
              variant="standard"
              fullWidth
              source="consumer_payment_fees_destination"
              disabled={fieldForm && !editing}
              label="Destination"
              InputLabelProps={{
                style: {
                  fontSize: '9px',
                  color: '#AAAAAA',
                },
              }}
            />
          </Box>
        </Box>
        <Box flex={1} display="flex" flexDirection="column">
          <span className={classes.filesLabel}>Additional Selling Fees</span>
          <Box display="flex">
            <NumberFormat
              className={classes.numberInput}
              displayType="input"
              thousandSeparator
              decimalScale={2}
              fixedDecimalScale
              isNumericString
              prefix="$ "
              label="Partner Fees:"
              source="consumerPartnerFees"
              disabled={fieldForm && !editing}
            />
            <TextInput
              className={classes.textInput}
              variant="standard"
              fullWidth
              source="consumer_partner_fees_destination"
              disabled={fieldForm && !editing}
              label="Destination"
              InputLabelProps={{
                style: {
                  fontSize: '9px',
                  color: '#AAAAAA',
                },
              }}
            />
          </Box>
          {/* <Box display="flex">
            <NumberFormat
              className={classes.numberInput}
              displayType="input"
              thousandSeparator
              decimalScale={2}
              fixedDecimalScale
              isNumericString
              prefix="$ "
              label="Fulfillment Fees:"
              source="fulfillmentFees"
              disabled={fieldForm && !editing}
            />
            <TextInput
              className={classes.textInput}
              variant="standard"
              fullWidth
              source="consumerFulfillmentDestination"
              disabled={fieldForm && !editing}
              label="Destination"
              InputLabelProps={{
                style: {
                  fontSize: '9px',
                  color: '#AAAAAA',
                },
              }}
            />
          </Box> */}
          <Box display="flex">
            <NumberFormat
              className={classes.numberInput}
              displayType="input"
              thousandSeparator
              decimalScale={2}
              fixedDecimalScale
              isNumericString
              suffix="%"
              label="Service Fees:"
              source="consumerServiceFees"
              disabled={fieldForm && !editing}
            />
            <TextInput
              className={classes.textInput}
              variant="standard"
              fullWidth
              source="consumer_service_fees_destination"
              disabled={fieldForm && !editing}
              label="Destination"
              InputLabelProps={{
                style: {
                  fontSize: '9px',
                  color: '#AAAAAA',
                },
              }}
            />
          </Box>
          <Box display="flex">
            <NumberFormat
              className={classes.numberInput}
              displayType="input"
              thousandSeparator
              decimalScale={2}
              fixedDecimalScale
              isNumericString
              suffix="%"
              label="Royalties Fees:"
              source="consumerRoyaltiesFees"
              disabled={fieldForm && !editing}
            />
            <TextInput
              className={classes.textInput}
              variant="standard"
              fullWidth
              source="consumer_royalties_fees_destination"
              disabled={fieldForm && !editing}
              label="Destination"
              InputLabelProps={{
                style: {
                  fontSize: '9px',
                  color: '#AAAAAA',
                },
              }}
            />
          </Box>
        </Box>
        {/* <Box
          display="flex"
          flexDirection="column"
          style={
            showAdvanced
              ? {
                  visibility: 'hidden',
                  width: '0px',
                  flex: 0,
                  transition: 'flex 1s, width 1s, visibility 0s',
                }
              : {
                  visibility: 'visible',
                  width: 'auto',
                  flex: 1,
                  transition: 'flex 1s, width 1s, visibility 0s',
                }
          }
        >
          <span className={classes.filesLabel}>Selling Fees</span>
          <NumberFormat
            className={classes.numberInput}
            displayType="input"
            thousandSeparator
            decimalScale={2}
            fixedDecimalScale
            isNumericString
            suffix="%"
            label="Marketing Fees:"
            source="marketingBudget"
            disabled={fieldForm && !editing}
          />
          <NumberFormat
            className={classes.numberInput}
            displayType="input"
            thousandSeparator
            decimalScale={2}
            fixedDecimalScale
            isNumericString
            prefix="$ "
            label="Fulfillment Fees:"
            source="fulfillmentFees"
            disabled={fieldForm && !editing}
          />
          <NumberFormat
            className={classes.numberInput}
            displayType="input"
            thousandSeparator
            decimalScale={2}
            fixedDecimalScale
            isNumericString
            suffix="%"
            label="Seller Commission:"
            source="commissionFees"
            disabled={fieldForm && !editing}
          />
          <NumberFormat
            className={classes.numberInput}
            displayType="input"
            thousandSeparator
            decimalScale={2}
            fixedDecimalScale
            isNumericString
            suffix="%"
            label="Software Fees:"
            source="softwareFees"
            disabled={fieldForm && !editing}
          />
        </Box>
        <Button className={classes.advancedButton} onClick={() => setShowAdvanced(!showAdvanced)}>
          {showAdvanced ? `<<` : `>>`}
        </Button> */}
      </Box>
    </Box>
  )
}

export default ConfirmationArea
