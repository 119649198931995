import gql from 'graphql-tag'
// import set from 'lodash/set'
// import { sanitizeResource } from '../getResponseParser'

export const productFilesCreateMany = ({ context, params, variables }) => {
  let jsonParams = JSON.stringify(params)
  jsonParams = jsonParams.replace(/"(\w+)":/g, '$1:')
  jsonParams = jsonParams.substr(1, jsonParams.length - 2)
  jsonParams = jsonParams.replace(/"\^/g, '')
  jsonParams = jsonParams.replace(/\^"/g, '')
  return {
    ...context,
    variables,
    query: gql`
            mutation insertProductFiles {
              insert_product_file(
                ${jsonParams}
              ) {
                returning {
                  id
                  type
                  product_id
                  file {
                    id
                    filename
                    url
                  }
                }
              }
            }
          `,
    parseResponse: ({ data }) => {
      if (data && data.insert_product_file?.returning) {
        return {
          data: data.insert_product_file.returning,
        }
      }
      return false
    },
  }
}

export const productSpecificationsCreateMany = ({ context, params, variables }) => {
  let jsonParams = JSON.stringify(params)
  jsonParams = jsonParams.replace(/"(\w+)":/g, '$1:')
  jsonParams = jsonParams.substr(1, jsonParams.length - 2)
  jsonParams = jsonParams.replace(/"\^/g, '')
  jsonParams = jsonParams.replace(/\^"/g, '')

  return {
    ...context,
    variables,
    query: gql`
            mutation insertProductSpecsMany {
              insert_product_specifications(
                ${jsonParams}
              ) {
                returning {
                  id
                  product_id
                  option_value {
                    id
                    value
                    option {
                      id
                      name
                    }
                  }
                }
              }
            }
          `,
    parseResponse: ({ data }) => {
      if (data && data.insert_product_specifications?.returning) {
        return {
          data: data.insert_product_specifications.returning,
        }
      }
      return false
    },
  }
}

export const productCustomisationsCreateMany = ({ context, params, variables }) => {
  let jsonParams = JSON.stringify(params)
  jsonParams = jsonParams.replace(/"(\w+)":/g, '$1:')
  jsonParams = jsonParams.substr(1, jsonParams.length - 2)
  jsonParams = jsonParams.replace(/"\^/g, '')
  jsonParams = jsonParams.replace(/\^"/g, '')

  return {
    ...context,
    variables,
    query: gql`
            mutation insertProductCustsMany {
              insert_product_customisations(
                ${jsonParams}
              ) {
                returning {
                  id
                  product_id
                  option_value {
                    id
                    value
                    option {
                      id
                      name
                    }
                  }
                }
              }
            }
          `,
    parseResponse: ({ data }) => {
      if (data && data.insert_product_customisations?.returning) {
        return {
          data: data.insert_product_customisations.returning,
        }
      }
      return false
    },
  }
}

export const productOptionsCreateMany = ({ context, params, variables }) => {
  let jsonParams = JSON.stringify(params)
  jsonParams = jsonParams.replace(/"(\w+)":/g, '$1:')
  jsonParams = jsonParams.substr(1, jsonParams.length - 2)
  jsonParams = jsonParams.replace(/"\^/g, '')
  jsonParams = jsonParams.replace(/\^"/g, '')

  return {
    ...context,
    variables,
    query: gql`
            mutation insertProductOptionsMany {
              insert_product_options(
                ${jsonParams}
              ) {
                returning {
                  id
                  product_id
                  option_value {
                    id
                    value
                    option {
                      id
                      name
                    }
                  }
                }
              }
            }
          `,
    parseResponse: ({ data }) => {
      if (data && data.insert_product_options?.returning) {
        return {
          data: data.insert_product_options.returning,
        }
      }
      return false
    },
  }
}

export const productSpecificationsDelete = ({ context, params }) => {
  // console.log(params)
  return {
    ...context,
    variables: {
      ...params,
    },
    query: gql`
      mutation delProdSpecs($delSpecs: [Int!]) {
        delete_product_specifications(where: { id: { _in: $delSpecs } }) {
          affected_rows
        }
      }
    `,
    parseResponse: ({ data, errors }) => ({
      data,
      errors,
    }),
  }
}

export const productOptionsDelete = ({ context, params }) => {
  // console.log(params)
  return {
    ...context,
    variables: {
      ...params,
    },
    query: gql`
      mutation delProdOpts($delOpts: [Int!]) {
        delete_product_options(where: { id: { _in: $delOpts } }) {
          affected_rows
        }
      }
    `,
    parseResponse: ({ data, errors }) => ({
      data,
      errors,
    }),
  }
}

export const productCustomisationsDelete = ({ context, params }) => {
  // console.log(params)
  return {
    ...context,
    variables: {
      ...params,
    },
    query: gql`
      mutation delProdCustoms($delCustsUnique: [String!]) {
        delete_product_customisations(
          where: { option_value: { option: { name: { _in: $delCustsUnique } } } }
        ) {
          affected_rows
        }
      }
    `,
    parseResponse: ({ data, errors }) => ({
      data,
      errors,
    }),
  }
}

export const productOptionsList = ({ context, params }) => {
  const offset = (params.pagination?.page - 1) * params.pagination?.perPage || 0
  const sortField = params.sort?.field || 'id'
  const sortBy = params.sort?.order?.toLowerCase() || 'asc'
  const perPage = params.pagination?.perPage || 1000
  const variables = {}

  // console.log(params)

  if (params.filter?.product_id) {
    variables.where = { product_id: { _eq: params.filter.product_id } }
  }
  return {
    ...context,
    variables,
    query: gql`
    query getProductOptions ($where: product_options_bool_exp) {
     product_options(where: $where, limit: ${perPage}, offset: ${offset}, order_by: { ${sortField}: ${sortBy} }) {
          id
          option_value {
            id
            value
            type
            price_modifier
            moq
            reference_file {
              id
              filename
              url
            }
            option {
              id
              name
            }
          }
        }
        product_options_aggregate (where: $where) {
          aggregate {
            count
          }
        }
      }
    `,
    parseResponse: ({ data }) => ({
      data: data.product_options,
      total: data.product_options_aggregate.aggregate.count,
    }),
  }
}

export const productCustomisationsList = ({ context, params }) => {
  const offset = (params.pagination?.page - 1) * params.pagination?.perPage || 0
  const sortField = params.sort?.field || 'id'
  const sortBy = params.sort?.order?.toLowerCase() || 'asc'
  const perPage = params.pagination?.perPage || 1000
  const variables = {}

  if (params.filter?.product_id) {
    variables.where = { product_id: { _eq: params.filter.product_id } }
  }
  return {
    ...context,
    variables,
    query: gql`
          query getProductCustomisations ($where: product_customisations_bool_exp) {
           product_customisations(where: $where, limit: ${perPage}, offset: ${offset}, order_by: { ${sortField}: ${sortBy} }) {
                id
                customisation {
                  id
                  component {
                    id
                    name
                    svg_shape_index
                    default_colour
                  }
                  values {
                    id
                    value
                    type
                    price_modifier
                    moq
                    reference_file {
                      id
                      filename
                      url
                    }
                  }
                }
              }
              product_customisations_aggregate (where: $where) {
                aggregate {
                  count
                }
              }
            }
          `,
    parseResponse: ({ data }) => ({
      data: data.product_customisations,
      total: data.product_customisations_aggregate.aggregate.count,
    }),
  }
}

export const tagsList = ({ context, params }) => {
  // console.log(params)
  return {
    ...context,
    variables: {
      ...params,
    },
    query: gql`
      query getTags {
        tag {
          id
          name
        }
        tag_aggregate {
          aggregate {
            count
          }
        }
      }
    `,
    parseResponse: ({ data }) => ({
      data: data.tag,
      total: data.tag_aggregate.aggregate.count,
    }),
  }
}

export const brandsGet = ({ context, params }) => {
  return {
    ...context,
    variables: {
      ...params,
    },
    query: gql`
      query getBrand {
        brand_by_pk (id: ${params.id}) {
          id
          name
          owner_name
          description
          top_banner_id
          top_banner {
            id
            filename
            url
          }
          hero_banner_id
          hero_banner {
            id
            filename
            url
          }
          featured_products {
            id
            saleable_product_variant {
              id
              image {
                id
                filename
                url
              }
              stock
              name
              saleable_product {
                id
                name
                total_stock
                primary_photo {
                  id
                  filename
                  url
                }
              }
            }
          }
          featured_products_aggregate {
            aggregate { count }
          }
          products_aggregate {
            aggregate { count }
          }
          saleable_products_aggregate {
            aggregate { count }
          }
        }
      }
    `,
    parseResponse: ({ data }) => ({
      data: data.brand_by_pk,
    }),
  }
}

export const brandsList = ({ context, params }) => {
  const offset = (params.pagination?.page - 1) * params.pagination?.perPage || 0
  const sortField = params.sort?.field || 'id'
  const sortBy = params.sort?.order?.toLowerCase() || 'asc'
  const perPage = params.pagination?.perPage || 1000
  const variables = {}

  if (params.ids || params.filter?.name) {
    variables.where = {
      ...(params.ids && { id: { _in: params.ids } }),
      ...(params.filter.name && { name: { _ilike: `%${params.filter.name}%` } }),
    }
  }
  return {
    ...context,
    variables,
    query: gql`
      query getBrands($where: brand_bool_exp) {
        brand  (where: $where, limit: ${perPage}, offset: ${offset}, order_by: { ${sortField}: ${sortBy} })  {
          id
          name
          owner_name
          description
          top_banner_id
          top_banner {
            id
            filename
            url
          }
          hero_banner_id
          hero_banner {
            id
            filename
            url
          }
          featured_products {
            id
            saleable_product_variant {
              id
              image {
                id
                filename
                url
              }
              stock
              name
              saleable_product {
                id
                name
                total_stock
                primary_photo {
                  id
                  filename
                  url
                }
              }
            }
          }
          featured_products_aggregate {
            aggregate {
              count
            }
          }
          products_aggregate {
            aggregate {
              count
            }
          }
          saleable_products_aggregate {
            aggregate {
              count
            }
          }
        }
        brand_aggregate (where: $where) {
          aggregate {
            count
          }
        }
      }
    `,
    parseResponse: ({ data }) => ({
      data: data.brand,
      total: data.brand_aggregate.aggregate.count,
    }),
  }
}

export const categoriesList = ({ context, params }) => {
  const offset = (params.pagination?.page - 1) * params.pagination?.perPage || 0
  const sortField = params.sort?.field || 'id'
  const sortBy = params.sort?.order?.toLowerCase() || 'asc'
  const perPage = params.pagination?.perPage || 1000
  const variables = {}

  if (params.ids || typeof params.filter?.level === 'number') {
    variables.where = params.filter?.parentCategory
      ? {
          where: {
            _or: [
              { id: { _eq: params.filter.parentCategory } },
              { parent_id: { _eq: params.filter.parentCategory } },
            ],
          },
        }
      : {
          ...(params.ids && { id: { _in: params.ids } }),
          ...(typeof params.filter?.level === 'number' && { level: { _eq: params.filter?.level } }),
        }
  }
  return {
    ...context,
    variables,
    query: gql`
      query getCategories($where: category_bool_exp) {
        category(where: $where, limit: ${perPage}, offset: ${offset}, order_by: { ${sortField}: ${sortBy} })  {
          id
          name
          reference
          level
          active
          parent_id
          primary_colour
          description
          image {
            id
            filename
            url
          }
          banner {
            id
            filename
            url
          }
          hero_banner {
            id
            filename
            url
          }
          marketing_image {
            id
            filename
            url
          }
          saleable_product_categories_aggregate {
            aggregate{count}
          }
          product_categories_aggregate {
            aggregate{count}
          }
          children: subcategories (order_by: {sequence: asc_nulls_last}) {
            id
            name
            reference
            level
            active
            parent_id
            description
            primary_colour
            image {
              id
              filename
              url
            }
            banner {
              id
              filename
              url
            }
            hero_banner {
              id
              filename
              url
            }
            products {
              id
              saleable_product_variant {
                id
                image {
                  id
                  filename
                  url
                }
                stock
                name
                saleable_product {
                  id
                  name
                  total_stock
                  primary_photo {
                    id
                    filename
                    url
                  }
                }
              }
            }
            children: subcategories (order_by: {sequence: asc_nulls_last}) {
              id
              name
              reference
              icon_svg
              level
              active
              primary_colour
              parent_id
            }
          }
        }
        category_aggregate(where: $where) {
          aggregate {
            count
          }
        }
      }
    `,
    parseResponse: ({ data }) => ({
      data: data.category,
      total: data.category_aggregate.aggregate.count,
      // validUntil,
    }),
  }
}

export const categoriesGet = ({ context, params }) => {
  // const validUntil = new Date()
  // // validUntil.setTime(validUntil.getTime() + 300000) // 5 minutes
  return {
    ...context,
    variables: {
      ...params,
    },
    query: gql`
            query getCategory {
              category_by_pk (id: ${params.id}) {
                id
                name
                reference
                level
                active
                parent_id
                primary_colour
                description
                image {
                  id
                  filename
                  url
                }
                banner {
                  id
                  filename
                  url
                }
                hero_banner {
                  id
                  filename
                  url
                }
                marketing_image {
                  id
                  filename
                  url
                }
                saleable_product_categories_aggregate {
                  aggregate{count}
                }
                product_categories_aggregate {
                  aggregate{count}
                }
                children: subcategories (order_by: {sequence: asc_nulls_last}) {
                  id
                  name
                  reference
                  level
                  active
                  parent_id
                  description
                  primary_colour
                  image {
                    id
                    filename
                    url
                  }
                  banner {
                    id
                    filename
                    url
                  }
                  hero_banner {
                    id
                    filename
                    url
                  }
                  products {
                    id
                    saleable_product_variant {
                      id
                      image {
                        id
                        filename
                        url
                      }
                      stock
                      name
                      saleable_product {
                        id
                        name
                        total_stock
                        primary_photo {
                          id
                          filename
                          url
                        }
                      }
                    }
                  }
                  children: subcategories (order_by: {sequence: asc_nulls_last}) {
                    id
                    name
                    reference
                    icon_svg
                    level
                    active
                    primary_colour
                    parent_id
                  }
                }
              }
            }
          `,
    parseResponse: ({ data }) => ({
      data: data.category_by_pk,
      // validUntil,
    }),
  }
}

export const categoriesUpdate = ({ context, params, variables }) => {
  let jsonParams = JSON.stringify(params)
  jsonParams = jsonParams.replace(/"(\w+)":/g, '$1:')
  jsonParams = jsonParams.substr(1, jsonParams.length - 2)
  jsonParams = jsonParams.replace(/"\^/g, '')
  jsonParams = jsonParams.replace(/\^"/g, '')

  // console.log(params)
  return {
    ...context,
    variables,
    query: gql`
            mutation upsertCategory {
              insert_category_one(
              ${jsonParams}
            ) {
                id
              }
            }
          `,
    parseResponse: ({ data }) => ({
      data: data.insert_category_one,
    }),
  }
}

export const categoriesUpdateMany = ({ context, params }) => {
  let jsonParams = JSON.stringify(params.data)
  jsonParams = jsonParams.replace(/"(\w+)":/g, '$1:')
  jsonParams = jsonParams.substr(1, jsonParams.length - 2)
  jsonParams = jsonParams.replace(/"\^/g, '')
  jsonParams = jsonParams.replace(/\^"/g, '')
  // console.log(jsonParams)

  return {
    ...context,
    variables: {
      ...params,
    },
    query: gql`
      mutation categoriesUpdateMany {
        update_category_many(updates: [${jsonParams}]) {
          returning {
            id
            __typename
          }
        }
      }
    `,
    parseResponse: ({ data }) => ({
      data: data.update_category_many,
    }),
  }
}

export const categoriesCreateMany = ({ context, params, variables }) => {
  let jsonParams = JSON.stringify(params)
  jsonParams = jsonParams.replace(/"(\w+)":/g, '$1:')
  jsonParams = jsonParams.substr(1, jsonParams.length - 2)
  jsonParams = jsonParams.replace(/"\^/g, '')
  jsonParams = jsonParams.replace(/\^"/g, '')

  // console.log(jsonParams)
  return {
    ...context,
    variables,
    query: gql`
      mutation categoriesCreateMany {
        insert_category(
          ${jsonParams}
        ) {
          returning {
            id
          }
        }
      }
    `,
    parseResponse: ({ data }) => {
      if (data && data.insert_category?.returning) {
        return {
          data: data.insert_category.returning,
        }
      }
      return false
    },
  }
}

export const categoriesProductsCreateMany = ({ context, params, variables }) => {
  let jsonParams = JSON.stringify(params)
  jsonParams = jsonParams.replace(/"(\w+)":/g, '$1:')
  jsonParams = jsonParams.substr(1, jsonParams.length - 2)
  jsonParams = jsonParams.replace(/"\^/g, '')
  jsonParams = jsonParams.replace(/\^"/g, '')

  // console.log(jsonParams)
  return {
    ...context,
    variables,
    query: gql`
      mutation categoriesProductsCreateMany {
        insert_category_products(
          ${jsonParams}
        ) {
          returning {
            id
          }
        }
      }
    `,
    parseResponse: ({ data }) => {
      if (data && data.insert_category_products?.returning) {
        return {
          data: data.insert_category_products.returning,
        }
      }
      return false
    },
  }
}

export const categoriesDeleteMany = ({ context, params }) => {
  if (!params.where) {
    throw new Error('Missing where for delete')
  }

  return {
    ...context,
    variables: {
      ...params,
    },
    query: gql`
      mutation delCatsMany($where: category_bool_exp!) {
        delete_category(where: $where) {
          affected_rows
        }
      }
    `,
    parseResponse: ({ data, errors }) => ({
      data,
      errors,
    }),
  }
}

export const productCategoriesDelete = ({ context, params }) => {
  if (!params.where) {
    throw new Error('Missing where for delete')
  }
  // console.log(params)

  return {
    ...context,
    variables: {
      ...params,
    },
    query: gql`
      mutation delCatProds($where: product_categories_bool_exp!) {
        delete_product_categories(where: $where) {
          affected_rows
        }
      }
    `,
    parseResponse: ({ data, errors }) => ({
      data,
      errors,
    }),
  }
}

export const saleableProductCategoriesDelete = ({ context, params }) => {
  if (!params.where) {
    throw new Error('Missing where for delete')
  }
  // console.log(params)

  return {
    ...context,
    variables: {
      ...params,
    },
    query: gql`
      mutation delCatProds($where: saleable_product_categories_bool_exp!) {
        delete_saleable_product_categories(where: $where) {
          affected_rows
        }
      }
    `,
    parseResponse: ({ data, errors }) => ({
      data,
      errors,
    }),
  }
}

export const categoriesProductsDelete = ({ context, params }) => {
  if (!params.where) {
    throw new Error('Missing where for delete')
  }
  // console.log(params)

  return {
    ...context,
    variables: {
      ...params,
    },
    query: gql`
      mutation delCatProds($where: category_products_bool_exp!) {
        delete_category_products(where: $where) {
          affected_rows
        }
      }
    `,
    parseResponse: ({ data, errors }) => ({
      data,
      errors,
    }),
  }
}

export const categoriesFilesDelete = ({ context, params }) => {
  if (!params.where) {
    throw new Error('Missing where for delete')
  }
  // console.log(params)
  return {
    ...context,
    variables: {
      ...params,
    },
    query: gql`
      mutation delCatFiles($where: category_files_bool_exp!) {
        delete_category_files(where: $where) {
          affected_rows
        }
      }
    `,
    parseResponse: ({ data, errors }) => ({
      data,
      errors,
    }),
  }
}

export const variantsUpdateMany = ({ context, params }) => {
  let jsonParams = JSON.stringify(params.data)
  jsonParams = jsonParams.replace(/"(\w+)":/g, '$1:')
  jsonParams = jsonParams.substr(1, jsonParams.length - 2)
  jsonParams = jsonParams.replace(/"\^/g, '')
  jsonParams = jsonParams.replace(/\^"/g, '')

  return {
    ...context,
    variables: {
      ...params,
    },
    query: gql`
      mutation variantsUpdateMany {
        update_variant_many(updates: [${jsonParams}]) {
          returning {
            id
            __typename
          }
        }
      }
    `,
    parseResponse: ({ data }) => ({
      data: data.update_variant_many,
    }),
  }
}

export const productMaterialsUpdateMany = ({ context, params }) => {
  let jsonParams = JSON.stringify(params.data)
  jsonParams = jsonParams.replace(/"(\w+)":/g, '$1:')
  jsonParams = jsonParams.substr(1, jsonParams.length - 2)
  jsonParams = jsonParams.replace(/"\^/g, '')
  jsonParams = jsonParams.replace(/\^"/g, '')

  console.log(jsonParams)

  return {
    ...context,
    variables: {
      ...params,
    },
    query: gql`
      mutation productMaterialsUpdateMany {
        update_product_materials_many(updates: [${jsonParams}]) {
          returning {
            id
            __typename
          }
        }
      }
    `,
    parseResponse: ({ data }) => ({
      data: data.update_product_materials_many,
    }),
  }
}

export const createBrand = ({ context, params, variables }) => {
  let jsonParams = JSON.stringify(params)
  jsonParams = jsonParams.replace(/"(\w+)":/g, '$1:')
  jsonParams = jsonParams.substr(1, jsonParams.length - 2)
  jsonParams = jsonParams.replace(/"\^/g, '')
  jsonParams = jsonParams.replace(/\^"/g, '')

  return {
    ...context,
    variables,
    query: gql`
            mutation createBrand {
              insert_brand_one(
                ${jsonParams}
              ) {
                id
              }
            }
          `,
    parseResponse: ({ data }) => {
      if (data && data.insert_brand_one) {
        return {
          data: data.insert_brand_one,
        }
      }
      return false
    },
  }
}
