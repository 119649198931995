import React, { useCallback, useEffect } from 'react'
import { SaveButton, Toolbar, useRedirect } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import SearchIcon from '@material-ui/icons/Search'
import { useForm, useFormState } from 'react-final-form'

const styles = {
  customToolbar: { backgroundColor: 'transparent' },
  customToolbarNP: {
    backgroundColor: 'transparent',
    padding: '0px',
    margin: '0px',
    display: 'flex',
    alignItems: 'baseline',
  },
  customSaveButton: {
    textTransform: 'uppercase',
    backgroundColor: '#00B3C3',
    '&:hover': {
      backgroundColor: '#21C6CF',
    },
  },
}

const useStyles = makeStyles(styles)

const Confirm = (props) => {
  const classes = useStyles()
  return (
    <Toolbar
      record={props.record}
      basePath={props.basePath}
      undoable
      resource={props.redirect}
      invalid={props.invalid}
      handleSubmit={props.handleSubmit}
      saving={props.saving}
      className={props.isSearch || props.noMargin ? classes.customToolbarNP : classes.customToolbar}
    >
      <ButtonD
        submitOnEnter={false}
        invalid={props.invalid}
        basePath={props.basePath}
        saving={props.saving}
        redirect={props.redirect}
        handleSubmit={props.handleClick}
        ignoreInvalid={props.ignoreInvalid}
        label={props.label}
        className={props.className}
        disabled={props.disabled}
        color="primary"
        isSearch={props.isSearch}
        isSave={props.isSave}
      />
    </Toolbar>
  )
}

const ButtonD = (props) => {
  const redirectTo = useRedirect()
  const { redirect, handleSubmit } = props

  useEffect(() => {
    return () => {}
  }, [])

  const formState = useFormState()
  const form = useForm()
  const [saving, setSaving] = React.useState(false)
  const { isSave, isSearch, ignoreInvalid, ...rest } = props

  const handleClick = useCallback(async () => {
    const abortController = new AbortController()
    if (!abortController.signal.aborted) {
      setSaving(true)
    }
    if (!isSave && !ignoreInvalid && formState.invalid) {
      form.submit()
    } else {
      try {
        await handleSubmit(formState.values, formState)
      } catch (error) {
        // console.log(error)
        if (!abortController.signal.aborted) {
          setSaving(false)
        }
      }
      if (redirect) {
        redirectTo(redirect)
      } else {
        if (!abortController.signal.aborted) {
          setSaving(false)
        }
      }
      return () => {
        abortController.abort()
      }
    }
    if (!abortController.signal.aborted) {
      setSaving(false)
    }
    return () => {
      abortController.abort()
    }
  }, [form, formState, redirectTo, redirect, handleSubmit])

  return (
    <SaveButton
      icon={isSearch ? <SearchIcon style={{ fontSize: '22px', margin: '0px' }} /> : <></>}
      size="large"
      {...rest}
      saving={saving}
      handleSubmitWithRedirect={handleClick}
    />
  )
}

export default Confirm
