import React from 'react'
import {
  Datagrid,
  EditButton,
  Filter,
  List,
  TextField,
  NumberField,
  // Pagination,
  // TextInput,
  SearchInput,
  // DateInput,
  //   NumberInput,
  DeleteButton,
  SelectInput,
  FunctionField,
  TopToolbar,
  // CreateButton,
} from 'react-admin'
import WarningIcon from '@material-ui/icons/Warning'
// import get from 'lodash/get'
import { Tooltip, withStyles } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import { getDisplayName, getOfferStatus } from '../../../utils/utils'
import SupplierDefaultImg from '../../../assets/supplier_default.png'
import useStyles from './styles'
import CreateCustomerSaleDialog from './components/CreateCustomerSaleDialog'

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#fff',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 500,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip)

const ListActions = (props) => {
  const { className } = props
  return (
    <TopToolbar className={className}>
      <CreateCustomerSaleDialog />
    </TopToolbar>
  )
}

// const OfferPagination = (props) => <Pagination rowsPerPageOptions={[25, 50, 100]} {...props} />

export const OfferFilters = (props) => {
  const classes = useStyles()
  return (
    <Filter {...props}>
      <SearchInput
        key="search"
        InputProps={{ placeholder: 'Product SKU / Name' }}
        source="name"
        alwaysOn
        className={classes.searchBackground}
        variant="standard"
      />
      <SelectInput
        key="statusSearch"
        InputProps={{ placeholder: 'Status' }}
        label="Status"
        source="status"
        choices={[
          { name: 'Pending Purchase Order', value: 'PENDING_PURCHASE_ORDER' },
          { name: 'Pending Submission', value: 'PENDING_SUBMISSION' },
          { name: 'Pending Approval', value: 'PENDING_APPROVAL' },
          { name: 'Production', value: 'PRODUCTION' },
          { name: 'Shipping', value: 'SHIPPING' },
          { name: 'Selling ', value: 'SELLING' },
          { name: 'Cancelled ', value: 'CANCELLED' },
          { name: 'Completed ', value: 'COMPLETED' },
        ]}
        optionText="name"
        optionValue="value"
        // className={classes.hideLabelAutoComplete}
        fullWidth
        variant="standard"
        alwaysOn
      />
      {/* <SelectInput
        key="typeSearch"
        InputProps={{ placeholder: 'Type' }}
        label="Type"
        source="filterType"
        choices={[
          { name: 'Public', value: 'PUBLIC' },
          { name: 'Private', value: 'PRIVATE' },
        ]}
        optionText="name"
        optionValue="value"
        // className={classes.hideLabelAutoComplete}
        fullWidth
        variant="standard"
        alwaysOn
      /> */}
    </Filter>
  )
}

const ListCom = (props) => {
  const { saleableProductId, basePath, ...rest } = props

  // console.log(props)
  return (
    <List
      {...rest}
      basePath={basePath}
      sort={{ field: 'id', order: 'DESC' }}
      filter={{
        ...(!saleableProductId && { type: ['PUBLIC', 'PRIVATE'] }),
        ...(saleableProductId && { saleable_product_id: saleableProductId }),
      }}
      filters={!saleableProductId ? <OfferFilters /> : null}
      // actions={null}
      actions={!saleableProductId && <ListActions />}
      empty={false}
      perPage={25}
      bulkActionButtons={false}
      // pagination={<OfferPagination />}
    >
      <Datagrid path="offer" rowClick="show">
        <NumberField source="id" label="Offer ID" />
        <FunctionField
          label="Photo"
          render={(record) => (
            <div style={{ width: '50px', height: '50px' }}>
              <img
                style={{ maxHeight: '50px' }}
                src={record.thumbnail?.url || SupplierDefaultImg}
                // alt=""
                alt={<img src={SupplierDefaultImg} alt="" />}
              />
            </div>
          )}
        />
        <TextField source="name" label="Product (SKU)" />
        <FunctionField
          label="Type"
          render={(record) => (record.type === 'PRIVATE' ? 'Private' : 'Public')}
        />
        <FunctionField
          label="Order Qty"
          render={(record) => (
            <span>
              {record.moq}
              <HtmlTooltip
                placement="bottom-start"
                title={
                  <div style={{ backgroundColor: 'lightgray', padding: '5px' }}>
                    <span>Order Quantities and Stock:</span>
                    <table>
                      <thead>
                        <tr style={{ border: 'solid 1px gray', backgroundColor: '#fff' }}>
                          <th>MOQ/Target Qty</th>
                          <th>Total Ordered</th>
                          <th>Total Batch Qty</th>
                          <th>Total Batch Stock</th>
                          <th>Total Inventory Stock</th>
                          <th>Total Allocated</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr style={{ border: 'solid 1px gray', backgroundColor: '#fff' }}>
                          <td style={{ padding: '3px', textAlign: 'center' }}>{record.moq}</td>
                          <td style={{ padding: '3px', textAlign: 'center' }}>
                            {record.total_sold || 0}
                          </td>
                          <td style={{ padding: '3px', textAlign: 'center' }}>
                            {record.batch_quantity?.aggregate?.sum?.quantity}
                          </td>
                          <td style={{ padding: '3px', textAlign: 'center' }}>
                            {record.stock?.aggregate?.sum?.stock}
                          </td>
                          <td style={{ padding: '3px', textAlign: 'center' }}>
                            {record.inventory_stock?.aggregate?.sum?.stock || 'N/A'}
                          </td>
                          <td style={{ padding: '3px', textAlign: 'center' }}>
                            {record.allocated?.aggregate?.sum?.quantity || 'N/A'}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <span>Batches:</span>
                    <table>
                      <thead>
                        <tr style={{ border: 'solid 1px gray', backgroundColor: '#fff' }}>
                          <th>Variant Id</th>
                          <th>Variant SKU</th>
                          <th>Variant Specs</th>
                          <th>Quantity Ordered</th>
                          <th>Current Stock</th>
                        </tr>
                      </thead>
                      <tbody>
                        {record.batches?.map((batch) => (
                          <tr
                            key={`btch${batch.id}`}
                            style={{ border: 'solid 1px gray', backgroundColor: '#fff' }}
                          >
                            <td style={{ padding: '3px', textAlign: 'center' }}>{batch.id}</td>
                            <td style={{ padding: '3px', textAlign: 'center' }}>
                              {batch.saleable_product_variant?.variant?.sku}
                            </td>
                            <td style={{ padding: '3px', textAlign: 'center' }}>
                              {batch.saleable_product_variant?.variant?.values
                                ?.map((el) => el.value)
                                .join(',')}
                            </td>
                            <td style={{ padding: '3px', textAlign: 'center' }}>
                              {batch.quantity}
                            </td>
                            <td style={{ padding: '3px', textAlign: 'center' }}>{batch.stock}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>

                    <div style={{ marginTop: '5px' }}>
                      <span>Key:</span>
                      <ul>
                        <li>MOQ is the total of all variants specified in the offer creation.</li>
                        <li>
                          Total Ordered is the total quantity sold as Group Purchases/Offer
                          Purchases. May be different to the MOQ for overflows.
                        </li>
                        <li>
                          Total Batch Qty is the total of each variant batch created and should be
                          the same as the Total Ordered
                        </li>
                        <li>
                          Total Batch Stock is the current stock level of each variant batch after
                          sales
                        </li>
                        <li>
                          Total Inventory Stock is the current stock level of each inventory and
                          should be equal to the Batch Stock
                        </li>
                        <li>
                          Total Allocated is the total quantity allocated after a sale, and should
                          align with the stock levels remaining
                        </li>
                      </ul>
                      <span>
                        Note that there are two running stock levels - Batch and Inventory. Batch
                        stock relates to physical stock, inventory stock is a users claim on an
                        item. If Batch is more than inventory, it means physical stock is not owned.
                        If inventory is more, there is potential for shortage.
                      </span>
                    </div>
                  </div>
                }
              >
                <InfoIcon style={{ fontSize: '16px' }} />
              </HtmlTooltip>
            </span>
          )}
        />
        {/* <TextField source="moq" label="Order Qty" /> */}

        <FunctionField
          label="# of Orders"
          render={(record) =>
            record.ordersToApprove?.aggregate?.count > 0 ? (
              <div>
                <span>{record.orders?.aggregate?.count || 0}</span>
                <Tooltip placement="bottom-start" title="Outstanding Payments">
                  <WarningIcon style={{ marginLeft: '5px', color: 'red' }} />
                </Tooltip>
              </div>
            ) : (
              record.orders?.aggregate?.count
            )
          }
        />
        <FunctionField
          label="% Fulfilled"
          render={(record) => `${((record.total_sold / record.moq) * 100).toFixed(0) || 0}%`}
        />
        {/* <FunctionField label="# of Sellers" render={() => 0} /> */}
        {/* <FunctionField source='sellers' label='# of Sellers' /> */}
        <FunctionField
          label="% Sold"
          render={(record) => {
            return record.allocated?.aggregate?.sum?.quantity && record.moq
              ? `${((record.allocated?.aggregate?.sum?.quantity / record.moq) * 100).toFixed(0)}%`
              : '0%'
          }}
        />
        <FunctionField label="Returns" render={() => 0} />
        <FunctionField
          label="Status"
          render={(record) => <span>{getOfferStatus(record.status)}</span>}
        />
        <FunctionField label="SCA" render={(record) => getDisplayName(record.sca)} />
        {!saleableProductId && <EditButton />}
        {!saleableProductId && <DeleteButton undoable={false} />}
      </Datagrid>
    </List>
  )
}

export default ListCom
