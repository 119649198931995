import React from 'react'
import {
  Box,
  //  IconButton
} from '@material-ui/core'
// import useStyles from '../styles/index'
import {
  List,
  Datagrid,
  TextField,
  FunctionField,
  DateField,
  Pagination,
  // useDataProvider,
  // useNotify,
  // useRefresh,
} from 'react-admin'
// import moment from 'moment'
import get from 'lodash/get'
// import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined'

const SalesManagement = (props) => {
  // const dataProvider = useDataProvider()
  // const notify = useNotify()
  // const refresh = useRefresh()
  // const classes = useStyles()
  // console.log('OMP', props)

  const DefaultPagination = (props) => {
    // console.log(props)
    return (
      <Pagination
        count={Object.keys(props.data).length}
        rowsPerPageOptions={[25, 50, 100]}
        {...props}
      />
    )
  }

  return (
    <Box p="0px 60px">
      <List
        {...props}
        sort={{ field: 'id', order: 'DESC' }}
        actions={null}
        perPage={25}
        // disableSyncWithLocation
        bulkActionButtons={false}
        resource="saleable_product_sale_allocation"
        basePath="/offer"
        empty={false}
        filter={{ offer_id: props.id }}
        pagination={<DefaultPagination />}
      >
        <Datagrid>
          <TextField label="Allocation ID" source="id" />
          <TextField label="Sale ID" source="saleable_product_sale_id" />
          {/* <FunctionField
            label="Order # (Date)"
            render={(record) =>
              `${get(record, 'id', '')} (${moment(get(record, 'created_at', '')).format(
                'YYYY-MM-DD',
              )})`
            }
          /> */}
          <DateField source="saleable_product_sale.created_at" label="Created Date" />
          <TextField label="Quantity" source="quantity" />
          <FunctionField
            label="Value"
            render={(record) => `$${(get(record, 'amount', '') / 100).toFixed(2)}`}
          />
          <FunctionField
            label="Profit"
            render={(record) => `$${(get(record, 'profit', '') / 100).toFixed(2)}`}
          />
          <TextField label="Owner ID" source="organisation_inventory.owner.owner_user_id" />
          <FunctionField
            label="Owner"
            render={(record) => `${get(record, 'organisation_inventory.owner.name', '')}`}
          />
          <TextField label="Platform" source="saleable_product_sale.platform" />
        </Datagrid>
      </List>
    </Box>
  )
}

export default SalesManagement
