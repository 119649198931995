import React from 'react'
import { Box } from '@material-ui/core'
import { useDataProvider } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import MultiplePhotoSelect from '../../components/fieldInputs/MultiplePhotoSelect'
import SpecificationArrayFieldInput from '../../components/fieldInputs/SpecificationArrayFieldInput'
import TextFieldInput from '../../components/fieldInputs/TextFieldInput'
import RichTextFieldInput from '../../components/fieldInputs/RichTextFieldInput'
import { makeStyles } from '@material-ui/core/styles'
import ColourImagesSelection from './ColourImagesSelection'
const useStyles = makeStyles(() => ({
  spacer: {
    height: '10px',
  },
  sectionHeader: {
    fontSize: '12px',
    color: 'rgb(18, 183, 106)',
  },
}))

const GeneralDetails = () => {
  const classes = useStyles()
  const { values } = useFormState()
  const form = useForm()
  const dataProvider = useDataProvider()

  // console.log(values)

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <span className={classes.sectionHeader}>Photos and Media</span>
      <div className={classes.spacer} />
      <MultiplePhotoSelect
        id={values.id}
        hidePrimary
        reference="saleable_product_id"
        resource="saleable_product_files"
        type="^ADDITIONAL_PHOTO^"
        field="images"
        primaryField="primary_photo"
        onClickPreview={async (file) => {
          await dataProvider.update('saleable_product', {
            data: {
              primary_photo_id: file.file.id,
              id: values.id,
            },
          })
          form.change('primary_photo', file.file)
        }}
        onSave={async (data) => {
          if (!values.primary_photo?.id) {
            await dataProvider.update('saleable_product', {
              data: {
                primary_photo_id: data[0].file?.id,
                id: values.id,
              },
            })
            await form.change('primary_photo', {
              id: data[0].file?.id,
              filename: data[0].file?.filename,
              url: data[0].file.url,
            })
            // checkFormValid(values)
          }
        }}
      />
      <div className={classes.spacer} />
      <div className={classes.spacer} />
      <span className={classes.sectionHeader}>Color Images</span>
      <div className={classes.spacer} />
      <ColourImagesSelection />
      <div className={classes.spacer} />
      <div className={classes.spacer} />
      <span className={classes.sectionHeader}>Name and Description</span>
      <div className={classes.spacer} />
      <TextFieldInput
        variant="standard"
        fullWidth
        field="name"
        resource="saleable_product"
        id={values.id}
        label="Name:"
        // onChange={() => checkFormValid(values)}
        // validate={required()}
        value={values.name}
      />
      <div className={classes.spacer} />
      <TextFieldInput
        variant="standard"
        fullWidth
        field="sku"
        resource="saleable_product"
        id={values.id}
        label="SKU Prefix:"
        // onChange={() => checkFormValid(values)}
        // validate={required()}
        value={values.sku}
      />
      <div className={classes.spacer} />
      <TextFieldInput
        variant="standard"
        fullWidth
        field="description"
        resource="saleable_product"
        id={values.id}
        label="Short description:"
        // validate={required()}
        multiline
        minRows={2}
        // onChange={() => checkFormValid(values)}
        style={{
          alignItems: 'start',
        }}
        value={values.short_description}
      />
      <div className={classes.spacer} />
      <TextFieldInput
        variant="standard"
        fullWidth
        field="long_description"
        resource="saleable_product"
        id={values.id}
        label="Long description:"
        multiline
        minRows={2}
        // onChange={() => checkFormValid(values)}
        style={{
          alignItems: 'start',
        }}
        value={values.long_description}
      />
      <div className={classes.spacer} />
      <span className={classes.sectionHeader}>Specifications</span>
      <div className={classes.spacer} />
      <SpecificationArrayFieldInput
        variant="standard"
        fullWidth
        fieldLabel="Other specifications"
        resourceName="saleable_product_specifications"
        source="otherSpecifications"
        fieldName="specifications"
        referenceField="saleable_product_id"
        referenceId={values.id}
        oldDataField="oldSpecifications"
        valueField="specification"
        valueSource="specification.value"
        onConflict={{
          on_conflict: {
            constraint: '^saleable_product_specificatio_saleable_product_id_specifica_key^',
            update_columns: ['^saleable_product_id^', '^specification_id^'],
          },
        }}
      />
      <div className={classes.spacer} />
      <span className={classes.sectionHeader}>Selling Features</span>
      <RichTextFieldInput
        // variant="standard"
        source="selling_features"
        resource="saleable_product"
        id={values.id}
        label={false}
      />
      <span className={classes.sectionHeader}>Terms & Conditions</span>
      <RichTextFieldInput
        // variant="standard"
        source="terms_conditions"
        resource="saleable_product"
        id={values.id}
        label={false}
      />
    </Box>
  )
}

export default GeneralDetails
