import React, { useState, useEffect } from 'react'
import { useInput, useDataProvider, useNotify } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { TextField, Chip, Box, CircularProgress, IconButton } from '@material-ui/core'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'
import BorderColorIcon from '@material-ui/icons/BorderColorOutlined'
import CancelOutlined from '@material-ui/icons/CancelOutlined'
import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined'

const AutocompleteFieldInput = (props) => {
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const form = useForm()
  const { values } = useFormState()
  const [options, setOptions] = useState([])
  const [editing, setEditing] = useState(false)
  const [saving, setSaving] = useState(false)

  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option) => option.name,
  })

  const {
    id,
    fieldLabel,
    elementName,
    resource,
    reference,
    referenceField,
    referenceOnConflict,
    source,
    className,
    onSave,
    ...other
  } = props
  const {
    input: { name }, // onChange, ...rest },
    meta: { touched, error },
    // isRequired,
  } = useInput(props)

  useEffect(() => {
    return () => {}
  }, [])

  useEffect(() => {
    const abortController = new AbortController()

    const getDataLists = async () => {
      try {
        const { data: optsTemp } = await dataProvider.getList(reference, {
          pagination: { page: 1, perPage: 100 },
          sort: { field: 'name', order: 'ASC' },
        })

        if (!abortController.signal.aborted) {
          setOptions(optsTemp)
        }
      } catch (error) {
        console.log(error)
      }
    }

    editing && getDataLists()

    return () => {
      abortController.abort()
    }
  }, [editing])

  // eslint-disable-next-line no-unused-vars
  // console.log(values[source])
  // console.log(options)

  //   if (!options.some((opt) => opt.name === values[source])) {
  //     options.push({ id: 0, name: values[source] })
  //   }
  return (
    <Box display="flex" width="100%">
      <div
        style={{ width: '100%' }}
        className={`${className} MuiFormControl-root MuiTextField-root`}
      >
        <div
          className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-formControl MuiInput-formControl"
          style={{ width: '100%' }}
        >
          <Autocomplete
            value={values[source] || ''}
            // multiple
            id="tags-filled"
            options={options}
            freeSolo
            fullWidth
            disabled={!editing}
            getOptionLabel={(option) => {
              return option.name || option.id || option
            }}
            filterOptions={filterOptions}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  key={`optKey${index}`}
                  variant="outlined"
                  label={option.name || option.id || option}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label={fieldLabel}
                placeholder="Select..."
                onChange={(e) => {
                  console.log(e.target.value)
                  form.change(source, e.target.value)
                }}
              />
            )}
            name={name}
            onChange={(event, newValue) => {
              console.log(newValue)
              const newOption = newValue?.name
              console.log(newOption)
              console.log(typeof newOption)
              if (typeof newOption === 'string') {
                form.change(source, newOption)
              } else {
                form.change(source, newValue)
              }
            }}
            {...other}
          />
        </div>
        <p
          className={
            touched && error
              ? 'MuiFormHelperText-root MuiFormHelperText-contained Mui-error'
              : 'MuiFormHelperText-root MuiFormHelperText-contained'
          }
        >
          {touched && error ? 'Required' : null}
        </p>
      </div>
      {editing ? (
        <Box display="flex">
          <IconButton
            onClick={async () => {
              setSaving(true)
              try {
                console.log(values)
                console.log(values[source])

                await dataProvider.create(reference, {
                  object: { [referenceField]: values[source].name || values[source] },
                  on_conflict: referenceOnConflict,
                })

                await dataProvider.update(resource, {
                  data: { id, [elementName]: values[source].name || values[source] },
                })

                // Compare new values to old and insert difference
                // console.log(values[source])
                // console.log(values[oldData])

                // const newValues = []
                // const deleteValues = []

                // values[source]?.map((item) => {
                //   // if (!values[oldData].some((oldItem) => oldItem[fieldName] === item.name)) {
                //   newValues.push({ [fieldName]: item.name, [referenceField]: referenceId })
                //   // }
                // })

                // // Compare old values to new and delete difference
                // values[oldData]?.map((oldItem) => {
                //   if (!values[source]?.some((item) => item.name === oldItem[fieldName])) {
                //     deleteValues.push(oldItem.id)
                //   }
                // })

                // // console.log(newValues, deleteValues)

                // if (newValues.length > 0) {
                //   const resp = await dataProvider.create(resourceName, {
                //     data: newValues,
                //     ...(onConflict && { ...onConflict }),
                //   })
                //   // console.log(resp)

                //   const newData =
                //     resp?.data?.id === 0
                //       ? resp.data.objects?.map((el) => ({
                //           id: el.id,
                //           name: el[fieldName],
                //           [fieldName]: el[fieldName],
                //           own: resourceFilter,
                //         }))
                //       : [
                //           {
                //             id: resp.data.id,
                //             name: resp.data[fieldName],
                //             [fieldName]: resp.data[fieldName],
                //             own: resourceFilter,
                //           },
                //         ]

                //   form.batch(() => {
                //     form.change(source, newData)
                //     form.change(oldData, newData)
                //   })
                // }
                // if (deleteValues.length > 0) {
                //   await dataProvider.deleteMany(resourceName, {
                //     ids: deleteValues,
                //   })
                // }

                setSaving(false)

                setEditing(false)
                notify('Update Complete', { type: 'info' })
                onSave && onSave()
              } catch (error) {
                setSaving(false)
                console.log(error)
                notify(error.message, { type: 'warning' })
              }
            }}
            style={{ maxWidth: '50px', maxHeight: '50px' }}
          >
            <CheckCircleOutlineOutlined fontSize="small" style={{ color: 'FF0000' }} />
          </IconButton>
          <IconButton
            onClick={() => {
              setEditing(false)
            }}
            style={{ maxWidth: '50px', maxHeight: '50px' }}
          >
            <CancelOutlined fontSize="small" />
          </IconButton>
          {saving && <CircularProgress style={{ width: '20px', height: '20px' }} />}
        </Box>
      ) : (
        <IconButton
          onClick={() => {
            setEditing(true)
          }}
          style={{ maxWidth: '50px', maxHeight: '50px' }}
        >
          <BorderColorIcon fontSize="small" />
        </IconButton>
      )}
    </Box>
  )
}

export default AutocompleteFieldInput
