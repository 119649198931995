import React from 'react'
import { Elements, PaymentElement } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
// import SetupForm from './SetupForm';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// const stripePromise = loadStripe(
//   'pk_test_51O0bpuG3YCdXCMUgwPE4XhbWpm3vK2tFli1MCd1V1Y19Xal9TgWEAb1zbu1quX2L5qzzjRWVfnQxbIDtmPpSEywW00hzmcxsnx',
// )

const stripePromise = loadStripe('pk_test_7BPkJ7gmF2ZhluSHZXLIAVNz00grdjoD2D')

const CheckoutIntent = ({ clientSecret }) => {
  const options = {
    // passing the SetupIntent's client secret
    clientSecret:
      clientSecret || 'seti_1OcOWhG3YCdXCMUgg6s6QP6H_secret_PRH4NfIoI64HbRqRY9krPKpWJmnXGCg',
    // Fully customizable with appearance API.
    appearance: {
      /*...*/
    },
  }

  return (
    <Elements stripe={stripePromise} options={options}>
      <SetupForm />
    </Elements>
  )
}

const SetupForm = () => {
  return (
    <form>
      <PaymentElement />
      <button>Submit</button>
    </form>
  )
}

export default CheckoutIntent
