import React, { useState } from 'react'
import { TextInput, useRedirect, useDataProvider, useNotify } from 'react-admin'
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  withStyles,
} from '@material-ui/core'
import { useFormState, useForm } from 'react-final-form'
import { getDisplayName, getPlatformName } from '../../../utils/utils'
import NumberFormat from '../../components/CustomNumberFormat'
import TextFieldInput from '../../components/fieldInputs/TextFieldInput'
import { makeStyles } from '@material-ui/core/styles'

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#FFFFFF',
    color: '#000000',
    padding: '6px',
    fontSize: '12px',
    border: '1px solid #DEDEDE',
  },
  body: {
    border: '1px solid #DEDEDE',
    padding: '0px',
    fontSize: 12,
  },
}))(TableCell)
const useStyles = makeStyles(() => ({
  sectionHeader: {
    fontSize: '18px',
    color: 'rgb(18, 183, 106)',
    margin: '0px 0px 20px',
  },
  fieldLabel: {
    fontSize: '12px',
    margin: '4px 0px',
    flex: 1,
  },
  fieldValue: {
    fontSize: '12px',
    margin: '4px 0px',
    flex: 6,
  },
  imageContainer: {
    height: '120px',
    width: '120px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  primaryImage: {
    maxWidth: '120px',
    maxHeight: '120px',
  },
  productQuantity: {
    fontSize: '16px',
    fontWeight: '400',
    margin: '0px 10px',
  },
  productAmount: {
    fontSize: '18px',
    fontWeight: '500',
    margin: '0px 10px',
  },
  table: {
    overflowX: 'unset',
  },
  numberTableInput: {
    width: '100%',
    height: '100%',
    marginTop: '0px',
    marginBottom: '0px',
    // height: '30px',
    minHeight: '30px',
    padding: '0px',
    '& .MuiFilledInput-underline::before': {
      borderBottom: 'none',
    },
  },
  notifyButton: {
    color: 'black',
    width: '50%',
    border: 'solid 1px rgba(180, 125, 80, 0.5)',
    backgroundColor: 'rgba(255, 149, 102, 0.5)',
    '&:hover': {
      backgroundColor: 'rgba(255, 149, 102, 0.3)',
    },
  },
}))

const ConsumerOrdersForm = () => {
  const { values } = useFormState()
  const classes = useStyles()
  const notify = useNotify()
  const dataProvider = useDataProvider()
  const form = useForm()

  const [saving, setSaving] = useState(false)

  // console.log(values)
  return (
    <Box p="40px 60px 30px" style={{ backgroundColor: '#FFF' }} mt="30px">
      <Box display="flex">
        <Box flex={2} display="flex" flexDirection="column">
          <span className={classes.sectionHeader}>Purchase Information</span>
          <Box display="flex">
            <span className={classes.fieldLabel}>To:</span>
            <span className={classes.fieldValue}>
              {values.contact_name}
              {values.consumer ? ` - Registered User Id: ${values.consumer?.id}` : null}
            </span>
          </Box>
          <Box display="flex">
            <span className={classes.fieldLabel}>Address:</span>
            <span className={classes.fieldValue}>{values.delivery_address}</span>
          </Box>
          <Box display="flex">
            <span className={classes.fieldLabel}>Email:</span>
            <span className={classes.fieldValue}>{values.contact_email}</span>
          </Box>
          <Box display="flex">
            <span className={classes.fieldLabel}>Phone:</span>
            <span className={classes.fieldValue}>{values.contact_phone}</span>
          </Box>
          <Box display="flex">
            <span className={classes.fieldLabel}>Platform:</span>
            <span className={classes.fieldValue}>{getPlatformName(values.platform)}</span>
          </Box>
          <Box display="flex">
            <span className={classes.fieldLabel}>Seller:</span>
            <span className={classes.fieldValue}>{getDisplayName(values.seller)}</span>
          </Box>
        </Box>

        <Box
          flex={1}
          display="flex"
          flexDirection="column"
          alignItems="flex-end"
          justifyContent="space-between"
        >
          <span className={classes.sectionHeader}>Tracking Details</span>
          <TextFieldInput
            variant="standard"
            fullWidth
            field="tracking_url"
            resource="consumer_order"
            id={values.id}
            label="Tracking URL:"
            // validate={required()}
            value={values.tracking_url}
          />
          <TextFieldInput
            variant="standard"
            fullWidth
            field="tracking_number"
            resource="consumer_order"
            id={values.id}
            label="Tracking Number:"
            // validate={required()}
            value={values.tracking_number}
          />
          <Tooltip title={'Will also set order status to Shipped'}>
            <div>
              <Button
                className={classes.notifyButton}
                disabled={!values.tracking_number || !values.tracking_url}
                onClick={async () => {
                  setSaving(true)
                  try {
                    const resp = await dataProvider.action('sendConsumerOrderShippedEmail', {
                      data: { orderId: values.id },
                    })

                    console.log(resp)

                    await dataProvider.update('consumer_order', {
                      data: { id: values.id, status: 'SHIPPED' },
                    })
                    notify('Email sent successfully.', { type: 'info' })
                    form.change('status', 'SHIPPED')
                    setSaving(false)
                  } catch (e) {
                    console.log(e)
                    notify('Error sending email.', { type: 'warning' })
                    setSaving(false)
                  }
                }}
              >
                {saving ? (
                  <CircularProgress style={{ width: '20px', height: '20px' }} />
                ) : (
                  <span style={{ fontSize: '12px' }}>Notify consumer</span>
                )}
              </Button>
            </div>
          </Tooltip>
        </Box>
      </Box>
      <Box mt="20px">
        <span className={classes.sectionHeader}>Order Items</span>
        {values.items?.map((el) => (
          <ItemCard key={el.id} item={el} />
        ))}
      </Box>
      <Box mt="20px">
        <span className={classes.sectionHeader}>Allocation(s)</span>
        <TableContainer component={Box} className={classes.table}>
          <Table className={classes.table} aria-label="shipping table">
            <TableHead>
              <TableRow>
                <StyledTableCell width="10%">Id</StyledTableCell>
                <StyledTableCell width="10%">Buyer Id</StyledTableCell>
                <StyledTableCell width="25%">Buyer</StyledTableCell>
                <StyledTableCell width="15%">Quantity</StyledTableCell>
                <StyledTableCell width="15%">Profit</StyledTableCell>
                <StyledTableCell width="15%">Amount</StyledTableCell>
                <StyledTableCell width="10%">Offer Id</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {values.items?.map((item, itemIndex) => {
                return item.saleable_product_sale_allocations?.map(
                  (allocation, allocationIndex) => (
                    <AllocationRow
                      key={allocation.id}
                      itemIndex={itemIndex}
                      allocationIndex={allocationIndex}
                    />
                  ),
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  )
}

const ItemCard = ({ item }) => {
  const classes = useStyles()
  const redirect = useRedirect()
  // console.log(item)

  return (
    <Card
      style={{
        display: 'flex',
        marginTop: '10px',
        padding: '10px',
        justifyContent: 'space-between',
        width: '50vw',
      }}
    >
      <Box className={classes.imageContainer}>
        <img
          className={classes.primaryImage}
          src={item.saleable_product?.primary_photo?.url}
          alt=""
        />
      </Box>
      <Box display="flex" width="100%" justifyContent="space-evenly" alignItems="center">
        <Box flex={1} display="flex" flexDirection="column" justifyContent="center">
          <Box display="flex">
            <span className={classes.fieldLabel}>Product:</span>
            <span className={classes.fieldValue}>{item.saleable_product?.name}</span>
          </Box>
          <Box display="flex">
            <span className={classes.fieldLabel}>Brand:</span>
            <span className={classes.fieldValue}>{item.saleable_product?.brand}</span>
          </Box>
          <Box display="flex">
            <span className={classes.fieldLabel}>Options:</span>
            <span className={classes.fieldValue}>
              {item.product_variant?.variant?.values
                ?.map((val) => val.option_value?.value || val.value)
                .join()}
            </span>
          </Box>
          <Box display="flex">
            <span className={classes.fieldLabel}>SKU:</span>
            <span className={classes.fieldValue}>
              {item.saleable_product?.sku}
              {item.product_variant?.variant?.sku ? `-${item.product_variant?.variant?.sku}` : null}
            </span>
          </Box>
        </Box>
        <Box
          p="0px 15px"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="flex-end"
          height="100%"
        >
          <Box display="flex" flexDirection="column">
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <span className={classes.fieldLabel}>Quantity:</span>
              <span className={classes.productQuantity}>{item.quantity}</span>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <span className={classes.fieldLabel}>Amount:</span>
              <span className={classes.productAmount}>${item.amount / 100}</span>
            </Box>
          </Box>
          <Button
            onClick={() => redirect(`/saleableProducts/${item.saleable_product?.id}`)}
          >{`Go to product ->`}</Button>
        </Box>
      </Box>
    </Card>
  )
}

const AllocationRow = ({ allocationIndex, itemIndex }) => {
  const classes = useStyles()

  // console.log(allocation, item)
  return (
    <TableRow>
      <StyledTableCell align="right">
        <NumberFormat
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={0}
          fixedDecimalScale
          isNumericString
          source={`items[${itemIndex}].saleable_product_sale_allocations[${allocationIndex}].id`}
        />
      </StyledTableCell>
      <StyledTableCell align="right">
        <NumberFormat
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={0}
          fixedDecimalScale
          isNumericString
          disabled
          source={`items[${itemIndex}].saleable_product_sale_allocations[${allocationIndex}].organisation_inventory.owner.id`}
        />
      </StyledTableCell>
      <StyledTableCell align="right">
        <TextInput
          className={classes.numberTableInput}
          disabled
          fullWidth
          label={false}
          variant="standard"
          source={`items[${itemIndex}].saleable_product_sale_allocations[${allocationIndex}].organisation_inventory.owner.name`}
        />
      </StyledTableCell>
      <StyledTableCell align="right">
        <NumberFormat
          className={classes.numberTableInput}
          displayType="input"
          disabled
          thousandSeparator
          decimalScale={0}
          fixedDecimalScale
          isNumericString
          source={`items[${itemIndex}].saleable_product_sale_allocations[${allocationIndex}].quantity`}
        />
      </StyledTableCell>
      <StyledTableCell align="right">
        <NumberFormat
          className={classes.numberTableInput}
          displayType="input"
          disabled
          thousandSeparator
          decimalScale={2}
          fixedDecimalScale
          isNumericString
          prefix="$ "
          source={`items[${itemIndex}].saleable_product_sale_allocations[${allocationIndex}].profitDiv`}
        />
      </StyledTableCell>
      <StyledTableCell align="right">
        <NumberFormat
          className={classes.numberTableInput}
          displayType="input"
          disabled
          thousandSeparator
          decimalScale={2}
          fixedDecimalScale
          isNumericString
          prefix="$ "
          source={`items[${itemIndex}].saleable_product_sale_allocations[${allocationIndex}].amountDiv`}
        />
      </StyledTableCell>
      <StyledTableCell align="right">
        <NumberFormat
          className={classes.numberTableInput}
          displayType="input"
          disabled
          thousandSeparator
          decimalScale={0}
          fixedDecimalScale
          isNumericString
          source={`items[${itemIndex}].saleable_product_sale_allocations[${allocationIndex}].offer_id`}
        />
      </StyledTableCell>
    </TableRow>
  )
}

export default ConsumerOrdersForm
