import React, { useState, useEffect } from 'react'
import {
  Create,
  useDataProvider,
  useNotify,
  useGetIdentity,
  FormWithRedirect,
  SelectInput,
} from 'react-admin'
import {
  Box,
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogContent,
  DialogTitle,
  CircularProgress,
  //   Tooltip,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/DeleteOutlined'
import { useForm, useFormState } from 'react-final-form'
import NumberFormat from '../../../components/CustomNumberFormat'
import { withStyles } from '@material-ui/core/styles'
import Confirm from '../../../components/Confirm'
import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment'

const useStyles = makeStyles(() => ({
  numberInput: {
    width: '80px',
    height: '36px',
    textAlign: 'center',
  },
  numberTableInput: {
    width: '99%',
    height: '100%',
    marginTop: '0px',
    marginBottom: '0px',
    marginLeft: '1%',
    // height: '30px',
    minHeight: '30px',
    padding: '0px',
    '& .MuiInput-underline::before': {
      borderBottom: 'none !important',
    },
    '& .MuiFilledInput-underline::before': {
      borderBottom: 'none',
    },
    '& .MuiInputBase-root': {
      borderBottom: 'none !important',
      height: '100%',
      minHeight: '30px',
      '& .input': {
        border: 'solid 1px green',
        width: '50%',
      },
    },
  },
  selectTableInput: {
    width: '100%',
    height: '100%',
    padding: '0px',
    '& .MuiInputBase-root': {
      borderBottom: 'none !important',
      height: '100%',
      width: '100%',
      minHeight: '30px',
    },
  },
  saveButton: {
    color: 'black',
    border: 'solid 1px rgba(180, 125, 80, 0.5)',
    backgroundColor: 'rgb(255, 255, 255)',
    '&:hover': {
      backgroundColor: 'rgba(255, 149, 102, 0.3)',
    },
  },
}))

const StyledTableCell = withStyles(() => ({
  head: {
    // backgroundColor: '#1E1E1E',
    // color: '#FFFFFF',
    padding: '2px',
    borderRight: '1px solid #ddd',
    '& last-child': {
      borderRight: 'none',
    },
  },
  body: {
    fontSize: 12,
    borderRight: '1px solid #ddd',
  },
}))(TableCell)
const BodyTableCell = withStyles(() => ({
  head: {
    color: '#FFFFFF',
    borderRight: '1px solid #ddd',
    '& last-child': {
      borderRight: 'none',
    },
  },
  body: {
    fontSize: 12,
    padding: '0',
    height: '36px',
    borderRight: '1px solid #ddd',
  },
}))(TableCell)

const CreateCustomerSaleDialog = (props) => (
  <Create
    {...props}
    actions={null}
    component="div"
    basePath="/offer"
    resource="saleable_product_sale"
    //    mutationMode="pessimistic"
  >
    <CreateCustomerSale />
  </Create>
)

const CreateCustomerSale = (props) => {
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const { identity } = useGetIdentity()

  const [createCustomerSaleOpen, setCreateCustomerSaleOpen] = useState(false)
  //   const [currentTab, setCurrentTab] = useState('one')

  const [products, setProducts] = useState([])
  const [orderConfirmed, setOrderConfirmed] = useState(false)
  const [paymentIntentId, setPaymentIntentId] = useState()
  const [consumerOrderId, setConsumerOrderId] = useState()

  const handleConfirmOrder = async () => {
    await dataProvider.update('saleableProductSalesConfirm', {
      data: { paymentIntentId },
    })
    notify('ORDER CONFIRMED', { type: 'info' })
  }

  const handleApproveOrder = async () => {
    await dataProvider.update('saleableProductSalesApprovePayment', {
      data: { consumerOrderId },
    })
    notify('ORDER CONFIRMED', { type: 'info' })
  }

  const handleCreateCustomerOrder = async (submission) => {
    // console.log(submission, identity)

    const params = {
      //   sellerCode: identity.code,
      platform: 'ADMIN',
      deliveryAddress: '',
      contactName: identity.email,
      contactEmail: identity.email,
      contactPhone: identity.mobile || '',
      items: submission.salesData?.map((el) => ({
        saleableProductId: el.saleableProductId,
        courierFees: 0,
        quantity: el.quantity,
        options: el.variantObject?.saleable_product_variant?.variant?.values?.map((obj) => ({
          option_id: obj.option_id,
          option_name: obj.option_name,
          value: obj.value,
        })),
      })),
    }

    // console.log(params)

    try {
      const { data } = await dataProvider.create('saleableProductSalesCreate', { data: params })
      //   console.log(data)
      setPaymentIntentId(data.paymentIntentId)
      setConsumerOrderId(data.consumerOrderId)
      notify('CREATED SUCCESFULLY', { type: 'info' })
    } catch (error) {
      console.log(error)
      notify(JSON.stringify(error), { type: 'warning' })
      throw new Error(error)
    }
  }

  useEffect(() => {
    return () => {}
  }, [])

  useEffect(() => {
    const getDataLists = async () => {
      try {
        const { data } = await dataProvider.getList('offersInvestmentList', {
          pagination: {
            page: 1,
            perPage: 25,
          },
          sort: {
            id: 'desc_nulls_last',
          },
          filter: { type: 'PUBLIC', status: 'SELLING' },
        })
        // console.log(data, total)
        !abortController.signal.aborted && setProducts(data)
      } catch (error) {
        // console.log(error)
        notify(error.message.split('.')[0], { type: 'warning' })
        return
      }
    }

    const abortController = new AbortController()
    createCustomerSaleOpen && getDataLists()
    return () => {
      abortController.abort()
    }
  }, [createCustomerSaleOpen])

  return (
    <FormWithRedirect
      {...props}
      initialValues={{
        salesData: [{ rowId: 0, quantity: 1 }],
      }}
      render={(formProps) => (
        <Box p="0px 20px">
          <Button onClick={() => setCreateCustomerSaleOpen(!createCustomerSaleOpen)}>
            Assign Allocations
          </Button>

          {createCustomerSaleOpen && (
            <CreateCustomerSaleForm
              formProps={formProps}
              createCustomerSaleOpen={createCustomerSaleOpen}
              setCreateCustomerSaleOpen={setCreateCustomerSaleOpen}
              products={products}
              handleCreateCustomerOrder={handleCreateCustomerOrder}
              handleConfirmOrder={handleConfirmOrder}
              handleApproveOrder={handleApproveOrder}
              orderConfirmed={orderConfirmed}
              setOrderConfirmed={setOrderConfirmed}
            />
          )}
        </Box>
      )}
    />
  )
}

const CreateCustomerSaleForm = (props) => {
  const {
    createCustomerSaleOpen,
    setCreateCustomerSaleOpen,
    formProps,
    products,
    handleCreateCustomerOrder,
    handleConfirmOrder,
    handleApproveOrder,
    orderConfirmed,
    setOrderConfirmed,
  } = props
  const { values } = useFormState()
  const form = useForm()
  const classes = useStyles()
  const [showConfirm, setShowConfirm] = useState(false)
  const [saving, setSaving] = useState(false)

  const getNewTotal = ({ index, newValue }) => {
    // console.log(index, newValue)
    let newTotal = 0
    values.salesData.map((el, i) => {
      //   console.log(newTotal, index, i, el)
      index === i ? (newTotal += newValue) : (newTotal += el.subtotal)
      //   console.log(newTotal, index, i, el)
    })
    return newTotal
  }

  //   console.log(values)
  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={createCustomerSaleOpen}
      onClose={() => {
        form.batch(() => {
          form.change('total', 0)
          form.change('salesData', [{ rowId: 0, quantity: 1 }])
        })
        setShowConfirm(false)
        setOrderConfirmed(false)
        setSaving(false)
        setCreateCustomerSaleOpen(false)
      }}
      aria-label="CreateCustomerSale"
    >
      <DialogTitle>Create Consumer Order</DialogTitle>
      <DialogContent>
        <Box sx={saving ? { opacity: 0.5 } : { opacity: 1 }}>
          {showConfirm ? (
            <Box display="flex" flexDirection="column" sx={{ position: 'relative' }}>
              {saving && (
                <CircularProgress
                  style={{
                    position: 'absolute',
                    top: '6vw',
                    left: '29vw',
                    width: '5vw',
                    height: '5vw',
                  }}
                />
              )}
              <TableContainer component={Paper}>
                <Table aria-label="simple table" style={{ overflow: 'hidden' }}>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell
                        style={{ width: '30%', fontSize: '10px', padding: '10px' }}
                        align="left"
                      >
                        Product
                      </StyledTableCell>
                      <StyledTableCell
                        style={{ width: '16%', fontSize: '10px', padding: '10px' }}
                        align="left"
                      >
                        Variant
                      </StyledTableCell>
                      <StyledTableCell
                        style={{ width: '16%', fontSize: '10px', padding: '10px' }}
                        align="left"
                      >
                        Quantity
                      </StyledTableCell>
                      <StyledTableCell
                        style={{ width: '16%', fontSize: '10px', padding: '10px' }}
                        align="left"
                      >
                        Price
                      </StyledTableCell>
                      <StyledTableCell
                        style={{ width: '16%', fontSize: '10px', padding: '10px' }}
                        align="left"
                      >
                        Subtotal
                      </StyledTableCell>
                      <StyledTableCell
                        style={{ width: '6%', fontSize: '10px', padding: '10px' }}
                        align="left"
                      >
                        Delete
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {values.salesData?.map((sale, index) => (
                      <SaleRow
                        key={sale.rowId}
                        sale={sale}
                        index={index}
                        products={products}
                        form={form}
                        saving={saving}
                        values={values}
                        getNewTotal={getNewTotal}
                        disabled={true}
                      />
                    ))}
                    <TableRow>
                      <BodyTableCell colSpan={4} />
                      <BodyTableCell>
                        <NumberFormat
                          className={classes.numberTableInput}
                          displayType="input"
                          thousandSeparator
                          decimalScale={2}
                          fixedDecimalScale
                          isNumericString
                          disabled
                          source={`total`}
                          prefix="$ "
                        />
                      </BodyTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <Box display="flex" justifyContent="center" alignItems="center" mt="30px">
                <Button
                  style={{ marginRight: '5px' }}
                  className={classes.saveButton}
                  disabled={orderConfirmed}
                  onClick={async () => {
                    //   console.log(paymentIntentId)
                    setSaving(true)
                    await handleConfirmOrder()
                    setOrderConfirmed(true)
                    setSaving(false)
                  }}
                >
                  CONFIRM
                </Button>
                <Button
                  style={{ marginLeft: '5px' }}
                  className={classes.saveButton}
                  disabled={!orderConfirmed}
                  onClick={async () => {
                    //   console.log(paymentIntentId)
                    setSaving(true)
                    await handleApproveOrder()
                    setShowConfirm(false)
                    setSaving(false)
                  }}
                >
                  APPROVE PAYMENT
                </Button>
              </Box>
            </Box>
          ) : (
            <Box sx={{ position: 'relative' }}>
              {saving && (
                <CircularProgress
                  style={{
                    position: 'absolute',
                    top: '6vw',
                    left: '29vw',
                    width: '5vw',
                    height: '5vw',
                  }}
                />
              )}
              <Box>
                <Button
                  disabled={!!saving}
                  className={classes.saveButton}
                  style={{ marginBottom: '10px' }}
                  onClick={() => {
                    const newSalesData = [...values.salesData]
                    newSalesData.push({ rowId: values.salesData.length + 1, quantity: 1 })
                    form.change('salesData', newSalesData)
                  }}
                >
                  Add new
                </Button>
                <span style={{ marginLeft: '10px', color: 'grey', fontSize: '10px' }}>
                  Note that allocations will apply to the oldest available offer for each variant
                  selected
                </span>
              </Box>
              <TableContainer component={Paper}>
                <Table aria-label="simple table" style={{ overflow: 'hidden' }}>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell
                        style={{ width: '30%', fontSize: '10px', padding: '10px' }}
                        align="left"
                      >
                        Product
                      </StyledTableCell>
                      <StyledTableCell
                        style={{ width: '16%', fontSize: '10px', padding: '10px' }}
                        align="left"
                      >
                        Variant
                      </StyledTableCell>
                      <StyledTableCell
                        style={{ width: '16%', fontSize: '10px', padding: '10px' }}
                        align="left"
                      >
                        Quantity
                      </StyledTableCell>
                      <StyledTableCell
                        style={{ width: '16%', fontSize: '10px', padding: '10px' }}
                        align="left"
                      >
                        Price
                      </StyledTableCell>
                      <StyledTableCell
                        style={{ width: '16%', fontSize: '10px', padding: '10px' }}
                        align="left"
                      >
                        Subtotal
                      </StyledTableCell>
                      <StyledTableCell
                        style={{ width: '6%', fontSize: '10px', padding: '10px' }}
                        align="left"
                      >
                        Delete
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {values.salesData?.map((sale, index) => (
                      <SaleRow
                        key={sale.rowId}
                        sale={sale}
                        index={index}
                        products={products}
                        form={form}
                        saving={saving}
                        values={values}
                        getNewTotal={getNewTotal}
                      />
                    ))}
                    <TableRow>
                      <BodyTableCell colSpan={4} />
                      <BodyTableCell>
                        <NumberFormat
                          className={classes.numberTableInput}
                          displayType="input"
                          thousandSeparator
                          decimalScale={2}
                          fixedDecimalScale
                          isNumericString
                          disabled
                          source={`total`}
                          prefix="$ "
                        />
                      </BodyTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

              <Box display="flex" justifyContent="center" alignItems="center" mt="30px">
                <Confirm
                  // redirect="/offer"
                  redirect={null}
                  record={formProps.record}
                  basePath={formProps.basePath}
                  label="CREATE"
                  undoable
                  invalid={formProps.invalid}
                  handleSubmit={async (submission) => {
                    setSaving(true)
                    // console.log(submission)

                    try {
                      await handleCreateCustomerOrder(submission)
                      //   console.log(resp)
                    } catch (error) {
                      setSaving(false)
                      return
                    }
                    setShowConfirm(true)
                    setSaving(false)
                  }}
                  saving={formProps.saving}
                  disabled={!!saving}
                  resource="catalogueProducts"
                />
              </Box>
            </Box>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  )
}

const SaleRow = (props) => {
  const classes = useStyles()
  const [variants, setVariants] = useState([])
  //   console.log(variants)
  const { index, products, form, saving, values, getNewTotal, disabled } = props
  return (
    <TableRow>
      <BodyTableCell>
        <SelectInput
          className={classes.selectTableInput}
          label={false}
          source={`salesData[${index}].product`}
          choices={products}
          fullWidth
          optionText={(record) =>
            `${record.id}. ${record.name} - ${moment(record.created_at).format('L')}`
          }
          onChange={(e) => {
            const product = products.find((el) => el.id === e.target.value)
            const newSubtotal = (product.retail_price / 100) * values.salesData[index].quantity
            const newTotal = getNewTotal({ index, newValue: newSubtotal })
            // console.log(e.target.value, product, newSubtotal, newTotal)
            setVariants(product.batches)
            form.batch(() => {
              form.change(`salesData[${index}].saleableProductId`, product.saleable_product?.id)
              form.change(`salesData[${index}].variant`, product.batches[0].id)
              form.change(`salesData[${index}].variantObject`, product.batches[0])
              form.change(`salesData[${index}].price`, product.retail_price / 100)
              form.change(`salesData[${index}].subtotal`, newSubtotal)
              form.change(`total`, newTotal)
            })
          }}
          optionValue="id"
          defaultValue=""
          variant="standard"
          disabled={!!saving || disabled}
        />
      </BodyTableCell>
      <BodyTableCell>
        <SelectInput
          className={classes.selectTableInput}
          label={false}
          source={`salesData[${index}].variant`}
          choices={variants}
          fullWidth
          optionText={(el) =>
            el.saleable_product_variant.variant.values.map((i) => i.value).join(',')
          }
          // arr.map(i => { return i.Name; }).join(', ')
          onChange={(e) => {
            const variantObject = variants.find((el) => el.id === e.target.value)
            form.change(`salesData[${index}].variantObject`, variantObject)
          }}
          optionValue="id"
          defaultValue=""
          variant="standard"
          disabled={!!saving || disabled}
        />
      </BodyTableCell>
      <BodyTableCell>
        <NumberFormat
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={0}
          fixedDecimalScale
          isNumericString
          disabled={!!saving || disabled}
          source={`salesData[${index}].quantity`}
          onChange={(e) => {
            const newSubtotal = values.salesData[index].price * e.target.value
            const newTotal = getNewTotal({ index, newValue: newSubtotal })
            // console.log(e.target.value, newSubtotal, newTotal)
            form.batch(() => {
              form.change(`salesData[${index}].subtotal`, newSubtotal)
              form.change(`total`, newTotal)
            })
          }}
        />
      </BodyTableCell>
      <BodyTableCell>
        <NumberFormat
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={2}
          fixedDecimalScale
          isNumericString
          disabled
          source={`salesData[${index}].price`}
          prefix="$ "
        />
      </BodyTableCell>
      <BodyTableCell>
        <NumberFormat
          className={classes.numberTableInput}
          displayType="input"
          thousandSeparator
          decimalScale={2}
          fixedDecimalScale
          isNumericString
          disabled
          source={`salesData[${index}].subtotal`}
          prefix="$ "
        />
      </BodyTableCell>
      <BodyTableCell>
        <IconButton
          disabled={!!saving || disabled}
          onClick={() => {
            const newSalesData = [...values.salesData]
            newSalesData.splice(index, 1)
            form.change('salesData', newSalesData)
          }}
          style={{ maxWidth: '50px', maxHeight: '50px' }}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
      </BodyTableCell>
    </TableRow>
  )
}

export default CreateCustomerSaleDialog
