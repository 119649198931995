import React, { useEffect, useState } from 'react'
import { useDataProvider, useNotify } from 'react-admin'
import {
  Box,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import BorderColorIcon from '@material-ui/icons/BorderColorOutlined'
import CancelOutlined from '@material-ui/icons/CancelOutlined'
import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined'
import lodashGet from 'lodash'
import { useForm, useFormState } from 'react-final-form'
import { makeStyles } from '@material-ui/core/styles'
import NumberFormat from '../../components/CustomNumberFormat'
import SelectFieldInput from '../../components/fieldInputs/SelectFieldInput2'
import TextFieldInput from '../../components/fieldInputs/TextFieldInput'
import NumberFieldInput from '../../components/fieldInputs/NumberFieldInput'

const useStyles = makeStyles(() => ({
  spacer: {
    height: '10px',
  },
  tableTitle: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '14px',
    color: '#333333',
    marginBottom: '10px',
    marginTop: '10px',
  },
  filesLabel: {
    fontSize: '12px',
    fontWeight: '600',
    marginBottom: '8px',
  },
  labelText: {
    fontSize: '12px',
  },
  numberInput: {
    width: '120px',
    height: '36px',
    textAlign: 'center',
  },
  numberTableInput: {
    width: '100%',
    height: '100%',
    marginTop: '0px',
    marginBottom: '0px',
    // height: '30px',
    minHeight: '30px',
    padding: '0px',
    '& .MuiFilledInput-underline::before': {
      borderBottom: 'none',
    },
  },
  table: {
    overflowX: 'unset',
  },
}))

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#FFFFFF',
    color: '#000000',
    padding: '6px',
    fontSize: '12px',
    border: '1px solid #DEDEDE',
  },
  body: {
    border: '1px solid #DEDEDE',
    padding: '0px',
    fontSize: 12,
  },
}))(TableCell)

const PackagingDetails = (props) => {
  const classes = useStyles()
  const form = useForm()
  const { values } = useFormState()

  const {
    packagingTypes = [],
    // checkFormValid
  } = props

  const formChange = (field, value) => {
    const oldValue = lodashGet(values, field)
    // console.log(field, 'oldValue', oldValue, 'newValue', value)
    if (oldValue != value) {
      form.change(field, value)
    }
  }

  useEffect(() => {
    // console.log(
    //   values.dropshipRate,
    //   values.dutyRate,
    //   values.unitCost,
    //   values.singleCartonLength,
    //   values.singleCartonWidth,
    //   values.singleCartonHeight,
    //   values.singleCartonWeight,
    // )
    // console.log(
    //   values.dropshipRate >= 0,
    //   values.dutyRate >= 0,
    //   values.unitCost >= 0,
    //   values.singleCartonLength >= 0,
    //   values.singleCartonWidth >= 0,
    //   values.singleCartonHeight >= 0,
    //   values.singleCartonWeight >= 0,
    // )

    const calcFreightCost = () => {
      const {
        dutyRate = 0,
        singleCartonLength = 0,
        singleCartonWeight = 0,
        singleCartonHeight = 0,
        singleCartonWidth = 0,
        dropshipRate = 0,
        airRate = 0,
        unitCost = 0,
      } = values

      // console.log(
      //   'FOB_PRICE',
      //   values.fobPrice,
      //   'DTY_RT',
      //   dutyRate,
      //   'S_CRTN_LNGTH',
      //   singleCartonLength,
      //   'S_CRTN_HGHT',
      //   singleCartonHeight,
      //   'S_CRTN_WDTH',
      //   singleCartonWidth,
      //   'S_CRTN_WGHT',
      //   singleCartonWeight,
      //   'AIR_RT',
      //   dropshipRate,
      //   'UNITCOST',
      //   unitCost,
      // )

      const airWeight =
        parseInt(
          (((singleCartonLength * singleCartonWidth * singleCartonHeight) / 5000) * 100).toFixed(2),
        ) || null

      // console.log('AirWeight', airWeight)

      const chargeableAirWeight =
        airWeight / 100 > singleCartonWeight ? airWeight / 100 : singleCartonWeight
      // console.log('ChargeableAir', chargeableAirWeight)

      const estLandedCostDropship = chargeableAirWeight * dropshipRate
      const estLandedCostAir = chargeableAirWeight * airRate

      const dutiesPerPiece = dutyRate === 0 ? 0 : Number(((dutyRate / 100) * unitCost).toFixed(2))

      const estLandedRateDropship = estLandedCostDropship + dutiesPerPiece
      const estLandedRateAir = estLandedCostAir + dutiesPerPiece

      // console.log(
      //   'DRPRTE',
      //   dropshipRate,
      //   'AIRRTE',
      //   airRate,
      //   'CGW:',
      //   chargeableAirWeight,
      //   'DTYS',
      //   dutiesPerPiece,
      //   'ESTCOST',
      //   estLandedCostDropship,
      //   'WITHDUTIES',
      //   estLandedRateDropship,
      // )

      const margin = 1 - values.fobMargin / 100
      const fobPrice = Number((values.unitCost / margin).toFixed(2))

      const totalFees = estLandedRateDropship + fobPrice

      const priceMargin = totalFees * 0.5

      const newPrice = totalFees + priceMargin

      const estProfit = values.retailPrice - newPrice

      // console.log('newPrice', newPrice, 'fobPrice', fobPrice)

      form.batch(() => {
        formChange('chargeableAirWeight', chargeableAirWeight)
        formChange('dropshippingEstimate', estLandedRateDropship)
        formChange('courierEstimate', estLandedRateAir)
        formChange('totalFees', totalFees)
        formChange('dutiesTotal', dutiesPerPiece)
        formChange('suggestedRetailPrice', newPrice)
        formChange('fobPrice', fobPrice)
        formChange('estProfit', estProfit)
      })
    }
    if (
      (values.dropshipRate >= 0,
      values.airRate >= 0,
      values.dutyRate >= 0,
      values.unitCost >= 0,
      values.singleCartonLength >= 0,
      values.singleCartonWidth >= 0,
      values.singleCartonHeight >= 0,
      values.singleCartonWeight >= 0)
    ) {
      calcFreightCost()
    }
  }, [
    values.unitCost,
    values.dutyRate,
    values.dropshipRate,
    values.airRate,
    values.singleCartonLength,
    values.singleCartonWidth,
    values.singleCartonHeight,
    values.singleCartonWeight,
  ])

  // console.log(values)

  return (
    <Box display="flex" flexDirection="column">
      <div className={classes.spacer} />
      <span className={classes.tableTitle}>Product Dimensions</span>
      <Box display="flex">
        <Box flex={1}>
          <DimensionsTable
            source="product"
            id={values.product_dimensions?.id}
            referenceField="product_dimensions_id"
            formReferenceField="product_dimensions"
          />
        </Box>
        <Box flex={1} />
      </Box>

      <div className={classes.spacer} />
      <span className={classes.tableTitle}>Packaging Details</span>
      <div className={classes.spacer} />
      <Box display="flex">
        {packagingTypes.length > 0 ? (
          <SelectFieldInput
            variant="standard"
            field="packaging_type"
            resource="saleable_product"
            id={values.id}
            label="Type"
            choices={packagingTypes}
            value={values.packaging_type}
            optionText="name"
            optionValue="name"
            style={{ width: '150px', minWidth: '150px' }}
          />
        ) : (
          <Skeleton style={{ width: '240px', height: '62px' }} animation="wave" />
        )}

        <TextFieldInput
          variant="standard"
          fullWidth
          field="packaging_label"
          className={classes.textInputFull}
          resource="saleable_product"
          id={values.id}
          style={{
            alignItems: 'start',
          }}
          label="Label"
          // validate={required()}
          value={values.packaging_label}
        />
      </Box>
      <div className={classes.spacer} />
      <Box display="flex">
        <Box flex={1}>
          <DimensionsTable
            source="packaging"
            id={values.packaging_dimensions?.id}
            referenceField="packaging_dimensions_id"
            formReferenceField="packaging_dimensions"
          />
        </Box>
        <Box flex={1} />
      </Box>
      <div className={classes.spacer} />
      <span className={classes.tableTitle}>Dropshipping Details</span>
      <div className={classes.spacer} />
      <Box display="flex">
        {packagingTypes.length > 0 ? (
          <SelectFieldInput
            variant="standard"
            field="single_carton_type"
            resource="saleable_product"
            id={values.id}
            label="Type"
            choices={packagingTypes}
            value={values.single_carton_type}
            optionText="name"
            optionValue="name"
            style={{ width: '150px', minWidth: '150px' }}
          />
        ) : (
          <Skeleton style={{ width: '240px', height: '62px' }} animation="wave" />
        )}

        <TextFieldInput
          variant="standard"
          fullWidth
          field="single_carton_label"
          className={classes.textInputFull}
          resource="saleable_product"
          id={values.id}
          style={{
            alignItems: 'start',
          }}
          label="Label"
          // validate={required()}
          value={values.single_carton_label}
        />
        <NumberFieldInput
          className={classes.fieldInputNarrow}
          label="Duty rate:"
          value={values.dutyRate}
          resource="saleable_product"
          field="duty_rate"
          formField="dutyRate"
          defaultValue={0}
          decimalScale={2}
          fixedDecimalScale
          parseMultiplier={100}
          suffix="%"
          id={values.id}
          onChange={(e) => {
            const newRate = Number(e.target.value.slice(0, -1))
            formChange('dutyRate', newRate)
          }}
        />
      </Box>
      <div className={classes.spacer} />
      <Box display="flex">
        <Box flex={1}>
          <DimensionsTable
            source="singleCarton"
            id={values.single_carton_dimensions?.id}
            referenceField="single_carton_dimensions_id"
            formReferenceField="single_carton_dimensions"
            // checkFormValid={checkFormValid}
          />
        </Box>
        <Box flex={1} />
      </Box>
    </Box>
  )
}

const DimensionsTable = (props) => {
  const classes = useStyles()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const {
    source,
    id,
    referenceField,
    //  checkFormValid
  } = props
  const { values } = useFormState()

  const [editing, setEditing] = useState(false)
  const [saving, setSaving] = useState(false)

  return (
    <Box position="relative">
      <TableContainer component={Box} className={classes.table}>
        <Table className={classes.table} aria-label="shipping table">
          <TableHead>
            <TableRow>
              <StyledTableCell width="25%" align="center">
                Length
              </StyledTableCell>
              <StyledTableCell width="25%" align="center">
                Width
              </StyledTableCell>
              <StyledTableCell width="25%" align="center">
                Height
              </StyledTableCell>
              <StyledTableCell width="25%" align="center">
                Weight
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <StyledTableCell align="right">
                <NumberFormat
                  className={classes.numberTableInput}
                  displayType="input"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  isNumericString
                  suffix="cm"
                  source={`${source}Length`}
                  disabled={!editing}
                />
              </StyledTableCell>
              <StyledTableCell align="right">
                <NumberFormat
                  className={classes.numberTableInput}
                  displayType="input"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  isNumericString
                  suffix="cm"
                  source={`${source}Width`}
                  disabled={!editing}
                />
              </StyledTableCell>
              <StyledTableCell align="right">
                <NumberFormat
                  className={classes.numberTableInput}
                  displayType="input"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  isNumericString
                  suffix="cm"
                  source={`${source}Height`}
                  disabled={!editing}
                />
              </StyledTableCell>
              <StyledTableCell align="right">
                <NumberFormat
                  className={classes.numberTableInput}
                  displayType="input"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  isNumericString
                  suffix="kg"
                  source={`${source}Weight`}
                  disabled={!editing}
                />
              </StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      {editing ? (
        <Box display="flex">
          <IconButton
            onClick={async () => {
              setSaving(true)

              const params = {
                ...(id && { id }),
                length: parseInt(values[`${source}Length`] * 100),
                width: parseInt(values[`${source}Width`] * 100),
                height: parseInt(values[`${source}Height`] * 100),
                gross_weight: parseInt(values[`${source}Weight`] * 100),
              }

              // console.log(params)

              try {
                if (id) {
                  await dataProvider.update('dimension', {
                    data: params,
                  })
                  setSaving(false)
                  setEditing(false)
                  // checkFormValid(values)
                } else {
                  const resp = await dataProvider.create('dimension', {
                    data: params,
                  })

                  const updateParams = { id: values.id }
                  updateParams[referenceField] = resp.data.id

                  await dataProvider.update('saleable_product', { data: updateParams })
                  setSaving(false)
                  setEditing(false)
                  // checkFormValid(values)
                }

                notify('Save Complete', { type: 'info' })
              } catch (error) {
                console.log(error)
                setSaving(false)
                setEditing(false)
                notify(error.message, { type: 'warning' })
              }
            }}
            style={{
              maxWidth: '30px',
              maxHeight: '30px',
              position: 'absolute',
              top: '-15px',
              right: '20px',
              backgroundColor: '#FFF',
              border: 'solid 1px lightgray',
            }}
          >
            <CheckCircleOutlineOutlined fontSize="small" style={{ color: 'FF0000' }} />
          </IconButton>
          <IconButton
            onClick={() => {
              setEditing(false)
            }}
            style={{
              maxWidth: '30px',
              maxHeight: '30px',
              position: 'absolute',
              top: '-15px',
              right: '-15px',
              backgroundColor: '#FFF',
              border: 'solid 1px lightgray',
            }}
          >
            <CancelOutlined fontSize="small" />
          </IconButton>
          {saving && (
            <CircularProgress
              style={{
                width: '20px',
                height: '20px',
                position: 'absolute',
                top: '-15px',
                left: '5px',
              }}
            />
          )}
        </Box>
      ) : (
        <IconButton
          onClick={() => {
            setEditing(true)
          }}
          style={{
            maxWidth: '30px',
            maxHeight: '30px',
            position: 'absolute',
            top: '-15px',
            right: '-15px',
            backgroundColor: '#FFF',
            border: 'solid 1px lightgray',
          }}
        >
          <BorderColorIcon fontSize="small" />
        </IconButton>
      )}
    </Box>
  )
}

export default PackagingDetails
