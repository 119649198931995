import React, {
  useState,
  useCallback,
  useEffect,
  //  Fragment
} from 'react'
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Paper,
  withStyles,
  IconButton,
} from '@material-ui/core'
import {
  useNotify,
  useDataProvider,
  Create,
  FormWithRedirect,
  useCreate,
  useRefresh,
  SaveButton,
} from 'react-admin'
import { debounce } from 'lodash'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useForm, useFormState } from 'react-final-form'
import AddIcon from '@material-ui/icons/Add'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import TextInput from '../../../components/OutlinedTextInput'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import { makeStyles } from '@material-ui/core/styles'
import ClientQuoteCreateDialog from './ClientQuoteCreateDialog'
import NumberFormat from '../../components/CustomNumberFormat'
import ExcelImport from '../../../utils/ExcelImport'
import { useDropzone } from 'react-dropzone'
import uploadFile from '../../../dataProvider/aws_upload'

const useStyles = makeStyles(() => ({
  sectionHeader: {
    fontSize: '16px',
    // color: 'rgb(18, 183, 106)',
    marginTop: '20px',
  },
  boxContainer: {
    marginTop: '20px',
    padding: '20px 10px',
  },
  numberTableInput: {
    width: '100%',
    height: '100%',
    marginTop: '0px',
    // minWidth: '60px',
    marginBottom: '0px',
    // height: '30px',
    minHeight: '30px',
    padding: '0px',
    '& .MuiFilledInput-underline::before': {
      borderBottom: 'none',
    },
  },
  infoBtn: {
    position: 'absolute',
    top: '0px',
    right: '0px',
    color: 'darkgrey',
  },
  imageDropZone: {
    width: '85px',
    height: '85px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: '0.9',
    border: 'solid gray 2px',
    textAlign: 'center',
    fontSize: '12px',
  },
  imageDropZoneEditing: {
    width: '85px',
    height: '85px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: '0.9',
    cursor: 'pointer',
    border: 'solid gray 2px',
    textAlign: 'center',
    fontSize: '12px',
  },
  loadingIcon: {
    width: '20px !important',
    height: '20px !important',
    position: 'absolute',
    zIndex: '150',
    left: '35px',
    top: '35px',
  },
  previewImageBox: {
    maxWidth: '85px',
    maxHeight: '85px',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    alignItems: 'center',
    padding: '2px',
    backgroundColor: 'white',
    // border: 'solid gray 2px',
  },
  previewImage: {
    zIndex: '100',
    maxWidth: '100%',
    maxHeight: '100%',
    // cursor: 'pointer',
  },
  previewNewImage: {
    zIndex: '0',
    margin: '2px',
    maxWidth: '80px',
    maxHeight: '80px',
    opacity: '0.4',
    transition: ['background', 'color', 'font-size'],
    transitionDuration: 2,
    transitionDelay: '1s',
  },
  deleteBtn: {
    position: 'absolute',
    top: '1px',
    right: '1px',
    zIndex: '120',
    padding: '0px',
    margin: '0px',
  },
}))

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#fff',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 500,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip)

const StyledTableCell = withStyles(() => ({
  head: {
    fontSize: 14,
    // backgroundColor: '#1E1E1E',
    // color: '#FFFFFF',
    borderRight: '1px solid #ddd',
    '& last-child': {
      borderRight: 'none',
    },
  },
  body: {
    fontSize: 14,
    borderRight: '1px solid #ddd',
    padding: '5px',
  },
}))(TableCell)
const BodyTableCell = withStyles(() => ({
  head: {
    color: '#FFFFFF',
    borderRight: '1px solid #ddd',
    '& last-child': {
      borderRight: 'none',
    },
  },
  body: {
    color: '#323232',
    fontSize: 12,
    padding: '5px',
    height: '36px',
    borderRight: '1px solid #ddd',
  },
}))(TableCell)

const ProductsTable = (props) => {
  const classes = useStyles()
  const notify = useNotify()
  const refresh = useRefresh()
  const dataProvider = useDataProvider()
  const { values } = useFormState()
  const { milestones, config } = props
  const [createOpen, setCreateOpen] = useState(false)

  const [options, setOptions] = React.useState([])
  const [loading, setLoading] = React.useState(false)

  // function sleep(delay = 0) {
  //   return new Promise((resolve) => {
  //     setTimeout(resolve, delay)
  //   })
  // }

  const getOptionsAsync = async (query) => {
    setLoading(true)
    const resp = await dataProvider.getList('supplier', {
      pagination: { page: 1, perPage: 50 },
      sort: { field: 'name', order: 'ASC' },
      filter: { name: query },
    })
    setLoading(false)
    return resp.data
  }

  const getOptionsDelayed = useCallback(
    debounce((text, callback) => {
      setOptions([])
      getOptionsAsync(text).then(callback)
    }, 500),
    [],
  )

  const [create, { loading: updating }] = useCreate('sourcing_request_products')

  const handleSubmit = (submission) => {
    console.log(submission)

    const params = submission.newProducts
      .filter(
        (el) =>
          el.product?.name &&
          el.product?.notes &&
          el.product?.product_quotes?.length > 0 &&
          el.product?.product_quotes[0].prices?.length > 0 &&
          el.product?.product_quotes[0].prices[0].unitPrice &&
          el.product?.product_quotes[0].prices[0].quantity &&
          el.product?.product_quotes[0].prices[0].lead_time,
      )
      .map((el) => ({
        sourcing_request_id: values.id,
        description: el.product.notes,
        product: {
          data: {
            ...(el.product.id && { id: el.product?.id }),
            name: el.product.name,
            notes: el.product.notes,
            thumbnail_photo_id: el.product.thumbnail_photo?.id,
            product_quotes: {
              data: [
                {
                  ...(el.product.product_quotes[0].id && { id: el.product.product_quotes[0].id }),
                  pieces_per_carton: el.product.product_quotes[0].pieces_per_carton,
                  packaging_type: el.product.product_quotes[0].packaging_type,
                  sample_cost: parseInt(el.product.product_quotes[0].sampleCost * 100),
                  sample_lead_time: el.product.product_quotes[0].sample_lead_time,
                  supplier_id: el.product.product_quotes[0].supplier_id,
                  tooling_cost: parseInt(el.product.product_quotes[0].toolingCost * 100),
                  included_packaging_option: {
                    data: {
                      product_quote_id: el.product?.product_quotes[0].id,
                      ...(el.product.product_quotes[0].cartonDimensions?.packagingOptionId && {
                        id: el.product.product_quotes[0].cartonDimensions?.packagingOptionId,
                      }),
                      type: el.product.product_quotes[0].included_packaging_option?.type,
                      pieces_per_carton:
                        el.product.product_quotes[0].included_packaging_option?.pieces_per_carton,
                      carton_dimensions: {
                        data: {
                          ...(el.product.product_quotes[0].cartonDimensions?.id && {
                            id: el.product.product_quotes[0].cartonDimensions?.id,
                          }),
                          length: parseInt(
                            el.product.product_quotes[0].cartonDimensions?.length * 100,
                          ),
                          width: parseInt(
                            el.product.product_quotes[0].cartonDimensions?.width * 100,
                          ),
                          height: parseInt(
                            el.product.product_quotes[0].cartonDimensions?.height * 100,
                          ),
                          gross_weight: parseInt(
                            el.product.product_quotes[0].cartonDimensions?.weight * 100,
                          ),
                        },
                        on_conflict: {
                          constraint: 'dimension_pkey',
                          update_columns: ['length', 'width', 'height', 'gross_weight'],
                        },
                      },
                    },
                    on_conflict: {
                      constraint: 'product_quote_packaging_options_pkey',
                      update_columns: [
                        'product_quote_id',
                        'type',
                        'pieces_per_carton',
                        'carton_dimensions_id',
                      ],
                    },
                  },
                  carton_dimensions: {
                    data: {
                      ...(el.product.product_quotes[0].cartonDimensions?.id && {
                        id: el.product.product_quotes[0].cartonDimensions?.id,
                      }),
                      length: parseInt(el.product.product_quotes[0].cartonDimensions?.length * 100),
                      width: parseInt(el.product.product_quotes[0].cartonDimensions?.width * 100),
                      height: parseInt(el.product.product_quotes[0].cartonDimensions?.height * 100),
                      gross_weight: parseInt(
                        el.product.product_quotes[0].cartonDimensions?.weight * 100,
                      ),
                    },
                    on_conflict: {
                      constraint: 'dimension_pkey',
                      update_columns: ['length', 'width', 'height', 'gross_weight'],
                    },
                  },
                  // valid_date: '',
                  prices: {
                    data: el.product.product_quotes[0].prices?.map((price) => ({
                      ...(price.id && { id: price.id }),
                      unit_price: parseInt(price.unitPrice * 100),
                      quantity: price.quantity,
                      lead_time: price.lead_time,
                    })),
                    on_conflict: {
                      constraint: 'product_quote_price_pkey',
                      update_columns: ['unit_price', 'quantity', 'lead_time'],
                    },
                  },
                },
              ],
              on_conflict: {
                constraint: 'product_quote_pkey1',
                update_columns: [
                  'pieces_per_carton',
                  'packaging_type',
                  'sample_cost',
                  'sample_lead_time',
                  'supplier_id',
                  'tooling_cost',
                  'included_packaging_option_id',
                ],
              },
            },
          },
          on_conflict: {
            constraint: 'product_pkey',
            update_columns: ['name', 'notes', 'thumbnail_photo_id'],
          },
        },
      }))

    if (params.length === 0) {
      notify('No valid rows to insert', 'warning')
    } else {
      // console.log('creating')
      create(
        {
          payload: {
            data: params,
            on_conflict: {
              constraint: 'sourcing_request_products_sourcing_request_id_product_id_key',
              update_columns: ['id', 'description', 'sourcing_request_id', 'product_id'],
            },
          },
        },
        {
          onSuccess: () => {
            setCreateOpen(false)
            refresh()
            // console.log(data)
          },
          onFailure: ({ error }) => {
            notify(error, 'error')
          },
        },
      )
    }
  }

  // console.log(values)

  return (
    <Card className={classes.boxContainer}>
      <span className={classes.sectionHeader}>Quote List</span>
      <Box mt="10px" display="flex" justifyContent="space-between" alignItems="center">
        <Button
          onClick={() => {
            setCreateOpen(true)
          }}
          style={{ border: 'solid 1px lightgrey', height: '32.95px' }}
        >
          Edit
        </Button>
        {values.items?.length > 0 && (
          <Box display="flex" alignItems="center">
            <Button
              disabled
              style={{ border: 'solid 1px lightgrey', marginRight: '20px', height: '32.95px' }}
            >
              Add from Database
            </Button>

            <ExcelImport sourcingRequestId={values.id} />
          </Box>
        )}
      </Box>

      <Box mt="20px">
        <TableContainer component={Paper}>
          <Table aria-label="simple table" style={{ overflow: 'hidden' }}>
            <TableHead>
              <TableRow>
                <StyledTableCell
                  style={{ width: '5%', fontSize: '12px', padding: '5px' }}
                  align="left"
                  rowSpan={2}
                >
                  Name
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '2%', fontSize: '12px', padding: '0px' }}
                  align="left"
                  rowSpan={2}
                >
                  Image
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '10%', fontSize: '12px', padding: '5px' }}
                  align="left"
                  rowSpan={2}
                >
                  Description
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '4%', fontSize: '12px', padding: '5px' }}
                  align="left"
                  rowSpan={2}
                >
                  Supplier
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '8%', fontSize: '12px', padding: '5px' }}
                  align="center"
                  colSpan={3}
                >
                  Quotes
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '3%', fontSize: '12px', padding: '5px' }}
                  align="center"
                  rowSpan={2}
                >
                  Quote to Client
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell
                  style={{ width: '3%', fontSize: '12px', padding: '5px' }}
                  align="left"
                >
                  Qty
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '2%', fontSize: '12px', padding: '5px' }}
                  align="left"
                >
                  Unit Price
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '2%', fontSize: '12px', padding: '5px' }}
                  align="left"
                >
                  Lead Time
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {values.products?.map((product, index) => (
                <ProductRow
                  key={`price${product.id}`}
                  field="products"
                  index={index}
                  product={product}
                  milestones={milestones}
                  config={config}
                  getOptionsDelayed={getOptionsDelayed}
                  setOptions={setOptions}
                  options={options}
                  loading={loading}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {createOpen && (
          <Create basePath="sourcingRequests" resource="sourcing_request_products">
            <FormWithRedirect
              redirect={null}
              save={handleSubmit}
              initialValues={{
                newProducts: [
                  ...values.products,
                  { product: { product_quotes: [{ prices: [{}] }] } },
                ],
              }}
              render={(formProps) => (
                <ProductEditDialog
                  formProps={formProps}
                  setCreateOpen={setCreateOpen}
                  createOpen={createOpen}
                  updating={updating}
                  getOptionsDelayed={getOptionsDelayed}
                  setOptions={setOptions}
                  options={options}
                  loading={loading}
                />
              )}
            />
          </Create>
        )}
      </Box>
    </Card>
  )
}

const ProductEditDialog = ({
  formProps,
  createOpen,
  setCreateOpen,
  getOptionsDelayed,
  setOptions,
  options,
  loading,
}) => {
  const form = useForm()
  const { values } = useFormState()
  // console.log(values)

  useEffect(() => {
    const lastProduct = values.newProducts.at(-1)
    // console.log(lastProduct)
    // console.log(Object.keys(values.newProducts[values.newProducts.length - 1]).length)
    if (lastProduct?.product?.name || lastProduct?.product?.notes) {
      const addProducts = [...values.newProducts]
      addProducts.push({ product: { product_quotes: [{ prices: [{}] }] } })
      form.change('newProducts', addProducts)
    }
  }, [values.newProducts])
  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={createOpen}
      onClose={(e) => {
        e.preventDefault()
        e.stopPropagation()
        setCreateOpen(false)
      }}
      aria-label="CreateProd"
    >
      <DialogTitle>Create Product</DialogTitle>
      <DialogContent>
        <TableContainer>
          <Table aria-label="simple table" style={{ overflow: 'hidden' }}>
            <TableHead>
              <TableRow>
                <StyledTableCell
                  style={{ width: '8%', fontSize: '12px', padding: '10px' }}
                  align="left"
                  rowSpan={2}
                >
                  Name
                </StyledTableCell>
                <StyledTableCell
                  style={{ fontSize: '12px', padding: '0px' }}
                  align="left"
                  rowSpan={2}
                >
                  Image
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '10%', fontSize: '12px', padding: '10px' }}
                  align="left"
                  rowSpan={2}
                >
                  Description
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '10%', fontSize: '12px', padding: '10px' }}
                  align="left"
                  rowSpan={2}
                >
                  Supplier
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '8%', fontSize: '12px', padding: '10px' }}
                  align="left"
                  rowSpan={2}
                >
                  Packaging
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '7%', fontSize: '12px', padding: '10px' }}
                  align="left"
                  rowSpan={2}
                >
                  Setup Cost
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '7%', fontSize: '12px', padding: '10px' }}
                  align="left"
                  rowSpan={2}
                >
                  Sample Cost
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '3%', fontSize: '12px', padding: '10px' }}
                  align="left"
                  rowSpan={2}
                >
                  Sample Lead Time
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '20%', fontSize: '12px', padding: '10px' }}
                  align="left"
                  colSpan={5}
                >
                  Carton
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '16%', fontSize: '12px', padding: '10px' }}
                  align="left"
                  colSpan={4}
                >
                  Quotes
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '5%', fontSize: '12px', padding: '10px' }}
                  align="left"
                  rowSpan={2}
                >
                  Actions
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell
                  style={{ width: '4%', fontSize: '12px', padding: '5px' }}
                  align="left"
                >
                  Qty
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '4%', fontSize: '12px', padding: '5px' }}
                  align="left"
                >
                  G.W.
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '4%', fontSize: '12px', padding: '5px' }}
                  align="left"
                >
                  L
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '4%', fontSize: '12px', padding: '5px' }}
                  align="left"
                >
                  W
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '4%', fontSize: '12px', padding: '5px' }}
                  align="left"
                >
                  H
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '4%', fontSize: '12px', padding: '5px' }}
                  align="left"
                >
                  Qty
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '4%', fontSize: '12px', padding: '5px' }}
                  align="left"
                >
                  Unit Price
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '4%', fontSize: '12px', padding: '5px' }}
                  align="left"
                >
                  Lead Time
                </StyledTableCell>
                <StyledTableCell
                  style={{ width: '2%', fontSize: '12px', padding: '5px' }}
                  align="left"
                >
                  Actions
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {values.newProducts?.map((product, index) => (
                <ProductRow
                  editing={true}
                  field="newProducts"
                  key={`newProduct${index}`}
                  index={index}
                  product={product}
                  id={product.id}
                  getOptionsDelayed={getOptionsDelayed}
                  setOptions={setOptions}
                  options={options}
                  loading={loading}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <SaveButton
          style={{ margin: '20px 0px' }}
          saving={formProps.saving}
          handleSubmitWithRedirect={async () => {
            await formProps.handleSubmitWithRedirect()
          }}
        />
        <Button
          style={{ margin: '20px', border: 'solid 1px gray' }}
          onClick={() => {
            setCreateOpen(false)
          }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const ProductRow = ({
  index,
  product,
  milestones,
  config,
  field,
  disabled,
  editing = false,
  getOptionsDelayed,
  setOptions,
  options,
  loading,
}) => {
  const classes = useStyles()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const form = useForm()
  // const refresh = useRefresh()
  const { values } = useFormState()
  const [createOpen, setCreateOpen] = useState(false)
  const [uploading, setUploading] = useState(false)
  // console.log(product)

  const preview = values[field][index].product?.thumbnail_photo?.url || null

  // console.log(preview)

  const { getRootProps, getInputProps } = useDropzone({
    accept: ['image/*'],
    multiple: false,
    disabled,
    onDrop: async (acceptedFiles) => {
      // console.log(acceptedFiles)
      if (acceptedFiles && acceptedFiles.length > 0) {
        setUploading(true)
        const acceptedFile = acceptedFiles[0]
        // console.log(acceptedFile)
        const url = await uploadFile(acceptedFile)
        try {
          const fileResp = await dataProvider.create('file', {
            data: { filename: acceptedFiles[0].name, url },
          })

          await form.batch(() => {
            form.change(`${field}[${index}].product.thumbnail_photo`, {
              id: fileResp.data.id,
              filename: acceptedFile.name,
              url,
            })
            form.change(`${field}[${index}].product.thumbnail_photo_id`, fileResp.data.id)
          })

          notify('Upload Complete', { type: 'info' })

          setUploading(false)
        } catch (error) {
          console.log(error)
          notify(error.message, { type: 'warning' })
          setUploading(false)
        }
      }
    },
  })

  // console.log(product?.product?.name)
  // console.log(product?.product?.product_quotes[0].prices)

  const rowPrices =
    product?.product?.product_quotes?.length > 0
      ? [...product.product.product_quotes[0].prices]
      : []

  const pricesLength = rowPrices.length
  // const priceIndex = 1

  rowPrices.shift()

  // console.log(rowPrices)

  const [open, setOpen] = React.useState(false)
  const [inputValue, setInputValue] = React.useState('')

  useEffect(() => {
    open &&
      getOptionsDelayed(inputValue, (filteredOptions) => {
        setOptions(filteredOptions)
      })
  }, [inputValue, getOptionsDelayed, open])

  return (
    <>
      <TableRow
        key={`prodRow${index}`}
        style={index % 2 === 0 ? { backgroundColor: '#FFF' } : { backgroundColor: '#F3F3F3' }}
      >
        <BodyTableCell
          rowSpan={pricesLength}
          align="right"
          style={{ padding: '0px', position: 'relative' }}
        >
          <TextInput
            variant="standard"
            fullWidth
            style={{ margin: '0px', height: '100%' }}
            source={`${field}[${index}].product.name`}
            label={false}
            disabled={disabled}
          />
          {!editing && (
            <HtmlTooltip
              placement="bottom-start"
              title={
                <TableContainer component="div">
                  <Table aria-label="simple table" style={{ overflow: 'hidden' }}>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell
                          style={{ width: '14%', fontSize: '12px', padding: '5px' }}
                          align="left"
                        >
                          Pkg Type
                        </StyledTableCell>
                        <StyledTableCell
                          style={{ width: '13%', fontSize: '12px', padding: '5px' }}
                          align="left"
                        >
                          Setup Cost
                        </StyledTableCell>
                        <StyledTableCell
                          style={{ width: '13%', fontSize: '12px', padding: '5px' }}
                          align="left"
                        >
                          Sample Cost
                        </StyledTableCell>
                        <StyledTableCell
                          style={{ width: '10%', fontSize: '12px', padding: '5px' }}
                          align="left"
                        >
                          Sample Lead Time
                        </StyledTableCell>
                        <StyledTableCell
                          style={{ width: '10%', fontSize: '12px', padding: '5px' }}
                          align="center"
                        >
                          Ctn Qty
                        </StyledTableCell>
                        <StyledTableCell
                          style={{ width: '10%', fontSize: '12px', padding: '5px' }}
                          align="center"
                        >
                          Ctn G.W.
                        </StyledTableCell>
                        <StyledTableCell
                          style={{ width: '10%', fontSize: '12px', padding: '5px' }}
                          align="center"
                        >
                          Ctn L
                        </StyledTableCell>
                        <StyledTableCell
                          style={{ width: '10%', fontSize: '12px', padding: '5px' }}
                          align="center"
                        >
                          Ctn W
                        </StyledTableCell>
                        <StyledTableCell
                          style={{ width: '10%', fontSize: '12px', padding: '5px' }}
                          align="center"
                        >
                          Ctn H
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <BodyTableCell>
                          {
                            values[field][index].product.product_quotes[0].included_packaging_option
                              .type
                          }
                        </BodyTableCell>
                        <BodyTableCell>
                          {Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                          }).format(values[field][index].product.product_quotes[0].toolingCost)}
                        </BodyTableCell>
                        <BodyTableCell>
                          {Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                          }).format(values[field][index].product.product_quotes[0].sampleCost)}
                        </BodyTableCell>
                        <BodyTableCell>
                          {values[field][index].product.product_quotes[0].sample_lead_time}
                        </BodyTableCell>
                        <BodyTableCell>
                          {
                            values[field][index].product.product_quotes[0].included_packaging_option
                              .pieces_per_carton
                          }
                        </BodyTableCell>
                        <BodyTableCell>
                          {values[field][index].product.product_quotes[0].cartonDimensions.weight}
                        </BodyTableCell>
                        <BodyTableCell>
                          {values[field][index].product.product_quotes[0].cartonDimensions.length}
                        </BodyTableCell>
                        <BodyTableCell>
                          {values[field][index].product.product_quotes[0].cartonDimensions.width}
                        </BodyTableCell>
                        <BodyTableCell>
                          {values[field][index].product.product_quotes[0].cartonDimensions.height}
                        </BodyTableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              }
            >
              <InfoIcon className={classes.infoBtn} />
            </HtmlTooltip>
          )}
        </BodyTableCell>
        <BodyTableCell align="center" rowSpan={pricesLength} style={{ padding: '0px' }}>
          {editing ? (
            preview ? (
              <div className={classes.previewImageBox}>
                <img src={preview} className={classes.previewImage} alt="" />
                <IconButton
                  aria-label="delete"
                  className={classes.deleteBtn}
                  onClick={async () => {
                    try {
                      setUploading(true)
                      // await dataProvider.update('scm_quote', {
                      //   data: { id: values.id, image_id: null },
                      // })
                      await form.batch(() => {
                        form.change(`${field}[${index}].product.thumbnail_photo`, null)
                        form.change(`${field}[${index}].product.thumbnail_photo_id`, null)
                      })
                      notify('Image Deleted', { type: 'info' })
                      setUploading(false)
                    } catch (error) {
                      notify(error.message, { type: 'warning' })
                      setUploading(false)
                    }
                  }}
                >
                  <DeleteOutlineIcon />
                </IconButton>
              </div>
            ) : (
              <Box style={{ position: 'relative' }}>
                <div
                  {...getRootProps({
                    className: !editing ? classes.imageDropZone : classes.imageDropZoneEditing,
                  })}
                >
                  <input {...getInputProps()} />
                  {!uploading && !preview && <span>{`Drag / Upload`}</span>}
                </div>

                {uploading && <CircularProgress className={classes.loadingIcon} />}
              </Box>
            )
          ) : (
            preview && (
              <div className={classes.previewImageBox}>
                <img src={preview} className={classes.previewImage} alt="" />
              </div>
            )
          )}
        </BodyTableCell>
        <BodyTableCell rowSpan={pricesLength} align="right" style={{ padding: '0px' }}>
          <TextInput
            variant="standard"
            fullWidth
            multiline
            style={{ margin: '0px', height: '100%' }}
            source={`${field}[${index}].product.notes`}
            label={false}
            disabled={disabled}
          />
        </BodyTableCell>
        <BodyTableCell rowSpan={pricesLength} align="right" style={{ padding: '0px' }}>
          {editing ? (
            <Autocomplete
              value={values[field][index].product.product_quotes[0].supplier}
              id="asynchronous-demo"
              style={{ width: 120 }}
              open={open}
              onOpen={() => {
                setOpen(true)
              }}
              onClose={() => {
                setOpen(false)
              }}
              onChange={(e, v) => {
                form.batch(() => {
                  form.change(`${field}[${index}].product.product_quotes[0].supplier`, v)
                  form.change(`${field}[${index}].product.product_quotes[0].supplier_id`, v.id)
                })
              }}
              getOptionSelected={(option, value) => option.name === value.name}
              getOptionLabel={(option) => option.name}
              options={options}
              loading={loading}
              onInputChange={(e, newInputValue) => setInputValue(newInputValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Supplier"
                  variant="outlined"
                  style={{ fontSize: '0.8125rem', height: '36px' }}
                  // onChange={(e) => {
                  //   setLoading(true)
                  //   setSearchFilter(e.target.value)
                  // }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          ) : (
            <TextInput
              variant="standard"
              fullWidth
              style={{ margin: '0px', height: '100%' }}
              source={`${field}[${index}].product.product_quotes[0].supplier.name`}
              label={false}
              disabled={disabled}
            />
          )}
        </BodyTableCell>
        {editing && (
          <BodyTableCell rowSpan={pricesLength} align="right" style={{ padding: '0px' }}>
            <TextInput
              variant="standard"
              fullWidth
              style={{ margin: '0px', height: '100%' }}
              source={`${field}[${index}].product.product_quotes[0].included_packaging_option.type`}
              label={false}
              disabled={disabled}
            />
          </BodyTableCell>
        )}
        {editing && (
          <BodyTableCell rowSpan={pricesLength} align="right" style={{ padding: '0px' }}>
            <NumberFormat
              disabled={disabled}
              className={classes.numberTableInput}
              displayType="input"
              thousandSeparator
              decimalScale={2}
              fixedDecimalScale
              isNumericString
              source={`${field}[${index}].product.product_quotes[0].toolingCost`}
              prefix="$ "
            />
          </BodyTableCell>
        )}
        {editing && (
          <BodyTableCell rowSpan={pricesLength} align="right" style={{ padding: '0px' }}>
            <NumberFormat
              disabled={disabled}
              className={classes.numberTableInput}
              displayType="input"
              thousandSeparator
              decimalScale={2}
              fixedDecimalScale
              isNumericString
              source={`${field}[${index}].product.product_quotes[0].sampleCost`}
              prefix="$ "
            />
          </BodyTableCell>
        )}
        {editing && (
          <BodyTableCell rowSpan={pricesLength} align="right" style={{ padding: '0px' }}>
            <NumberFormat
              disabled={disabled}
              className={classes.numberTableInput}
              displayType="input"
              thousandSeparator
              decimalScale={0}
              fixedDecimalScale
              isNumericString
              source={`${field}[${index}].product.product_quotes[0].sample_lead_time`}
            />
          </BodyTableCell>
        )}
        {editing && (
          <BodyTableCell rowSpan={pricesLength} align="right" style={{ padding: '0px' }}>
            <NumberFormat
              disabled={disabled}
              className={classes.numberTableInput}
              displayType="input"
              thousandSeparator
              decimalScale={0}
              fixedDecimalScale
              isNumericString
              source={`${field}[${index}].product.product_quotes[0].included_packaging_option.pieces_per_carton`}
            />
          </BodyTableCell>
        )}
        {editing && (
          <BodyTableCell rowSpan={pricesLength} align="right" style={{ padding: '0px' }}>
            <NumberFormat
              disabled={disabled}
              className={classes.numberTableInput}
              displayType="input"
              thousandSeparator
              decimalScale={0}
              fixedDecimalScale
              isNumericString
              source={`${field}[${index}].product.product_quotes[0].cartonDimensions.weight`}
            />
          </BodyTableCell>
        )}
        {editing && (
          <BodyTableCell rowSpan={pricesLength} align="right" style={{ padding: '0px' }}>
            <NumberFormat
              disabled={disabled}
              className={classes.numberTableInput}
              displayType="input"
              thousandSeparator
              decimalScale={0}
              fixedDecimalScale
              isNumericString
              source={`${field}[${index}].product.product_quotes[0].cartonDimensions.length`}
            />
          </BodyTableCell>
        )}
        {editing && (
          <BodyTableCell rowSpan={pricesLength} align="right" style={{ padding: '0px' }}>
            <NumberFormat
              disabled={disabled}
              className={classes.numberTableInput}
              displayType="input"
              thousandSeparator
              decimalScale={0}
              fixedDecimalScale
              isNumericString
              source={`${field}[${index}].product.product_quotes[0].cartonDimensions.width`}
            />
          </BodyTableCell>
        )}
        {editing && (
          <BodyTableCell rowSpan={pricesLength} align="right" style={{ padding: '0px' }}>
            <NumberFormat
              disabled={disabled}
              className={classes.numberTableInput}
              displayType="input"
              thousandSeparator
              decimalScale={0}
              fixedDecimalScale
              isNumericString
              source={`${field}[${index}].product.product_quotes[0].cartonDimensions.height`}
            />
          </BodyTableCell>
        )}
        <BodyTableCell align="right" style={{ padding: '0px' }}>
          <NumberFormat
            disabled={disabled}
            className={classes.numberTableInput}
            displayType="input"
            thousandSeparator
            decimalScale={0}
            fixedDecimalScale
            isNumericString
            source={`${field}[${index}].product.product_quotes[0].prices[0].quantity`}
          />
        </BodyTableCell>
        <BodyTableCell align="right" style={{ padding: '0px' }}>
          <NumberFormat
            disabled={disabled}
            className={classes.numberTableInput}
            displayType="input"
            thousandSeparator
            decimalScale={2}
            fixedDecimalScale
            isNumericString
            source={`${field}[${index}].product.product_quotes[0].prices[0].unitPrice`}
            prefix="$ "
          />
        </BodyTableCell>
        <BodyTableCell align="right" style={{ padding: '0px' }}>
          <NumberFormat
            disabled={disabled}
            className={classes.numberTableInput}
            displayType="input"
            thousandSeparator
            decimalScale={0}
            fixedDecimalScale
            isNumericString
            source={`${field}[${index}].product.product_quotes[0].prices[0].lead_time`}
          />
        </BodyTableCell>
        {editing && (
          <BodyTableCell align="right" style={{ padding: '0px' }}>
            <IconButton
              aria-label="addProdQuotePrice"
              onClick={() => {
                const newProducts = [...values.newProducts]
                newProducts[index].product?.product_quotes[0].prices.push({})
                form.change('newProducts', newProducts)
              }}
            >
              <AddIcon />
            </IconButton>
            <IconButton
              aria-label="deleteProdQuotePrice"
              onClick={async () => {
                // console.log(values.newProducts[index].product?.product_quotes[0].prices)
                const price = values.newProducts[index].product?.product_quotes[0].prices[0]
                // console.log(price)
                if (price.id) {
                  try {
                    const delPrices = [price.id]
                    await dataProvider.delete('product_quote_price', { delPrices })
                  } catch (error) {
                    notify(error.message.split('.')[0], { type: 'warning' })
                  }
                }
                const newPriceList = [...values.newProducts[index].product.product_quotes[0].prices]
                newPriceList.splice(0, 1)
                form.batch(() => {
                  form.change(
                    `newProducts[${index}].product.product_quotes[0].prices`,
                    newPriceList,
                  )
                  form.change(`products[${index}].product.product_quotes[0].prices`, newPriceList)
                })
              }}
            >
              <DeleteOutlineIcon />
            </IconButton>
          </BodyTableCell>
        )}
        {editing ? (
          <BodyTableCell
            align="center"
            style={{
              padding: '2px 0px',
            }}
            rowSpan={pricesLength}
          >
            <IconButton
              aria-label="deleteProdQuote"
              onClick={async () => {
                // console.log(values.newProducts[index].id)
                if (values.newProducts[index].id) {
                  try {
                    await dataProvider.delete('sourcing_request_products', {
                      data: { id: values.newProducts[index].id },
                    })
                  } catch (error) {
                    notify(error.message.split('.')[0], { type: 'warning' })
                  }
                }
                const newProductsList = [...values.newProducts]
                newProductsList.splice(index, 1)
                form.change('newProducts', newProductsList)
              }}
            >
              <DeleteOutlineIcon />
            </IconButton>
          </BodyTableCell>
        ) : (
          <BodyTableCell
            align="center"
            style={{
              padding: '2px 0px',
            }}
            rowSpan={pricesLength}
          >
            <Button
              style={{ width: '100px' }}
              aria-label="createClientQuote"
              onClick={() => setCreateOpen(true)}
            >
              Add Client Quote
            </Button>
            {createOpen && config && (
              <ClientQuoteCreateDialog
                createOpen={createOpen}
                setCreateOpen={setCreateOpen}
                productIndex={index}
                factoryQuote={product?.product?.product_quotes[0]}
                milestones={milestones}
                config={config}
              />
            )}
          </BodyTableCell>
        )}
      </TableRow>
      {rowPrices?.map((el, priceIndex) => {
        // console.log('PriceEL:', el)
        return (
          <TableRow key={`prodQuotePrice${priceIndex}`}>
            <BodyTableCell align="right" style={{ padding: '0px' }}>
              <NumberFormat
                disabled={disabled}
                className={classes.numberTableInput}
                displayType="input"
                thousandSeparator
                decimalScale={0}
                fixedDecimalScale
                isNumericString
                source={`${field}[${index}].product.product_quotes[0].prices[${
                  priceIndex + 1
                }].quantity`}
              />
            </BodyTableCell>
            <BodyTableCell align="right" style={{ padding: '0px' }}>
              <NumberFormat
                disabled={disabled}
                className={classes.numberTableInput}
                displayType="input"
                thousandSeparator
                decimalScale={2}
                fixedDecimalScale
                isNumericString
                source={`${field}[${index}].product.product_quotes[0].prices[${
                  priceIndex + 1
                }].unitPrice`}
                prefix="$ "
              />
            </BodyTableCell>
            <BodyTableCell align="right" style={{ padding: '0px' }}>
              <NumberFormat
                disabled={disabled}
                className={classes.numberTableInput}
                displayType="input"
                thousandSeparator
                decimalScale={0}
                fixedDecimalScale
                isNumericString
                source={`${field}[${index}].product.product_quotes[0].prices[${
                  priceIndex + 1
                }].lead_time`}
              />
            </BodyTableCell>
            {editing && (
              <BodyTableCell align="right" style={{ padding: '0px' }}>
                <IconButton
                  aria-label="addProdQuotePrice"
                  onClick={() => {
                    const newProducts = [...values.newProducts]
                    newProducts[index].product?.product_quotes[0].prices.push({})
                    form.change('newProducts', newProducts)
                  }}
                >
                  <AddIcon />
                </IconButton>
                <IconButton
                  aria-label="deleteProdQuotePrice"
                  onClick={async () => {
                    // console.log(values.newProducts[index].product?.product_quotes[0].prices)
                    // console.log(priceIndex + 1)
                    const price =
                      values.newProducts[index].product?.product_quotes[0].prices[priceIndex + 1]
                    // console.log(price)
                    if (price.id) {
                      try {
                        const delPrices = [price.id]
                        await dataProvider.delete('product_quote_price', { delPrices })
                      } catch (error) {
                        notify(error.message.split('.')[0], { type: 'warning' })
                      }
                    }
                    const newPriceList = [
                      ...values.newProducts[index].product.product_quotes[0].prices,
                    ]
                    newPriceList.splice(priceIndex + 1, 1)
                    // form.batch(()=>)

                    form.batch(() => {
                      form.change(
                        `newProducts[${index}].product.product_quotes[0].prices`,
                        newPriceList,
                      )
                      form.change(
                        `products[${index}].product.product_quotes[0].prices`,
                        newPriceList,
                      )
                    })
                  }}
                >
                  <DeleteOutlineIcon />
                </IconButton>
              </BodyTableCell>
            )}
          </TableRow>
        )
      })}
    </>
  )
}
export default ProductsTable
