import React from 'react'
import {
  // Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
} from '@material-ui/core'
import { useFormState } from 'react-final-form'
import NumberFormat from 'react-number-format'
import useStyles from '../styles'

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#FFFFFF',
    color: '#000000',
    padding: '8px',
    fontSize: 12,
    borderTop: '2px solid #000000',
    borderBottom: '2px solid #000000',
    borderLeft: '1px solid #DEDEDE',
    borderRight: '1px solid #DEDEDE',
  },
  body: {
    borderLeft: '1px solid #DEDEDE',
    borderRight: '1px solid #DEDEDE',
    padding: '8px',
    fontSize: 12,
  },
}))(TableCell)
const StyledTableCellBold = withStyles(() => ({
  body: {
    borderLeft: '1px solid #DEDEDE',
    borderRight: '1px solid #DEDEDE',
    padding: '8px',
    // color: '#000000',
    fontSize: 12,
    // fontWeight: 600,
  },
}))(TableCell)
const StyledTableSubtitleCell = withStyles(() => ({
  body: {
    borderLeft: '1px solid #DEDEDE',
    borderRight: '1px solid #DEDEDE',
    color: '#000000',
    margin: '0px',
    borderTop: '2px solid #000000',
    borderBottom: '2px solid #000000',
    fontSize: 12,
    padding: '8px',
    fontWeight: 600,
  },
}))(TableCell)

const IncomeTables = (props) => {
  const classes = useStyles()
  // console.log('INCTABLEPROPS', props)
  const { values } = useFormState()

  const { calculatedIncomeFields, dropshippingEstimate, clientCostTotal, clientCostUnit } = props

  return (
    <div>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="spanning table">
          <TableHead>
            <TableRow>
              <StyledTableCell />
              <StyledTableCell align="right">Price</StyledTableCell>
              <StyledTableCell align="right">Margin</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <StyledTableCell>Retail Price</StyledTableCell>
              <StyledTableCell align="right">
                <NumberFormat
                  value={values.retailPrice}
                  className={classes.numberFormat}
                  displayType="text"
                  // thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  // disabled={retailPriceConfirmed}
                  prefix="$"
                  renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
                />
              </StyledTableCell>
              <StyledTableCell align="right">
                <NumberFormat
                  value={values.retailMargin}
                  className={classes.numberFormat}
                  displayType="text"
                  // thousandSeparator
                  decimalScale={0}
                  fixedDecimalScale
                  suffix="%"
                  renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
                />
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableSubtitleCell colSpan={2}>TOTAL POTENTIAL REVENUE</StyledTableSubtitleCell>
              <StyledTableSubtitleCell align="right">
                <NumberFormat
                  value={calculatedIncomeFields?.potentialRevenueTotal}
                  className="numberFormat"
                  displayType="text"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  prefix="$"
                  renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
                />
              </StyledTableSubtitleCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="spanning table">
          <TableHead>
            <TableRow>
              <StyledTableCell width="34%" align="center" />
              <StyledTableCell width="18%" align="center">
                Rate
              </StyledTableCell>
              <StyledTableCell width="20%" align="center">
                Per Piece
              </StyledTableCell>
              <StyledTableCell width="28%" align="center">
                Total
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <StyledTableCell align="left">Dropshipping Fees</StyledTableCell>
              <StyledTableCell />
              <StyledTableCell width="12.5%" align="right">
                <NumberFormat
                  value={dropshippingEstimate}
                  className={classes.numberFormat}
                  displayType="text"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  prefix="$"
                  renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
                />
              </StyledTableCell>
              <StyledTableCell width="12.5%" align="right">
                <NumberFormat
                  value={calculatedIncomeFields.dropshippingEstimateTotal}
                  className={classes.numberFormat}
                  displayType="text"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  prefix="$"
                  renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
                />
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>Commission %</StyledTableCell>
              <StyledTableCell align="right">
                <NumberFormat
                  value={values.commissionFees}
                  className={classes.numberFormat}
                  displayType="text"
                  // thousandSeparator
                  decimalScale={1}
                  fixedDecimalScale
                  suffix="%"
                  renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
                />
              </StyledTableCell>
              <StyledTableCell align="right">
                <NumberFormat
                  value={calculatedIncomeFields?.commissionUnit}
                  className="numberFormat"
                  displayType="text"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  prefix="$"
                  renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
                />
              </StyledTableCell>
              <StyledTableCellBold align="right">
                <NumberFormat
                  value={calculatedIncomeFields?.commissionTotal}
                  className="numberFormat"
                  displayType="text"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  prefix="$"
                  renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
                />
              </StyledTableCellBold>
            </TableRow>
            <TableRow>
              <StyledTableCell>Payment Processing Fees</StyledTableCell>
              <StyledTableCell align="right">
                <NumberFormat
                  value={values.consumerPaymentFees}
                  className={classes.numberFormat}
                  displayType="text"
                  // thousandSeparator
                  decimalScale={1}
                  fixedDecimalScale
                  suffix="%"
                  renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
                />
              </StyledTableCell>
              <StyledTableCell align="right">
                <NumberFormat
                  value={calculatedIncomeFields?.consumerPaymentUnit}
                  className="numberFormat"
                  displayType="text"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  prefix="$"
                  renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
                />
              </StyledTableCell>
              <StyledTableCellBold align="right">
                <NumberFormat
                  value={calculatedIncomeFields?.consumerPaymentTotal}
                  className="numberFormat"
                  displayType="text"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  prefix="$"
                  renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
                />
              </StyledTableCellBold>
            </TableRow>
            <TableRow>
              <StyledTableCell>Marketing Budget %</StyledTableCell>
              <StyledTableCell width="12.5%" align="right">
                <NumberFormat
                  value={values.marketingFees}
                  className={classes.numberFormat}
                  displayType="text"
                  // thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  suffix="%"
                  renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
                />
              </StyledTableCell>
              <StyledTableCell align="right">
                <NumberFormat
                  value={calculatedIncomeFields?.marketingBudgetUnit}
                  className="numberFormat"
                  displayType="text"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  prefix="$"
                  renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
                />
              </StyledTableCell>
              <StyledTableCellBold align="right">
                <NumberFormat
                  value={calculatedIncomeFields?.marketingBudgetTotal}
                  className="numberFormat"
                  displayType="text"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  prefix="$"
                  renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
                />
              </StyledTableCellBold>
            </TableRow>
            {/* <TableRow>
              <StyledTableCell>Fulfillment</StyledTableCell>
              <StyledTableCell align="center">-</StyledTableCell>
              <StyledTableCell align="right">
                <NumberFormat
                  value={values.fulfillmentFees}
                  className={classes.numberFormat}
                  displayType="text"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  prefix="$"
                  renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
                />
              </StyledTableCell>
              <StyledTableCellBold align="right">
                <NumberFormat
                  value={calculatedIncomeFields?.fulfillmentTotal}
                  className="numberFormat"
                  displayType="text"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  prefix="$"
                  renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
                />
              </StyledTableCellBold>
            </TableRow> */}
            <TableRow>
              <StyledTableCell>Service Fees</StyledTableCell>
              <StyledTableCell align="right">
                <NumberFormat
                  value={values.consumerServiceFees}
                  className={classes.numberFormat}
                  displayType="text"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  suffix="%"
                  renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
                />
              </StyledTableCell>
              <StyledTableCellBold align="right">
                <NumberFormat
                  value={calculatedIncomeFields?.consumerServiceUnit}
                  className="numberFormat"
                  displayType="text"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  prefix="$"
                  renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
                />
              </StyledTableCellBold>
              <StyledTableCell align="right">
                <NumberFormat
                  value={calculatedIncomeFields.consumerServiceTotal}
                  className={classes.numberFormat}
                  displayType="text"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  prefix="$"
                  renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
                />
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>Licensing & Royalties Fees</StyledTableCell>
              <StyledTableCell align="right">
                <NumberFormat
                  value={values.consumerRoyaltiesFees}
                  className={classes.numberFormat}
                  displayType="text"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  suffix="%"
                  renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
                />
              </StyledTableCell>
              <StyledTableCellBold align="right">
                <NumberFormat
                  value={calculatedIncomeFields?.consumerRoyaltiesUnit}
                  className="numberFormat"
                  displayType="text"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  prefix="$"
                  renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
                />
              </StyledTableCellBold>
              <StyledTableCell align="right">
                <NumberFormat
                  value={calculatedIncomeFields.consumerRoyaltiesTotal}
                  className={classes.numberFormat}
                  displayType="text"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  prefix="$"
                  renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
                />
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>Partner Fees</StyledTableCell>
              <StyledTableCell align="right">
                <NumberFormat
                  value={values.consumerPartnerFees}
                  className={classes.numberFormat}
                  displayType="text"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  suffix="%"
                  renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
                />
              </StyledTableCell>
              <StyledTableCellBold align="right">
                <NumberFormat
                  value={calculatedIncomeFields?.consumerPartnerUnit}
                  className="numberFormat"
                  displayType="text"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  prefix="$"
                  renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
                />
              </StyledTableCellBold>
              <StyledTableCell align="right">
                <NumberFormat
                  value={calculatedIncomeFields.consumerPartnerTotal}
                  className={classes.numberFormat}
                  displayType="text"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  prefix="$"
                  renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
                />
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>Software Fees</StyledTableCell>
              <StyledTableCell align="right">
                <NumberFormat
                  value={values.consumerSoftwareFees}
                  className={classes.numberFormat}
                  displayType="text"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  suffix="%"
                  renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
                />
              </StyledTableCell>
              <StyledTableCellBold align="right">
                <NumberFormat
                  value={calculatedIncomeFields?.consumerSoftwareUnit}
                  className="numberFormat"
                  displayType="text"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  prefix="$"
                  renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
                />
              </StyledTableCellBold>
              <StyledTableCell align="right">
                <NumberFormat
                  value={calculatedIncomeFields.consumerSoftwareTotal}
                  className={classes.numberFormat}
                  displayType="text"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  prefix="$"
                  renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
                />
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableSubtitleCell colSpan={3}>TOTAL SELLING FEES</StyledTableSubtitleCell>
              <StyledTableSubtitleCell align="right">
                <NumberFormat
                  value={calculatedIncomeFields?.sellingFeesTotal}
                  className="numberFormat"
                  displayType="text"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  prefix="$"
                  renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
                />
              </StyledTableSubtitleCell>
            </TableRow>
            <TableRow rowSpan={2} />
            <TableRow>
              <StyledTableCell colSpan={2}>Investor Income</StyledTableCell>
              <StyledTableCell align="right">
                <NumberFormat
                  value={calculatedIncomeFields.grossMarginUnit}
                  className="numberFormat"
                  displayType="text"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  prefix="$"
                  renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
                />
              </StyledTableCell>
              <StyledTableCell align="right">
                <NumberFormat
                  value={calculatedIncomeFields?.grossMargin}
                  className="numberFormat"
                  displayType="text"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  prefix="$"
                  renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
                />
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell colSpan={2}>COGS</StyledTableCell>
              <StyledTableCell align="right">
                <NumberFormat
                  value={clientCostUnit}
                  className="numberFormat"
                  displayType="text"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  prefix="$"
                  renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
                />
              </StyledTableCell>
              <StyledTableCell align="right">
                <NumberFormat
                  value={clientCostTotal}
                  className="numberFormat"
                  displayType="text"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  prefix="$"
                  renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
                />
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableSubtitleCell colSpan={3}>INVESTOR PROFIT</StyledTableSubtitleCell>
              <StyledTableSubtitleCell align="right">
                <NumberFormat
                  value={calculatedIncomeFields?.netIncome}
                  className="numberFormat"
                  displayType="text"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  prefix="$"
                  renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
                />
              </StyledTableSubtitleCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default IncomeTables
