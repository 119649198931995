import React from 'react'
import {
  Box,
  Button,
  // Accordion,
  // AccordionDetails,
  // AccordionSummary,
  Typography,
  // Tooltip
} from '@material-ui/core'
// import { uniqueId } from 'lodash'
import { useForm, useFormState } from 'react-final-form'
// import Skeleton from '@material-ui/lab/Skeleton'
// import { ApolloProvider } from '@apollo/client'
// import NewClient from '../../../dataProvider/hasura/buildApolloClient'
import {
  // required,
  // useRedirect,
  useDataProvider,
  // useNotify,
  // useGetIdentity,
} from 'react-admin'
// import AddIcon from '@material-ui/icons/Add'
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
// import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser'
// import { withStyles } from '@material-ui/core/styles'
// import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
// import WarningIcon from '@material-ui/icons/Warning'
// import CheckIcon from '@material-ui/icons/CheckCircleOutlined'
// import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined'
import FactoryQuoteCalculator from './FactoryQuoteCalculator'
import GetAppIcon from '@material-ui/icons/GetApp'
import VisibilityIcon from '@material-ui/icons/Visibility'
import AdditionalFilesFieldInput from '../../components/fieldInputs/AdditionalFilesFieldInput'
import MultiplePhotoSelect from '../../components/fieldInputs/MultiplePhotoSelect'
import AutocompleteFieldInput from '../../components/fieldInputs/AutocompleteFieldInput'
// import AutocompleteSpecificationArrayFieldInput from '../../components/fieldInputs/AutocompleteSpecificationArrayFieldInput'
// import AutocompleteArrayFieldInput from '../../components/fieldInputs/AutocompleteArrayFieldInput'
import CategoriesFieldInput from '../../components/fieldInputs/CategoriesFieldInput'
// import MultipleColourSelect from '../../components/fieldInputs/MultipleColourSelect'
// import ChipFieldInput from '../../components/fieldInputs/ChipFieldInput'
import SpecificationArrayFieldInput from '../../components/fieldInputs/SpecificationArrayFieldInput'
// import DimensionsTableFieldInput from '../../components/fieldInputs/DimensionsTableFieldInput'
import TextFieldInput from '../../components/fieldInputs/TextFieldInput'
import RichTextFieldInput from '../../components/fieldInputs/RichTextFieldInput'
import DateFieldInput from '../../components/fieldInputs/DateFieldInput'
import NumberFieldInput from '../../components/fieldInputs/NumberFieldInput'
import FactoryQuotePaymentTermsInput from '../../components/fieldInputs/PaymentTermsInput'
// import SelectFieldInput from '../../components/fieldInputs/SelectFieldInput'
// import BooleanFieldInput from '../../components/fieldInputs/BooleanFieldInput'
import TagSelectWithCreate from '../../components/fieldInputs/TagSelectWithCreate'
import SupplierSelectWithCreate from '../../components/fieldInputs/SupplierSelectWithCreate'
// import CustomisationsTable from './CustomisationsTable'
import ProductSizesTable from '../../components/ProductSizesTable'
import ProductOptionsForm from '../../components/ProductOptionsForm'
import ProductMaterialsForm from '../../components/ProductMaterialsForm'
import AutocompleteArrayWithCreate from '../../components/fieldInputs/AutocompleteArrayWithCreate'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  outerBox: {
    padding: '20px 40px',
    // backgroundColor: 'white',
  },
  title: {
    position: 'relative',
    fontFamily: ['-apple-system', 'BlinkMacSystemFont', 'sans-serif'].join(','),
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '32px',
    lineHeight: '38px',
    marginBottom: '40px',
  },
  heading: {
    fontSize: '15px',
    fontWeight: '600',
  },
  spacer: {
    height: '10px',
  },
  spacer20: {
    height: '20px',
  },
  // accordionStyle: {
  //   '& .MuiAccordionSummary-root': {
  //     '& .MuiAccordionSummary-content': {
  //       justifyContent: 'space-between',
  //       alignItems: 'center',
  //     },
  //   },
  // },
  centerBox: {
    padding: '0px 10px 12px 20px !important',
    // marginLeft: '20px',
    // marginRight: '20px',
    width: '-webkit-fill-available',
    // border: 'blue 1px solid',
  },
  rightBox: {
    padding: '0px 20px 12px 10px !important',
    // marginLeft: '20px',
    // marginRight: '20px',
    width: '-webkit-fill-available',
    // border: 'blue 1px solid',
  },
  leftNavItem: {
    color: 'rgba(255, 149, 102, 0.5)',
    marginTop: '5px',
    cursor: 'pointer',
  },
  contentBox: {
    backgroundColor: 'white',
    padding: '20px 20px !important',
    borderRadius: '8px',
    border: 'solid 1px #CDCDCD',
  },
  sectionHeader: {
    fontSize: '12px',
    color: 'rgb(18, 183, 106)',
  },
}))

const FormData = (props) => {
  // console.log('FDPROPS', props)
  const {
    packagingTypes = [],
    materials = [],
    imprints = [],
    milestones = [],
    // categories = [],
    // formProps,
  } = props
  const classes = useStyles()
  const form = useForm()
  // const redirect = useRedirect()
  const dataProvider = useDataProvider()
  // const notify = useNotify()
  // const identity = useGetIdentity()

  // const token = localStorage.getItem('token')
  // const client = NewClient(token)

  const { values } = useFormState()

  // Object.keys(errors).length > 0 && console.log('FORMERRORS', errors)

  // console.log('PDFORMVALS:', values)

  // const branchProducts =
  //   values.branch_products?.length > 0
  //     ? values.branch_products
  //     : values.root_product?.branch_products

  const getMilestones = (x) => x * 5
  const milestonesOptions = Array.from(Array(20), (_, x) => {
    const v = getMilestones(x + 1)
    return {
      name: v.toString(),
      value: v,
    }
  })

  return (
    <Box display="flex" flexDirection="column" className={classes.outerBox}>
      <Box display="flex" alignItems="center" justifyContent="space-between" p="30px !important">
        <span style={{ fontSize: '24px', lineHeight: '36px', fontWeight: '500' }}>
          Product Details Input
        </span>

        <Box display="flex">
          <Button style={{ border: 'solid 1px black', marginRight: '20px' }}>
            <VisibilityIcon />
            Preview
          </Button>
          <Button style={{ border: 'solid 1px black' }}>
            <GetAppIcon />
            Download
          </Button>
        </Box>
      </Box>

      <Box display="flex">
        <Box flex={7} display="flex" flexDirection="column" className={classes.centerBox}>
          {/* <Accordion className={classes.accordionStyle}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>General Information</Typography>
              {values?.primary_photo && values?.thumbnail ? (
                <CheckIcon style={{ color: 'green' }} />
              ) : (
                <CancelOutlinedIcon style={{ color: 'red' }} />
              )}
            </AccordionSummary>
            <AccordionDetails>
              <Box display="flex" flexDirection="column" width="100%"> */}
          <Box className={classes.contentBox}>
            <Typography variant="h5">General Information</Typography>
            <span className={classes.sectionHeader}>Photos and Media</span>
            <div className={classes.spacer} />
            <MultiplePhotoSelect
              id={values.id}
              hidePrimary
              reference="product_id"
              resource="product_file"
              type="^ADDITIONAL_PHOTO^"
              field="images"
              primaryField="primary_photo"
              onClickPreview={async (file) => {
                await dataProvider.update('product', {
                  data: {
                    thumbnail_photo_id: file.file.id,
                    primary_photo_id: file.file.id,
                    id: values.id,
                  },
                })
                form.change('primary_photo', file.file)
                form.change('thumbnail_photo', file.file)
              }}
              onSave={async (data) => {
                if (!values.primary_photo?.id) {
                  await dataProvider.update('product', {
                    data: {
                      thumbnail_photo_id: data[0].file?.id,
                      primary_photo_id: data[0].file?.id,
                      id: values.id,
                    },
                  })
                  await form.change('primary_photo', {
                    id: data[0].file?.id,
                    filename: data[0].file?.filename,
                    url: data[0].file.url,
                  })
                  await form.change('thumbnail_photo', {
                    id: data[0].file?.id,
                    filename: data[0].file?.filename,
                    url: data[0].file.url,
                  })
                }
              }}
            />
            <div className={classes.spacer} />
            <TextFieldInput
              variant="standard"
              fullWidth
              field="name"
              resource="product"
              id={values.id}
              label="Product name:"
              // validate={required()}
              value={values.name}
            />
            <div className={classes.spacer} />
            <TextFieldInput
              variant="standard"
              fullWidth
              field="catalogue_sku"
              resource="product"
              id={values.id}
              label="Product code / SKU:"
              // validate={required()}
              value={values.catalogue_sku}
            />
            <div className={classes.spacer} />
            <TextFieldInput
              variant="standard"
              fullWidth
              field="short_description"
              resource="product"
              id={values.id}
              label="Short description:"
              // validate={required()}
              multiline
              minRows={2}
              style={{
                alignItems: 'start',
              }}
              value={values.short_description}
            />
            <div className={classes.spacer} />
            <TextFieldInput
              variant="standard"
              fullWidth
              field="long_description"
              resource="product"
              id={values.id}
              label="Long description:"
              multiline
              minRows={2}
              style={{
                alignItems: 'start',
              }}
              value={values.long_description}
            />
            <div className={classes.spacer} />
            <span className={classes.sectionHeader}>Specifications</span>
            <div className={classes.spacer} />
            <SpecificationArrayFieldInput
              variant="standard"
              fullWidth
              fieldLabel="Other specifications"
              fieldName="product_specifications"
              resourceName="product_specifications"
              source="otherSpecifications"
              referenceField="product_id"
              referenceId={values.id}
              oldDataField="oldSpecifications"
              onConflict={{
                on_conflict: {
                  constraint: '^product_specifications_product_id_specification_id_key^',
                  update_columns: ['^product_id^', '^specification_id^'],
                },
              }}
            />
            <div className={classes.spacer} />
            <span className={classes.sectionHeader}>Selling Features</span>
            <div className={classes.spacer} />
            <div className={classes.rtfInputBox}>
              <RichTextFieldInput
                // variant="standard"
                source="selling_features"
                resource="product"
                id={values.id}
                label={false}
              />
            </div>
            <div className={classes.spacer} />
          </Box>
          {/* </Box> */}
          {/* </AccordionDetails>
          </Accordion> */}
          <div className={classes.spacer20} />

          {/* <Accordion className={classes.accordionStyle}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>Quotes</Typography>
              {values?.primary_photo && values?.thumbnail ? (
                <CheckIcon style={{ color: 'green' }} />
              ) : (
                <CancelOutlinedIcon style={{ color: 'red' }} />
              )}
            </AccordionSummary>
            <AccordionDetails> */}
          <Box className={classes.contentBox}>
            <Typography variant="h5">Quotes</Typography>

            <Box display="flex" flexDirection="column" width="100%">
              <span className={classes.sectionHeader}>Supplier</span>
              <div className={classes.spacer} />

              <SupplierSelectWithCreate
                // validate={required()}
                label="Supplier"
                optionText={(value) => {
                  return value?.official_verification_link
                    ? value?.name
                    : `${value?.name} (Unverified)`
                }}
                source={'product_quotes[0].supplier_id'}
              />
              <FactoryQuoteCalculator />
              <div className={classes.spacer} />

              <DateFieldInput
                variant="standard"
                fullWidth
                field="valid_date"
                formField="product_quotes[0].valid_date"
                resource="product_quote"
                id={values.product_quotes[0].id}
                label="Factory quote valid until:"
                className={classes.fieldInputLeftNarrow}
                // validate={required()}
                // value={values.product_quotes[0].valid_date}
              />
              <div className={classes.spacer} />

              <Box display="flex">
                <Box flex={1}>
                  <span className={classes.sectionHeader}>Sample Costs</span>
                  <div className={classes.spacer} />

                  <Box display="flex">
                    <NumberFieldInput
                      className={classes.fieldInputLeftNarrow}
                      label="Tooling cost:"
                      value={values?.toolingCost}
                      resource="product_quote"
                      field="tooling_cost"
                      defaultValue={0}
                      decimalScale={2}
                      fixedDecimalScale
                      parseMultiplier={100}
                      prefix="$ "
                      id={values?.product_quotes?.length > 0 ? values.product_quotes[0].id : null}
                    />
                    <NumberFieldInput
                      className={classes.fieldInputLeftNarrow}
                      label="Tooling lead time:"
                      value={
                        values?.product_quotes?.length > 0
                          ? values.product_quotes[0].tooling_lead_time
                          : null
                      }
                      resource="product_quote"
                      field="tooling_lead_time"
                      defaultValue={0}
                      decimalScale={0}
                      fixedDecimalScale
                      suffix=" days"
                      id={values?.product_quotes?.length > 0 ? values.product_quotes[0].id : null}
                    />
                  </Box>
                  <div className={classes.spacer} />

                  <Box display="flex">
                    <NumberFieldInput
                      className={classes.fieldInputLeftNarrow}
                      label="Sample cost:"
                      value={values?.sampleCost}
                      resource="product_quote"
                      field="sample_cost"
                      defaultValue={0}
                      decimalScale={2}
                      fixedDecimalScale
                      parseMultiplier={100}
                      prefix="$ "
                      id={values?.product_quotes?.length > 0 ? values.product_quotes[0].id : null}
                    />
                    {/* <NumberFieldInput
                  className={classes.fieldInputLeftNarrow}
                  label="Custom sample cost:"
                  value={values?.customSampleCost}
                  resource="product_quote"
                  field="custom_sample_cost"
                  defaultValue={0}
                  decimalScale={2}
                  fixedDecimalScale
                  parseMultiplier={100}
                  prefix="$ "
                  id={values?.product_quotes?.length > 0 ? values.product_quotes[0].id : null}
                /> */}
                    <NumberFieldInput
                      className={classes.fieldInputLeftNarrow}
                      label="Sample lead time:"
                      value={
                        values?.product_quotes?.length > 0
                          ? values.product_quotes[0].sample_lead_time
                          : null
                      }
                      resource="product_quote"
                      field="sample_lead_time"
                      defaultValue={0}
                      decimalScale={0}
                      fixedDecimalScale
                      suffix=" days"
                      id={values?.product_quotes?.length > 0 ? values.product_quotes[0].id : null}
                    />
                  </Box>
                </Box>
                <Box flex={1}>
                  <FactoryQuotePaymentTermsInput
                    milestones={milestones}
                    milestonesOptions={milestonesOptions}
                    id={values.product_quotes[0].id}
                    resource="product_quote_payment_terms"
                    formField="product_quotes[0].payment_terms"
                    parentField="product_quote_id"
                    field="payment_terms"
                    title="Payment Terms"
                    titleClass={classes.sectionHeader}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          {/* </AccordionDetails>
          </Accordion> */}
          <div className={classes.spacer20} />
          {/* 
          <Accordion className={classes.accordionStyle}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                Sizes, Dimensions and Packaging Options
              </Typography>
              {values?.primary_photo && values?.thumbnail ? (
                <CheckIcon style={{ color: 'green' }} />
              ) : (
                <CancelOutlinedIcon style={{ color: 'red' }} />
              )}
            </AccordionSummary>
            <AccordionDetails> */}
          <Box className={classes.contentBox}>
            <Typography variant="h5">Sizes, Dimensions and Packaging Options</Typography>

            {packagingTypes?.length > 0 ? (
              <ProductSizesTable packagingTypes={packagingTypes} />
            ) : null}
          </Box>
          {/* </AccordionDetails>
          </Accordion> */}
          <div className={classes.spacer20} />

          {/* <Accordion className={classes.accordionStyle}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>Options and Customizations</Typography>
              {values?.primary_photo && values?.thumbnail ? (
                <CheckIcon style={{ color: 'green' }} />
              ) : (
                <CancelOutlinedIcon style={{ color: 'red' }} />
              )}
            </AccordionSummary>
            <AccordionDetails> */}

          <Box className={classes.contentBox}>
            <Typography variant="h5">Options and Customizations</Typography>

            {/* <Box display="flex" flexDirection="column" width="100%"> */}
            <ProductOptionsForm materials={materials} imprints={imprints} />
            {/* </Box> */}
            {/* </AccordionDetails>
          </Accordion> */}
          </Box>
          <div className={classes.spacer20} />

          <Box className={classes.contentBox}>
            <Typography variant="h5">Product Materials</Typography>
            <ProductMaterialsForm />
          </Box>
          <div className={classes.spacer20} />

          {/* <Accordion className={classes.accordionStyle}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>Additional Files</Typography>
            </AccordionSummary>
            <AccordionDetails> */}

          <Box className={classes.contentBox}>
            <Typography variant="h5">Additional Files</Typography>

            <AdditionalFilesFieldInput
              field="additional_files"
              resource="product_file"
              resourceConstraint="product_file_file_id_product_id_type_key"
              referenceField="product_id"
              referenceId={values.id}
              typesList={[
                { id: 0, name: 'Design Artwork', value: 'DESIGN_ARTWORK' },
                { id: 1, name: 'Design Dieline', value: 'DESIGN_DIELINE' },
                { id: 2, name: 'Design Logo', value: 'DESIGN_LOGO' },
                { id: 3, name: 'Factory Quote', value: 'FACTORY_QUOTE' },
                { id: 4, name: 'Size Chart', value: 'SIZE_CHART' },
                { id: 5, name: 'Other', value: 'OTHER' },
                // { id: 0, name: 'Packing List', value: 'PACKING_LIST' },
                // { id: 1, name: 'Product Spec Sheet', value: 'PRODUCT_SPEC_SHEET' },
                // { id: 2, name: 'Product Artwork', value: 'PRODUCT_ARTWORK' },
                // { id: 3, name: 'Product Concept Rendering', value: 'PRODUCT_CONCEPT_RENDERING' },
                // { id: 4, name: 'Product Technical Drawing', value: 'PRODUCT_TECHNICAL_DRAWING' },
                // { id: 5, name: 'Packaging Artwork', value: 'PACKAGING_ARTWORK' },
                // { id: 6, name: 'Packaging Concept Rendering', value: 'PACKAGING_CONCEPT_RENDERING' },
                // { id: 7, name: 'Packaging Technical Drawing', value: 'PACKAGING_TECHNICAL_DRAWING' },
                // { id: 8, name: 'Carton Artwork', value: 'CARTON_ARTWORK' },
                // { id: 9, name: 'Carton Concept Rendering', value: 'CARTON_CONCEPT_RENDERING' },
                // { id: 10, name: 'Carton Technical Drawing', value: 'CARTON_TECHNICAL_DRAWING' },
                // { id: 11, name: 'Other Design File', value: 'DESIGN_FILE' },
              ]}
            />
          </Box>
          {/* </AccordionDetails>
          </Accordion> */}
          <div className={classes.spacer20} />
          {/* 
          <Accordion className={classes.accordionStyle}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>Additional Notes</Typography>
            </AccordionSummary>
            <AccordionDetails> */}

          <Box className={classes.contentBox}>
            <Typography variant="h5">Additional Notes</Typography>

            {/* <Box width="100%"> */}
            <TextFieldInput
              variant="standard"
              fullWidth
              field="notes"
              className={classes.textInputFull}
              resource="product"
              id={values.id}
              multiline
              minRows={2}
              style={{
                alignItems: 'start',
              }}
              label="Product notes:"
              // validate={required()}
              value={values.notes}
            />
          </Box>
          {/* </Box> */}
          {/* </AccordionDetails>
          </Accordion> */}
        </Box>
        <Box flex={3} className={classes.rightBox}>
          {/* <Box style={{ position: 'fixed' }} display="flex" flexDirection="column">
          <span className={classes.leftNavItem}>Item</span>
          <span className={classes.leftNavItem}>Item</span>
          <span className={classes.leftNavItem}>Item</span>
          <span className={classes.leftNavItem}>Item</span>
          <span className={classes.leftNavItem}>Item</span>
        </Box> */}
          <Box className={classes.contentBox}>
            <Typography variant="h5">Organization</Typography>
            {/* <TextFieldInput
              variant="standard"
              fullWidth
              field="brand"
              resource="product"
              id={values.id}
              label="Brand:"
              // validate={required()}
              value={values.brand}
            /> */}
            <AutocompleteFieldInput
              variant="standard"
              fullWidth
              fieldLabel="Brand"
              referenceOnConflict={{
                constraint: '^brand_name_key^',
                update_columns: ['^name^'],
              }}
              referenceField="name"
              resource="product"
              source="brand"
              elementName="brand"
              reference="brand"
              id={values.id}
              // onSave={() => checkFormValid(values)}
              // classes={{ container: classes.categoryInput }}
            />
            {/* <AutocompleteArrayFieldInput
              variant="standard"
              fullWidth
              reference="categories"
              fieldLabel="Categories"
              elementName="category"
              // validate={required()}
              data={values.categories}
              oldData={values.product_categories}
              resourceName="product_categories"
              fieldName="category_id"
              referenceField="product_id"
              referenceId={values.id}
              classes={{ container: classes.categoryInput }}
              onConflict={{
                on_conflict: {
                  constraint: 'product_categories_product_id_category_id_key',
                  update_columns: ['category_id', 'product_id'],
                },
              }}
            /> */}
            <CategoriesFieldInput
              variant="standard"
              fullWidth
              fieldLabel="Categories"
              formField="chosenCategories"
              oldField="oldCategories"
              resourceName="product_categories"
              fieldName="category_id"
              referenceField="product_id"
              referenceId={values.id}
              classes={{ container: classes.categoryInput }}
              onConflict={{
                on_conflict: {
                  constraint: 'product_categories_product_id_category_id_key',
                  update_columns: ['product_id', 'category_id'],
                },
              }}
            />
            <div className={classes.spacer} />
            <TagSelectWithCreate
              variant="standard"
              fullWidth
              reference="tags"
              fieldLabel="Tags"
              // validate={required()}
              data={values.tags}
              oldData={values.product_tags}
              resourceName="product_tags"
              fieldName="tag_id"
              referenceField="product_id"
              referenceId={values.id}
            />
            <div className={classes.spacer} />
            <AutocompleteArrayWithCreate
              source="chosenActivities"
              resource="system_dictionary"
              resourceFilter="activity"
              label="Activities"
              freeSolo
              fieldName="activity"
              referenceField="product_id"
              referenceId={values?.id}
              resourceName="product_activities"
              oldData="activities"
              onConflict={{
                on_conflict: {
                  constraint: 'product_activities_product_id_activity_key',
                  update_columns: ['activity', 'product_id'],
                },
              }}
            />
          </Box>

          <Box className={classes.contentBox} style={{ marginTop: '30px' }}>
            <Box display="flex" flexDirection="column">
              <span className={classes.sectionHeader}>HTS Code and Duty Rate</span>
              <div className={classes.spacer} />
              <Box display="flex">
                <TextFieldInput
                  variant="standard"
                  // fullWidth
                  field="hts_code"
                  resource="product"
                  id={values.id}
                  label="HTS code:"
                  // validate={required()}
                  value={values.hts_code}
                  className={classes.fieldInputLeftNarrow}
                />

                <NumberFieldInput
                  className={classes.fieldInputLeftNarrow}
                  label="Duty rate:"
                  value={values?.dutyRate}
                  resource="product"
                  field="duty_rate"
                  defaultValue={0}
                  decimalScale={2}
                  fixedDecimalScale
                  parseMultiplier={100}
                  suffix=" %"
                  id={values.id}
                  // onSave={() => saveFactoryQuote({ values, dataProvider })}
                />
              </Box>
            </Box>
          </Box>
        </Box>

        {/* <Box mt="30px" display="flex" flexDirection="column">
        <span className={classes.labelText}>Product Family</span>
        <Box display="flex" className={classes.relatedProductsContainer}>
          {values.id && values.root_product_id && values.root_product_id !== values.id && (
            <Box key={`root${values.root_product_id}`} className={classes.relatedProductsImageBox}>
              <span className={classes.baseProductLabel}>Base Product</span>
              <img
                src={values.root_product?.thumbnail_photo?.url}
                className={classes.relatedProductsImage}
                alt=""
                // onClick={(e) => console.log(values.root_product_id)}
                onClick={() => redirect(`/products/${values.root_product_id}`)}
              />
              <span>{values.root_product?.name}</span>
            </Box>
          )}
          {branchProducts?.map((item) => (
            <Box key={`branch${item.id}`} className={classes.relatedProductsImageBox}>
              <img
                src={item.thumbnail_photo?.url}
                alt=""
                className={classes.relatedProductsImage}
                onClick={() => redirect(`/products/${item.id}`)}
              />
              <span>{item.name}</span>
            </Box>
          ))}
        </Box>
      </Box> */}
      </Box>
    </Box>
  )
}

export default FormData
