import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Box, Tabs, Tab } from '@material-ui/core'
import { useDataProvider, useNotify } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
// import { uniqueId } from 'lodash'
import SaleableEditForm from './components/SaleableEditForm'
import ProductStockForm from './components/ProductStockForm'
import ProductSalesForm from './components/ProductSalesForm'
import OffersForm from './components/OffersForm'
import HeaderSection from './components/HeaderSection'
// import SaleableProductVariantsList from './subFields/saleableProductVariants/SaleableProductVariantsList'

const useStyles = makeStyles({
  tabPanel: {
    padding: '0px',
    '& .MuiBox-root': {
      padding: '0px',
    },
  },
})

const SaleableProductEdit = (props) => {
  const location = useLocation()
  const queryParameters = new URLSearchParams(location.search)

  const tab = queryParameters.get('tab')

  const dataProvider = useDataProvider()
  const notify = useNotify()
  const classes = useStyles()
  const [value, setValue] = useState(
    tab === 'info' ? 0 : tab === 'stock' ? 1 : tab === 'sales' ? 2 : tab === 'offers' ? 3 : 0,
  )
  // const [imprints, setImprints] = useState([])
  const [categories, setCategories] = useState([])
  const [materials, setMaterials] = useState([])
  const [config, setConfig] = useState(null)
  const [packagingTypes, setPackagingTypes] = useState([])
  // const [formErrors, setFormErrors] = useState([])

  // const [addresses, setAddresses] = useState([])

  // useEffect(() => {
  //   const getClientAddresses = async () => {
  //     const { data: addressesTemp } = await dataProvider.getList('address', {
  //       pagination: { page: 1, perPage: 100 },
  //       sort: { field: 'type', order: 'ASC' },
  //       filter: { organisation_id: values.client.id },
  //     })
  //     setAddresses(addressesTemp)
  //   }
  //   values.client_id && getClientAddresses()
  // }),
  //   [values.client_id]

  // console.log(props)

  // const checkFormValid = (record) => {
  //   console.log('VALIDATING', record)
  //   if (!record) {
  //     return null
  //   }
  //   const errors = []
  //   !record.name && errors.push('Name')
  //   !record.primary_photo && errors.push('Primary Photo')
  //   !record.sku && errors.push('SKU')
  //   !record.description && errors.push('Description')
  //   !record.long_description && errors.push('Long Description')
  //   !record.retailPrice > 0 && errors.push('Retail Price')
  //   !record.chosenPlatforms?.length > 0 && errors.push('Platforms')
  //   !record.brand && errors.push('Brand')
  //   !record.single_carton_type && errors.push('Single Carton Type')
  //   !record.singleCartonHeight && errors.push('Single Carton Height')
  //   !record.singleCartonLength && errors.push('Single Carton Length')
  //   !record.singleCartonWidth && errors.push('Single Carton Width')
  //   !record.singleCartonWeight && errors.push('Single Carton Weight')

  //   setFormErrors(errors)
  // }

  useEffect(() => {
    const getDataLists = async () => {
      try {
        // debugMode && console.count('STEP')
        // debugMode && console.time('GETTING IMPRINTS')
        // const { data: imprintsTemp } = await dataProvider.getList('imprints', {
        //   filter: { own: 'imprintTechnique' },
        // })
        // !abortController.signal.aborted && setImprints(imprintsTemp)
        // debugMode && console.timeEnd('GETTING IMPRINTS')

        const { data: packagingTypesTemp } = await dataProvider.getList('packagingStyle', {
          filter: { own: 'packagingStyle' },
        })
        !abortController.signal.aborted && setPackagingTypes(packagingTypesTemp)

        const { data: categoriesTemp } = await dataProvider.getList('categories', {})
        !abortController.signal.aborted && setCategories(categoriesTemp)

        const { data: materialsTemp } = await dataProvider.getList('materials', {
          filter: { own: '%material%' },
        })
        !abortController.signal.aborted && setMaterials(materialsTemp)

        const { data: configTemp } = await dataProvider.globalConfig('globalConfig', {
          fields: ['estimated_freight_rate_dropship', 'estimated_freight_rate_air'],
        })
        const configVals = {}
        configTemp.global_preferences.forEach(
          (item) => (configVals[item.setting_key] = item.setting_value),
        )
        !abortController.signal.aborted && setConfig(configVals)
      } catch (error) {
        console.log(error)
        notify(error.message.split('.')[0], { type: 'warning' })

        return
      }
    }

    const abortController = new AbortController()
    getDataLists()

    return () => {
      abortController.abort()
    }
  }, [])

  if (!config || materials?.length < 1 || categories?.length < 1 || packagingTypes?.length < 1) {
    return null
  }
  // console.log('CFG', config)

  const TabPanel = (tabProps) => {
    const { children, value, index } = tabProps

    // console.log(other)
    return (
      <div
        style={{ padding: '0px' }}
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        className={classes.tabPanel}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </div>
    )
  }

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <div>
      <HeaderSection
        id={props.id}
        //  formErrors={formErrors}
      />
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="simple tabs example"
        style={{ marginBottom: '4px' }}
      >
        <Tab label="General Information" {...a11yProps(0)} />
        <Tab label="Stock Options" {...a11yProps(1)} />
        <Tab label="Sales" {...a11yProps(2)} />
        <Tab label="Offers" {...a11yProps(3)} />
      </Tabs>
      <TabPanel value={value} index={0} className={classes.tabPanel}>
        {value === 0 && (
          <SaleableEditForm
            categories={categories}
            packagingTypes={packagingTypes}
            materials={materials}
            config={config}
            // checkFormValid={checkFormValid}
            {...props}
            // imprints={imprints}
          />
        )}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {value === 1 && (
          <ProductStockForm saleableProductId={props.id} basePath="/saleableProducts" />
        )}
      </TabPanel>
      <TabPanel value={value} index={2}>
        {value === 2 && (
          <ProductSalesForm saleableProductId={props.id} basePath="/saleableProducts" />
        )}
      </TabPanel>
      <TabPanel value={value} index={3}>
        {value === 3 && (
          <OffersForm
            saleableProductId={props.id}
            basePath="/saleableProducts"
            resource="saleableProducts"
          />
        )}
      </TabPanel>
    </div>
  )
}

export default SaleableProductEdit
