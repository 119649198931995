import React, { useState } from 'react'
import { useDataProvider, useNotify, TextInput } from 'react-admin'
import {
  Box,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  withStyles,
} from '@material-ui/core'
import { useForm, useFormState } from 'react-final-form'
import { makeStyles } from '@material-ui/core/styles'
import BorderColorIcon from '@material-ui/icons/BorderColorOutlined'
import CancelOutlined from '@material-ui/icons/CancelOutlined'
import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined'
import InfoIcon from '@material-ui/icons/InfoOutlined'
// import lodashGet from 'lodash'
import NumberFieldInput from '../../components/fieldInputs/NumberFieldInput2'
import TextFieldInput from '../../components/fieldInputs/TextFieldInput'
import NumberFormat from '../../components/CustomNumberFormat'
import { GetCalculatedIncomeFields } from '../../offers/offer/components/CostsAndPricing'
import { roundNumber } from '../../../utils/utils'

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip)

const useStyles = makeStyles(() => ({
  table: {
    overflowX: 'unset',
  },
  tableTitle: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '14px',
    color: '#333333',
    marginBottom: '10px',
    marginTop: '10px',
  },
  filesLabel: {
    fontSize: '12px',
    fontWeight: '600',
    marginBottom: '8px',
  },
  labelText: {
    fontSize: '12px',
  },
  numberInput: {
    width: '80px',
    height: '36px',
    textAlign: 'center',
  },
  numberInputLeft: {
    marginRight: '80px',
  },
  numberTableInput: {
    width: '100%',
    height: '100%',
    marginTop: '0px',
    marginBottom: '0px',
    // height: '30px',
    minHeight: '30px',
    padding: '0px',
    '& .MuiFilledInput-underline::before': {
      borderBottom: 'none',
    },
  },
  textInput: {
    width: '120px',
    height: '36px',
    marginLeft: '4px',
    paddingBottom: '15px',
    marginRight: '45px',
  },
}))

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#FFFFFF',
    color: '#000000',
    padding: '6px',
    fontSize: '12px',
    border: '1px solid #DEDEDE',
  },
  body: {
    border: '1px solid #DEDEDE',
    padding: '0px',
    fontSize: 12,
  },
}))(TableCell)

const PricingDetails = () => {
  const classes = useStyles()
  const form = useForm()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const { values } = useFormState()
  const [saving, setSaving] = useState(false)
  const [editing, setEditing] = useState(false)

  // console.log(values.memberPrice)
  // console.log(values.expressPrice)
  // console.log(values)

  return (
    <Box position="relative">
      <TableContainer component={Box} className={classes.table}>
        <Table className={classes.table} aria-label="shipping table">
          <TableHead>
            <TableRow>
              <StyledTableCell width="16%">
                Unit Cost
                <HtmlTooltip
                  title={
                    <div>
                      Factory Prices:
                      <ul>
                        {values.product_quote?.prices?.map((el) => (
                          <li key={el.id}>{`Quantity: ${el.quantity} - Price: $${(
                            el.unit_price / 100
                          ).toFixed(2)}`}</li>
                        ))}
                      </ul>
                    </div>
                  }
                >
                  <InfoIcon style={{ fontSize: '16px', marginLeft: '5px' }} />
                </HtmlTooltip>
              </StyledTableCell>
              <StyledTableCell width="16%">FOB Buffer</StyledTableCell>
              <StyledTableCell width="17%">FOB Price</StyledTableCell>
              <StyledTableCell width="17%" style={{ color: 'rgba(0, 0, 0, 0.38)' }}>
                Dropshipping Cost
              </StyledTableCell>
              <StyledTableCell width="17%" style={{ color: 'rgba(0, 0, 0, 0.38)' }}>
                Total Fees
              </StyledTableCell>
              <StyledTableCell width="17%" style={{ color: 'rgba(0, 0, 0, 0.38)' }}>
                Total Cost
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <StyledTableCell align="right">
                <NumberFormat
                  className={classes.numberTableInput}
                  displayType="input"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  isNumericString
                  prefix="$ "
                  source="unitCost"
                  disabled={!editing}
                  onChange={(e) => {
                    const newCost = Number(e.target.value.slice(2))
                    const margin = 1 - values.fobMargin / 100

                    const fobPrice = Number((newCost / margin).toFixed(2))

                    const sellingFeesTotal = GetCalculatedIncomeFields({
                      commissionFees: values.commissionFees,
                      marketingFees: values.marketingFees,
                      retailPrice: values.retailPrice,
                      consumerPartnerFees: values.consumerPartnerFees,
                      consumerPaymentFees: values.consumerPaymentFees,
                      consumerServiceFees: values.consumerServiceFees,
                      consumerSoftwareFees: values.consumerSoftwareFees,
                      consumerRoyaltiesFees: values.consumerRoyaltiesFees,
                    })

                    const newPrice =
                      values.dropshippingEstimate + values.fobPrice + sellingFeesTotal

                    const estimatedProfit =
                      values.retailPrice - values.dropshippingEstimate - fobPrice - sellingFeesTotal
                    // const estimatedMemberProfit =
                    //   values.memberPrice - values.dropshippingEstimate - fobPrice - sellingFeesTotal

                    // console.log(
                    //   'DROPEST',
                    //   values.dropshippingEstimate,
                    //   'NEWCOST',
                    //   newPrice,
                    //   'ESTimatedPROFIT',
                    //   estimatedProfit,
                    //   'FOBPRICE',
                    //   fobPrice,
                    //   'RETAILPRICE',
                    //   values.retailPrice,
                    //   'SELLFEES',
                    //   sellingFeesTotal,
                    // )

                    form.batch(() => {
                      form.change('totalFees', sellingFeesTotal)
                      form.change('totalCost', newPrice)
                      form.change('fobPrice', fobPrice)
                      form.change('estimatedProfit', estimatedProfit)
                      // form.change('estimatedMemberProfit', estimatedMemberProfit)
                    })
                  }}
                />
              </StyledTableCell>
              <StyledTableCell align="right">
                <NumberFormat
                  className={classes.numberTableInput}
                  displayType="input"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  isNumericString
                  suffix="%"
                  disabled={!editing}
                  onChange={(e) => {
                    const margin = 1 - Number(e.target.value.slice(0, -1)) / 100
                    const fobPrice = Number((values.unitCost / margin).toFixed(2))

                    const sellingFeesTotal = GetCalculatedIncomeFields({
                      commissionFees: values.commissionFees,
                      marketingFees: values.marketingFees,
                      retailPrice: values.retailPrice,
                      consumerPartnerFees: values.consumerPartnerFees,
                      consumerPaymentFees: values.consumerPaymentFees,
                      consumerServiceFees: values.consumerServiceFees,
                      consumerSoftwareFees: values.consumerSoftwareFees,
                      consumerRoyaltiesFees: values.consumerRoyaltiesFees,
                    })

                    const newPrice =
                      values.dropshippingEstimate + values.fobPrice + sellingFeesTotal

                    const estimatedProfit =
                      values.retailPrice - values.dropshippingEstimate - fobPrice - sellingFeesTotal
                    // const estimatedMemberProfit =
                    //   values.memberPrice - values.dropshippingEstimate - fobPrice - sellingFeesTotal

                    // console.log(
                    //   'NEWMARGIN',
                    //   margin,
                    //   'ESTimatedPROFIT',
                    //   estimatedProfit,
                    //   'FOBPRICE',
                    //   fobPrice,
                    //   'RETAILPRICE',
                    //   values.retailPrice,
                    //   'SELLFEES',
                    //   sellingFeesTotal,
                    // )

                    form.batch(() => {
                      form.change('totalFees', sellingFeesTotal)
                      form.change('totalCost', newPrice)
                      form.change('fobPrice', fobPrice)
                      form.change('estimatedProfit', estimatedProfit)
                      // form.change('estimatedMemberProfit', estimatedMemberProfit)
                    })
                  }}
                  source="fobMargin"
                />
              </StyledTableCell>
              <StyledTableCell align="right">
                <NumberFormat
                  className={classes.numberTableInput}
                  displayType="input"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  isNumericString
                  prefix="$ "
                  disabled={!editing}
                  onChange={(e) => {
                    const price = Number(e.target.value.slice(2))
                    const fobMargin = ((price - values.unitCost) / price) * 100
                    const sellingFeesTotal = GetCalculatedIncomeFields({
                      commissionFees: values.commissionFees,
                      marketingFees: values.marketingFees,
                      retailPrice: values.retailPrice,
                      consumerPartnerFees: values.consumerPartnerFees,
                      consumerPaymentFees: values.consumerPaymentFees,
                      consumerServiceFees: values.consumerServiceFees,
                      consumerSoftwareFees: values.consumerSoftwareFees,
                      consumerRoyaltiesFees: values.consumerRoyaltiesFees,
                    })
                    const newPrice =
                      values.dropshippingEstimate + values.fobPrice + sellingFeesTotal

                    const estimatedProfit =
                      values.retailPrice - values.dropshippingEstimate - price - sellingFeesTotal
                    // const estimatedMemberProfit =
                    //   values.memberPrice - values.dropshippingEstimate - price - sellingFeesTotal

                    // console.log(
                    //   'UNITCOST',
                    //   values.unitCost,
                    //   'ESTimatedPROFIT',
                    //   estimatedProfit,
                    //   'FOBPRICE',
                    //   price,
                    //   'RETAILPRICE',
                    //   values.retailPrice,
                    //   'TOTALFEES',
                    //   totalFees,
                    //   'SELLFEES',
                    //   sellingFeesTotal,
                    // )

                    form.batch(() => {
                      form.change('totalFees', sellingFeesTotal)
                      form.change('totalCost', newPrice)
                      form.change('fobMargin', fobMargin)
                      form.change('estimatedProfit', estimatedProfit)
                      // form.change('estimatedMemberProfit', estimatedMemberProfit)
                    })
                  }}
                  source="fobPrice"
                />
              </StyledTableCell>
              <StyledTableCell align="right">
                <NumberFormat
                  className={classes.numberTableInput}
                  displayType="input"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  isNumericString
                  disabled
                  prefix="$ "
                  source="dropshippingEstimate"
                />
              </StyledTableCell>
              <StyledTableCell align="right">
                <NumberFormat
                  className={classes.numberTableInput}
                  displayType="input"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  isNumericString
                  disabled
                  prefix="$ "
                  source="totalFees"
                />
              </StyledTableCell>
              <StyledTableCell align="right">
                <NumberFormat
                  className={classes.numberTableInput}
                  displayType="input"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  isNumericString
                  disabled
                  prefix="$ "
                  source="totalCost"
                />
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell
                style={{
                  lineHeight: '1.5rem',
                  fontWeight: 500,
                  color: '#000',
                  border: '1px solid #DEDEDE',
                  fontSize: '12px',
                  padding: '6px',
                }}
              >
                Retail Price
              </StyledTableCell>
              <StyledTableCell
                style={{
                  lineHeight: '1.5rem',
                  fontWeight: 500,
                  color: 'rgba(0, 0, 0, 0.38)',
                  border: '1px solid #DEDEDE',
                  fontSize: '12px',
                  padding: '6px',
                }}
              >
                Express Price
              </StyledTableCell>
              <StyledTableCell
                style={{
                  lineHeight: '1.5rem',
                  fontWeight: 500,
                  color: 'rgba(0, 0, 0, 0.38)',
                  border: '1px solid #DEDEDE',
                  fontSize: '12px',
                  padding: '6px',
                }}
              >
                Member Price
              </StyledTableCell>
              <StyledTableCell
                style={{
                  lineHeight: '1.5rem',
                  fontWeight: 500,
                  color: 'rgba(0, 0, 0, 0.38)',
                  border: '1px solid #DEDEDE',
                  fontSize: '12px',
                  padding: '6px',
                }}
              >
                Member Express Price
              </StyledTableCell>
              <StyledTableCell
                style={{
                  lineHeight: '1.5rem',
                  fontWeight: 500,
                  color: 'rgba(0, 0, 0, 0.38)',
                  border: '1px solid #DEDEDE',
                  fontSize: '12px',
                  padding: '6px',
                }}
              >
                Inventory Owner Profit
              </StyledTableCell>
            </TableRow>
            <TableRow>
              {/* <StyledTableCell colSpan={2} /> */}
              <StyledTableCell align="right">
                <NumberFormat
                  className={classes.numberTableInput}
                  displayType="input"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  isNumericString
                  prefix="$ "
                  source="retailPrice"
                  disabled={!editing}
                  onChange={(e) => {
                    const newPrice = Number(e.target.value.slice(2))

                    const sellingFeesTotal = GetCalculatedIncomeFields({
                      commissionFees: values.commissionFees,
                      marketingFees: values.marketingFees,
                      retailPrice: newPrice,
                      consumerPartnerFees: values.consumerPartnerFees,
                      consumerPaymentFees: values.consumerPaymentFees,
                      consumerServiceFees: values.consumerServiceFees,
                      consumerSoftwareFees: values.consumerSoftwareFees,
                      consumerRoyaltiesFees: values.consumerRoyaltiesFees,
                    })

                    // console.log(
                    //   'ESTimatedPROFIT',
                    //   estimatedProfit,
                    //   'FOBPRICE',
                    //   values.fobPrice,
                    //   'RETAILPRICE',
                    //   newPrice,
                    //   'SELLFEES',
                    //   sellingFeesTotal,
                    // )

                    // express price is the same as retail, minus the dropshipping, and add the courier estimate

                    // member price is the same as retail, with 0 commission fees. if commission fees are 0, then member price will be the same!

                    const sellingFeesTotalWithoutCommission = GetCalculatedIncomeFields({
                      commissionFees: 0,
                      marketingFees: values.marketingFees,
                      retailPrice: newPrice,
                      consumerPartnerFees: values.consumerPartnerFees,
                      consumerPaymentFees: values.consumerPaymentFees,
                      consumerServiceFees: values.consumerServiceFees,
                      consumerSoftwareFees: values.consumerSoftwareFees,
                      consumerRoyaltiesFees: values.consumerRoyaltiesFees,
                    })

                    // Express Price
                    // Member Price
                    // Member Express Price
                    // Est Profit
                    // Est Member Profit

                    // console.log('DROPEST', values.dropshippingEstimate)

                    // console.log('COUREST', values.courierEstimate)

                    const expressPrice =
                      newPrice - values.dropshippingEstimate + values.courierEstimate

                    const memberPrice = roundNumber(
                      newPrice - sellingFeesTotal + sellingFeesTotalWithoutCommission,
                      2,
                    )

                    const memberExpressPrice =
                      memberPrice - values.dropshippingEstimate + values.courierEstimate

                    const estimatedProfit = roundNumber(newPrice - values.totalCost, 2)

                    // const estimatedMemberProfit = memberPrice - values.totalCost

                    // console.log(
                    //   'DROPSHIPPING',
                    //   values.dropshippingEstimate,
                    //   'ESTIMATEDPROFIT',
                    //   estimatedProfit,
                    //   'FOBPRICE',
                    //   values.fobPrice,
                    //   'RETAILPRICE',
                    //   values.retailPrice,
                    //   'SELLFEES',
                    //   sellingFeesTotal,
                    //   'SELLFEES0COM',
                    //   sellingFeesTotalWithoutCommission,
                    //   'TOTALCOST',
                    //   values.totalCost,
                    //   'MEMBERPRICE',
                    //   memberPrice,
                    //   // 'MEMBERPROFIT',
                    //   // estimatedMemberProfit,
                    // )

                    form.batch(() => {
                      form.change('totalFees', sellingFeesTotal)
                      form.change('estimatedProfit', estimatedProfit)
                      form.change('expressPrice', expressPrice)
                      form.change('memberPrice', memberPrice)
                      form.change('memberExpressPrice', memberExpressPrice)
                      // form.change('estimatedMemberProfit', estimatedMemberProfit)
                    })
                  }}
                />
              </StyledTableCell>
              <StyledTableCell align="right">
                <NumberFormat
                  className={classes.numberTableInput}
                  displayType="input"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  isNumericString
                  disabled
                  prefix="$ "
                  source="expressPrice"
                />
              </StyledTableCell>
              <StyledTableCell align="right">
                <NumberFormat
                  className={classes.numberTableInput}
                  displayType="input"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  isNumericString
                  disabled
                  prefix="$ "
                  source="memberPrice"
                />
              </StyledTableCell>
              <StyledTableCell align="right">
                <NumberFormat
                  className={classes.numberTableInput}
                  displayType="input"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  isNumericString
                  disabled
                  prefix="$ "
                  source="memberExpressPrice"
                />
              </StyledTableCell>
              <StyledTableCell
                align="right"
                style={
                  values.retailPrice < values.totalCost
                    ? { boxShadow: 'inset red 0px 0px 6px 2px' }
                    : null
                }
              >
                <NumberFormat
                  className={classes.numberTableInput}
                  displayType="input"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  isNumericString
                  prefix="$ "
                  source="estimatedProfit"
                  disabled
                />
              </StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      {editing ? (
        <Box display="flex">
          <IconButton
            onClick={async () => {
              setSaving(true)
              try {
                await dataProvider.update('saleable_product', {
                  data: {
                    id: values.id,
                    retail_price: parseInt(values.retailPrice * 100),
                    fob_price: parseInt(values.fobPrice * 100),
                    dropshipping_estimate: parseInt(values.dropshippingEstimate * 100),
                    unit_cost: parseInt(values.unitCost * 100),
                    express_price: parseInt(values.expressPrice * 100),
                    member_price: parseInt(values.memberPrice * 100),
                    member_express_price: parseInt(values.memberExpressPrice * 100),
                  },
                })
                // await checkFormValid(values)
                notify('Save Complete', { type: 'info' })
                setEditing(false)
                setSaving(false)
              } catch (error) {
                console.log(error)
                setSaving(false)
                notify(error.message, { type: 'warning' })
              }
            }}
            style={{
              maxWidth: '30px',
              maxHeight: '30px',
              position: 'absolute',
              top: '-15px',
              right: '20px',
              backgroundColor: '#FFF',
              border: 'solid 1px lightgray',
            }}
          >
            <CheckCircleOutlineOutlined fontSize="small" style={{ color: 'FF0000' }} />
          </IconButton>
          <IconButton
            onClick={() => {
              setEditing(false)
            }}
            style={{
              maxWidth: '30px',
              maxHeight: '30px',
              position: 'absolute',
              top: '-15px',
              right: '-15px',
              backgroundColor: '#FFF',
              border: 'solid 1px lightgray',
            }}
          >
            <CancelOutlined fontSize="small" />
          </IconButton>
          {saving && (
            <CircularProgress
              style={{
                width: '20px',
                height: '20px',
                position: 'absolute',
                top: '-15px',
                left: '5px',
              }}
            />
          )}
        </Box>
      ) : (
        <IconButton
          onClick={() => {
            setEditing(true)
          }}
          style={{
            maxWidth: '30px',
            maxHeight: '30px',
            position: 'absolute',
            top: '-15px',
            right: '-15px',
            backgroundColor: '#FFF',
            border: 'solid 1px lightgray',
          }}
        >
          <BorderColorIcon fontSize="small" />
        </IconButton>
      )}
      <Box mt="20px">
        <span>Selling Fees & Budgets</span>
        <Box display="flex">
          <Box display="flex" flexDirection="column" alignItems="flex-start" mt="20px">
            <Box display="flex">
              <NumberFieldInput
                className={classes.fieldInputNarrow}
                label="Marketing budget:"
                value={values?.marketingFees}
                resource="saleable_product"
                field="marketing_fees"
                formField="marketingFees"
                defaultValue={0}
                decimalScale={2}
                fixedDecimalScale
                parseMultiplier={100}
                suffix="%"
                id={values.id}
                onChange={(e) => {
                  const newFees = Number(e.target.value.slice(0, -1))

                  const sellingFeesTotal = GetCalculatedIncomeFields({
                    commissionFees: values.commissionFees,
                    marketingFees: newFees,
                    retailPrice: values.retailPrice,
                    consumerPartnerFees: values.consumerPartnerFees,
                    consumerPaymentFees: values.consumerPaymentFees,
                    consumerServiceFees: values.consumerServiceFees,
                    consumerSoftwareFees: values.consumerSoftwareFees,
                    consumerRoyaltiesFees: values.consumerRoyaltiesFees,
                  })

                  const estimatedProfit = roundNumber(
                    values.retailPrice -
                      sellingFeesTotal -
                      values.dropshippingEstimate -
                      values.fobPrice,
                    2,
                  )

                  // const estimatedMemberProfit =
                  //   values.memberPrice -
                  //   sellingFeesTotal -
                  //   values.dropshippingEstimate -
                  //   values.fobPrice

                  const newPrice = values.dropshippingEstimate + values.fobPrice + sellingFeesTotal

                  // console.log('NEWTOTALCOST', newPrice)

                  form.batch(() => {
                    form.change('totalCost', newPrice)
                    form.change('totalFees', sellingFeesTotal)
                    form.change('estimatedProfit', estimatedProfit)
                    // form.change('estimatedMemberProfit', estimatedMemberProfit)
                  })
                }}
              />
              <TextFieldInput
                variant="standard"
                fullWidth
                field="consumer_marketing_fees_destination"
                resource="saleable_product"
                id={values.id}
                label="Destination:"
                // validate={required()}
                value={values.consumer_marketing_fees_destination}
                className={classes.fieldInputLeft}
              />
            </Box>

            <Box display="flex">
              <NumberFieldInput
                className={classes.fieldInputNarrow}
                label="Software fees:"
                value={values?.consumerSoftwareFees}
                resource="saleable_product"
                field="consumer_software_fees"
                formField="consumerSoftwareFees"
                defaultValue={0}
                decimalScale={2}
                fixedDecimalScale
                parseMultiplier={100}
                suffix="%"
                id={values.id}
                onChange={(e) => {
                  const newFees = Number(e.target.value.slice(0, -1))

                  const sellingFeesTotal = GetCalculatedIncomeFields({
                    commissionFees: values.commissionFees,
                    marketingFees: values.marketingFees,
                    retailPrice: values.retailPrice,
                    consumerPartnerFees: values.consumerPartnerFees,
                    consumerPaymentFees: values.consumerPaymentFees,
                    consumerServiceFees: values.consumerServiceFees,
                    consumerSoftwareFees: newFees,
                    consumerRoyaltiesFees: values.consumerRoyaltiesFees,
                  })

                  const estimatedProfit = roundNumber(
                    values.retailPrice -
                      sellingFeesTotal -
                      values.dropshippingEstimate -
                      values.fobPrice,
                    2,
                  )

                  // const estimatedMemberProfit =
                  //   values.memberPrice -
                  //   sellingFeesTotal -
                  //   values.dropshippingEstimate -
                  //   values.fobPrice

                  const newPrice = values.dropshippingEstimate + values.fobPrice + sellingFeesTotal

                  // console.log('NEWTOTALCOST', newPrice)

                  form.batch(() => {
                    form.change('totalCost', newPrice)
                    form.change('totalFees', sellingFeesTotal)
                    form.change('estimatedProfit', estimatedProfit)
                    // form.change('estimatedMemberProfit', estimatedMemberProfit)
                  })
                }}
              />
              <TextFieldInput
                variant="standard"
                fullWidth
                field="consumer_software_fees_destination"
                resource="saleable_product"
                id={values.id}
                label="Destination:"
                // validate={required()}
                value={values.consumer_software_fees_destination}
                className={classes.fieldInputLeft}
              />
            </Box>
            <Box display="flex">
              <NumberFieldInput
                className={classes.fieldInputNarrow}
                label="Payment fees:"
                value={values?.consumerPaymentFees}
                resource="saleable_product"
                field="consumer_payment_fees"
                formField="consumerPaymentFees"
                defaultValue={0}
                decimalScale={2}
                fixedDecimalScale
                parseMultiplier={100}
                suffix="%"
                id={values.id}
                onChange={(e) => {
                  const newFees = Number(e.target.value.slice(0, -1))

                  const sellingFeesTotal = GetCalculatedIncomeFields({
                    commissionFees: values.commissionFees,
                    marketingFees: values.marketingFees,
                    retailPrice: values.retailPrice,
                    consumerPartnerFees: values.consumerPartnerFees,
                    consumerPaymentFees: newFees,
                    consumerServiceFees: values.consumerServiceFees,
                    consumerSoftwareFees: values.consumerSoftwareFees,
                    consumerRoyaltiesFees: values.consumerRoyaltiesFees,
                  })

                  const estimatedProfit = roundNumber(
                    values.retailPrice -
                      sellingFeesTotal -
                      values.dropshippingEstimate -
                      values.fobPrice,
                    2,
                  )

                  // const estimatedMemberProfit =
                  //   values.memberPrice -
                  //   sellingFeesTotal -
                  //   values.dropshippingEstimate -
                  //   values.fobPrice

                  const newPrice = values.dropshippingEstimate + values.fobPrice + sellingFeesTotal

                  // console.log('NEWTOTALCOST', newPrice)

                  form.batch(() => {
                    form.change('totalCost', newPrice)
                    form.change('totalFees', sellingFeesTotal)
                    // form.change('estimatedMemberProfit', estimatedMemberProfit)
                    form.change('estimatedProfit', estimatedProfit)
                  })
                }}
              />
              <TextFieldInput
                variant="standard"
                fullWidth
                field="consumer_payment_fees_destination"
                resource="saleable_product"
                id={values.id}
                label="Destination:"
                // validate={required()}
                value={values.consumer_payment_fees_destination}
                className={classes.fieldInputLeft}
              />
            </Box>
            <Box display="flex" alignItems="flex-end">
              <NumberFieldInput
                className={classes.fieldInputLeftNarrow}
                label="Commission fees:"
                value={values?.commissionFees}
                resource="saleable_product"
                field="commission_fees"
                formField="commissionFees"
                defaultValue={0}
                decimalScale={2}
                fixedDecimalScale
                parseMultiplier={100}
                suffix="%"
                id={values.id}
                onChange={(e) => {
                  const newFees = Number(e.target.value.slice(0, -1))

                  const sellingFeesTotal = GetCalculatedIncomeFields({
                    commissionFees: newFees,
                    marketingFees: values.marketingFees,
                    retailPrice: values.retailPrice,
                    consumerPartnerFees: values.consumerPartnerFees,
                    consumerPaymentFees: values.consumerPaymentFees,
                    consumerServiceFees: values.consumerServiceFees,
                    consumerSoftwareFees: values.consumerSoftwareFees,
                    consumerRoyaltiesFees: values.consumerRoyaltiesFees,
                  })

                  const sellingFeesTotalWithoutCommission = GetCalculatedIncomeFields({
                    commissionFees: 0,
                    marketingFees: values.marketingFees,
                    retailPrice: values.retailPrice,
                    consumerPartnerFees: values.consumerPartnerFees,
                    consumerPaymentFees: values.consumerPaymentFees,
                    consumerServiceFees: values.consumerServiceFees,
                    consumerSoftwareFees: values.consumerSoftwareFees,
                    consumerRoyaltiesFees: values.consumerRoyaltiesFees,
                  })

                  // Total Cost
                  // !Express Price // doesn't update here
                  // Member Price
                  // Member Express Price
                  // Est Profit
                  // Est Member Profit

                  const totalCost = values.dropshippingEstimate + values.fobPrice + sellingFeesTotal

                  // const expressPrice =
                  //   values.retailPrice - values.dropshippingEstimate + values.courierEstimate

                  const memberPrice = roundNumber(
                    values.retailPrice - sellingFeesTotal + sellingFeesTotalWithoutCommission,
                    2,
                  )

                  // console.log('DROPEST', values.dropshippingEstimate)

                  // console.log('COUREST', values.courierEstimate)

                  const memberExpressPrice =
                    memberPrice - values.dropshippingEstimate + values.courierEstimate

                  const estimatedProfit = roundNumber(values.retailPrice - totalCost, 2)

                  // const estimatedMemberProfit = memberPrice - totalCost

                  // console.log(
                  //   'DUTY',
                  //   values.dutyRate,
                  //   'DROPSHIPPING',
                  //   values.dropshippingEstimate,
                  //   'ESTIMATEDPROFIT',
                  //   estimatedProfit,
                  //   'FOBPRICE',
                  //   values.fobPrice,
                  //   'RETAILPRICE',
                  //   values.retailPrice,
                  //   'SELLFEES',
                  //   sellingFeesTotal,
                  //   'SELLFEES0COM',
                  //   sellingFeesTotalWithoutCommission,
                  //   'TOTALCOST',
                  //   totalCost,
                  //   'MEMBERPRICE',
                  //   memberPrice,
                  //   // 'MEMBERPROFIT',
                  //   // estimatedMemberProfit,
                  // )

                  form.batch(() => {
                    form.change('totalCost', totalCost)
                    form.change('totalFees', sellingFeesTotal)
                    form.change('estimatedProfit', estimatedProfit)
                    // form.change('expressPrice', expressPrice)
                    form.change('memberPrice', memberPrice)
                    form.change('memberExpressPrice', memberExpressPrice)
                    // form.change('estimatedMemberProfit', estimatedMemberProfit)
                  })
                }}
              />
              <TextInput
                className={classes.textInput}
                variant="standard"
                fullWidth
                disabled
                source="consumerCommissionDestination"
                label="N/A"
              />
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" alignItems="flex-start" mt="20px">
            <Box display="flex">
              <NumberFieldInput
                className={classes.fieldInputNarrow}
                label="Partner fees:"
                value={values?.consumerPartnerFees}
                resource="saleable_product"
                field="consumer_partner_fees"
                formField="consumerPartnerFees"
                defaultValue={0}
                decimalScale={2}
                fixedDecimalScale
                parseMultiplier={100}
                suffix="%"
                id={values.id}
                onChange={(e) => {
                  const newFees = Number(e.target.value.slice(0, -1))

                  // console.log('CHGVALS', values)
                  // console.log(totalFees)
                  // console.log(values.commissionFees)
                  // console.log(values.marketingFees)
                  // console.log(values.retailPrice)
                  // console.log(newFees)
                  // console.log(values.consumerPaymentFees)
                  // console.log(values.consumerServiceFees)
                  // console.log(values.consumerSoftwareFees)
                  // console.log(values.consumerRoyaltiesFees)
                  const sellingFeesTotal = GetCalculatedIncomeFields({
                    commissionFees: values.commissionFees,
                    marketingFees: values.marketingFees,
                    retailPrice: values.retailPrice,
                    consumerPartnerFees: newFees,
                    consumerPaymentFees: values.consumerPaymentFees,
                    consumerServiceFees: values.consumerServiceFees,
                    consumerSoftwareFees: values.consumerSoftwareFees,
                    consumerRoyaltiesFees: values.consumerRoyaltiesFees,
                  })
                  // console.log(sellingFeesTotal)

                  const estimatedProfit = roundNumber(
                    values.retailPrice -
                      sellingFeesTotal -
                      values.dropshippingEstimate -
                      values.fobPrice,
                    2,
                  )

                  // const estimatedMemberProfit =
                  //   values.memberPrice -
                  //   sellingFeesTotal -
                  //   values.dropshippingEstimate -
                  //   values.fobPrice

                  const newPrice = values.dropshippingEstimate + values.fobPrice + sellingFeesTotal

                  // console.log('NEWTOTALCOST', newPrice)

                  form.batch(() => {
                    form.change('totalCost', newPrice)
                    form.change('totalFees', sellingFeesTotal)
                    // form.change('estimatedMemberProfit', estimatedMemberProfit)
                    form.change('estimatedProfit', estimatedProfit)
                  })
                }}
              />
              <TextFieldInput
                variant="standard"
                fullWidth
                field="consumer_partner_fees_destination"
                resource="saleable_product"
                id={values.id}
                label="Destination:"
                // validate={required()}
                value={values.consumer_partner_fees_destination}
                className={classes.fieldInputLeft}
              />
            </Box>
            <Box display="flex">
              <NumberFieldInput
                className={classes.fieldInputLeftNarrow}
                label="Service fees:"
                value={values?.consumerServiceFees}
                resource="saleable_product"
                field="consumer_service_fees"
                formField="consumerServiceFees"
                defaultValue={0}
                decimalScale={2}
                fixedDecimalScale
                parseMultiplier={100}
                suffix="%"
                id={values.id}
                onChange={(e) => {
                  const newFees = Number(e.target.value.slice(0, -1))

                  const sellingFeesTotal = GetCalculatedIncomeFields({
                    commissionFees: values.commissionFees,
                    marketingFees: values.marketingFees,
                    retailPrice: values.retailPrice,
                    consumerPartnerFees: values.consumerPartnerFees,
                    consumerPaymentFees: values.consumerPaymentFees,
                    consumerServiceFees: newFees,
                    consumerSoftwareFees: values.consumerSoftwareFees,
                    consumerRoyaltiesFees: values.consumerRoyaltiesFees,
                  })

                  const estimatedProfit = roundNumber(
                    values.retailPrice -
                      sellingFeesTotal -
                      values.dropshippingEstimate -
                      values.fobPrice,
                    2,
                  )

                  // const estimatedMemberProfit =
                  //   values.memberPrice -
                  //   sellingFeesTotal -
                  //   values.dropshippingEstimate -
                  //   values.fobPrice

                  const newPrice = values.dropshippingEstimate + values.fobPrice + sellingFeesTotal

                  // console.log('NEWTOTALCOST', newPrice)

                  form.batch(() => {
                    form.change('totalCost', newPrice)
                    form.change('totalFees', sellingFeesTotal)
                    form.change('estimatedProfit', estimatedProfit)
                    // form.change('estimatedMemberProfit', estimatedMemberProfit)
                  })
                }}
              />
              <TextFieldInput
                variant="standard"
                fullWidth
                field="consumer_service_fees_destination"
                resource="saleable_product"
                id={values.id}
                label="Destination:"
                // validate={required()}
                value={values.consumer_service_fees_destination}
                className={classes.fieldInputLeft}
              />
            </Box>
            <Box display="flex">
              <NumberFieldInput
                className={classes.fieldInputNarrow}
                label="Royalties & Licensing fees:"
                value={values?.consumerRoyaltiesFees}
                resource="saleable_product"
                field="consumer_royalties_fees"
                formField="consumerRoyaltiesFees"
                defaultValue={0}
                decimalScale={2}
                fixedDecimalScale
                parseMultiplier={100}
                suffix="%"
                id={values.id}
                onChange={(e) => {
                  // console.log(values.retailPrice)
                  const newFees = Number(e.target.value.slice(0, -1))
                  const sellingFeesTotal = GetCalculatedIncomeFields({
                    commissionFees: values.commissionFees,
                    marketingFees: values.marketingFees,
                    retailPrice: values.retailPrice,
                    consumerPartnerFees: values.consumerPartnerFees,
                    consumerPaymentFees: values.consumerPaymentFees,
                    consumerServiceFees: values.consumerServiceFees,
                    consumerSoftwareFees: values.consumerSoftwareFees,
                    consumerRoyaltiesFees: newFees,
                  })

                  const estimatedProfit = roundNumber(
                    values.retailPrice -
                      sellingFeesTotal -
                      values.dropshippingEstimate -
                      values.fobPrice,
                    2,
                  )

                  // const estimatedMemberProfit =
                  //   values.memberPrice -
                  //   sellingFeesTotal -
                  //   values.dropshippingEstimate -
                  //   values.fobPrice

                  const newPrice = values.dropshippingEstimate + values.fobPrice + sellingFeesTotal

                  // console.log(
                  //   'DROPSHIPPING',
                  //   values.dropshippingEstimate,
                  //   'ESTIMATEDPROFIT',
                  //   estimatedProfit,
                  //   'FOBPRICE',
                  //   values.fobPrice,
                  //   'RETAILPRICE',
                  //   values.retailPrice,
                  //   'SELLFEES',
                  //   sellingFeesTotal,
                  //   'TOTALCOST',
                  //   newPrice,
                  // )

                  form.batch(() => {
                    form.change('totalCost', newPrice)
                    form.change('totalFees', sellingFeesTotal)
                    // form.change('estimatedMemberProfit', estimatedMemberProfit)
                    form.change('estimatedProfit', estimatedProfit)
                  })
                }}
              />
              <TextFieldInput
                variant="standard"
                fullWidth
                field="consumer_royalties_fees_destination"
                resource="saleable_product"
                id={values.id}
                label="Destination:"
                // validate={required()}
                value={values.consumer_royalties_fees_destination}
                className={classes.fieldInputLeft}
              />
            </Box>
            <Box display="flex" width="100%" justifyContent="space-between">
              <NumberFormat
                className={`MuiFormControl-root ${classes.numberInputLeft}`}
                displayType="input"
                thousandSeparator
                label="Dropshipping fees:"
                decimalScale={2}
                fixedDecimalScale
                isNumericString
                prefix="$ "
                source="dropshippingEstimate"
                disabled
              />
              <TextFieldInput
                variant="standard"
                fullWidth
                field="dropshipping_fees_destination"
                resource="saleable_product"
                id={values.id}
                label="Destination:"
                // validate={required()}
                value={values.dropshipping_fees_destination}
                className={classes.fieldInputLeft}
              />
            </Box>
            <Box display="flex" width="100%">
              <NumberFormat
                className={`MuiFormControl-root ${classes.numberInputLeft}`}
                displayType="input"
                thousandSeparator
                label="Courier estimate:"
                decimalScale={2}
                fixedDecimalScale
                isNumericString
                prefix="$ "
                source="courierEstimate"
                disabled
              />
              {/* 
              <NumberFieldInput
                className={classes.fieldInputNarrow}
                label="Courier estimate:"
                value={values?.courierEstimate}
                resource="saleable_product"
                field="courier_estimate"
                formField="courierEstimate"
                defaultValue={0}
                decimalScale={2}
                fixedDecimalScale
                parseMultiplier={100}
                prefix="$ "
                id={values.id}
                onChange={(e) => {
                  const newPrice = Number(e.target.value.slice(2))
                  const expressPrice = values.retailPrice - values.dropshippingEstimate + newPrice

                  form.change('expressPrice', expressPrice)
                }}
              /> */}
              <TextFieldInput
                variant="standard"
                fullWidth
                field="courier_estimate_destination"
                resource="saleable_product"
                id={values.id}
                label="Destination:"
                // validate={required()}
                value={values.courier_estimate_destination}
                className={classes.fieldInputLeft}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default PricingDetails
