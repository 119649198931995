import gql from 'graphql-tag'

export const saleableProductSalesAllocationQuery = `
  id
  amount
  profit
  quantity
  app_user_inventory_id
  inventory_owner_id
  offer_id
  organisation_inventory_id
  organisation_inventory {
    id
    owner {
      id
      name
      owner_user_id
    }
  }
  saleable_product_sale_id
  saleable_product_sale {
    id
    created_at
    updated_at
    customer_id
    platform
  }
`

export const saleableProductSalesAllocationGet = ({ context, params }) => {
  return {
    ...context,
    variables: {},
    query: gql`
      query getSaleableProductSalesAllocation {
        saleable_product_sale_allocation(id: ${params.id}) {
          ${saleableProductSalesAllocationQuery}
        }
      }
    `,
    parseResponse: ({ data }) => {
      if (data && data.saleable_product_sale_allocation) {
        return {
          data: data.saleable_product_sale_allocation,
        }
      }
      return false
    },
  }
}

export const saleableProductSalesAllocationList = ({ context, params }) => {
  const offset = (params.pagination.page - 1) * params.pagination.perPage
  const variables = {}

  if (params.filter.offer_id || params.id) {
    variables.where = {
      ...(params.filter.id && { id: { _eq: params.filter.id } }),
      ...(params.filter.offer_id && { offer_id: { _eq: params.filter.offer_id } }),
    }
  }

  return {
    ...context,
    variables,
    query: gql`
            query getSaleableProductSalesAllocationsList ($where: saleable_product_sale_allocation_bool_exp) {
             saleable_product_sale_allocation(where: $where, limit: ${
               params.pagination.perPage
             }, offset: ${offset}, order_by: { ${
      params.sort.field
    }: ${params.sort.order.toLowerCase()} }) {
                ${saleableProductSalesAllocationQuery}
              }
              saleable_product_sale_allocation_aggregate (where: $where) {
                aggregate {
                  count
                }
              }
            }
          `,
    parseResponse: ({ data }) => {
      if (data && data.saleable_product_sale_allocation) {
        return {
          data: data.saleable_product_sale_allocation,
          total: data.saleable_product_sale_allocation_aggregate.aggregate.count,
        }
      }
      return false
    },
  }
}
