import gql from 'graphql-tag'

export const saleableProductSaleGetQuery = `
    id
    created_at
    updated_at
    customer_id
    seller_id
    stripe_transaction_code
    quantity
    unit_price
    amount
    courier_fees
    status
    platform
    delivery_address
    contact_name
    contact_email
    contact_phone
    options
    order_id
    product_variant_id
    retail_unit_price
    saleable_product_id
    product_variant {
      id
      variant {
        id
        sku
        name
        values {
          id
          option_name
          value
        }
      }
    }
    order {
        id
        status
        platform
    }
    saleable_product_sale_allocations {
      id
      quantity
      amount
      profit
      inventory_owner_id
      offer_id
      owner_app_user_id {
        id
        email
      }
    }
    allocationsCount: saleable_product_sale_allocations_aggregate {
      aggregate {
        count
      }
    }
    allocationsQuantity: saleable_product_sale_allocations_aggregate {
      aggregate {
        sum {
          quantity
        }
      }
    }
    allocationsProfit: saleable_product_sale_allocations_aggregate {
      aggregate {
        sum {
          profit
        }
      }
    }
    allocationsAmount: saleable_product_sale_allocations_aggregate {
      aggregate {
        sum {
          amount
        }
      }
    }
    saleable_product {
        id
        name
          primary_photo {
            id
            filename
            url
        }
    }
`

export const saleableProductSalesGet = ({ context, params }) => {
  return {
    ...context,
    variables: {},
    query: gql`
      query getSaleableProduct {
        saleable_product_sale_by_pk(id: ${params.id}) {
          ${saleableProductSaleGetQuery}
        }
      }
      `,

    parseResponse: ({ data }) => {
      if (data && data.saleable_product_sale_by_pk) {
        return {
          data: data.saleable_product_sale_by_pk,
        }
      }
      return false
    },
  }
}

export const saleableProductSalesList = ({ params, context }) => {
  const page = params.pagination?.page || 1
  const perPage = params.pagination?.perPage || 0
  const offset = (page - 1) * perPage
  const variables = { where: params.filter }

  return {
    ...context,
    variables,
    query: gql`
        query getSaleableProductSales ($where: saleable_product_sale_bool_exp) {
          saleable_product_sale(where: $where, limit: ${perPage}, offset: ${offset}, order_by: { ${
      params.sort.field
    }: ${params.sort.order.toLowerCase()} }) {
              ${saleableProductSaleGetQuery}
            }
            saleable_product_sale_aggregate(where: $where) {
              aggregate {
                count
              }
            }
          }
        `,
    parseResponse: ({ data }) => ({
      data: data.saleable_product_sale,
      total: data.saleable_product_sale_aggregate.aggregate.count,
    }),
  }
}

export const saleableProductSalesCreate = ({ context, params }) => {
  let jsonParams = JSON.stringify(params.data)
  jsonParams = jsonParams.replace(/"(\w+)":/g, '$1:')
  jsonParams = jsonParams.substr(1, jsonParams.length - 2)
  jsonParams = jsonParams.replace(/"\^/g, '')
  jsonParams = jsonParams.replace(/\^"/g, '')
  return {
    ...context,
    // variables: params,
    query: gql`
      mutation createCustomerSale {
        createCustomerOrderAdmin(
      args: {
        ${jsonParams}
      }
      ) {
        consumerOrderId
        orderNumber
        paymentIntentId
      }
    }`,

    parseResponse: ({ data }) => {
      if (data && data.createCustomerOrderAdmin) {
        return {
          data: {
            id: data.createCustomerOrderAdmin?.consumerOrderId,
            ...data.createCustomerOrderAdmin,
          },
        }
      }
      return false
    },
  }
}

export const saleableProductSalesConfirm = ({ context, params }) => {
  // console.log(params)
  return {
    ...context,
    variables: {
      paymentIntentId: params.data?.paymentIntentId,
      paymentType: 'customer_purchase',
      type: 'payment_intent.succeeded',
    },
    query: gql`
      mutation consumerOrderConfirm(
        $paymentType: String!
        $paymentIntentId: String!
        $type: String!
      ) {
        consumerOrderConfirm(
          arg1: { type: $type, paymentIntentId: $paymentIntentId, paymentType: $paymentType }
        ) {
          status
        }
      }
    `,
    parseResponse: ({ data }) => {
      if (data && data.consumerOrderConfirm) {
        return {
          data: {
            id: params.data?.paymentIntentId,
            ...data.consumerOrderConfirm,
          },
        }
      }
      return false
    },
  }
}

export const saleableProductSalesApprovePayment = ({ context, params }) => {
  // console.log(params)
  return {
    ...context,
    variables: {
      consumerOrderId: params.data?.consumerOrderId,
    },
    query: gql`
      mutation consumerOrderApprovePayment($consumerOrderId: Int!) {
        update_saleable_product_sale(
          where: { order: { id: { _eq: $consumerOrderId } } }
          _set: { status: COMPLETED, platform: ADMIN }
        ) {
          affected_rows
        }
        update_consumer_order_by_pk(
          pk_columns: { id: $consumerOrderId }
          _set: { status: COMPLETED }
        ) {
          id
        }
      }
    `,
    parseResponse: () => {
      return {
        data: {
          id: params.data?.consumerOrderId,
        },
      }
    },
  }
}
