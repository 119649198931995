/* eslint-disable no-unreachable */
import React, { useState, useEffect, useMemo } from 'react'
import { Box } from '@material-ui/core'
import {
  Edit,
  FormWithRedirect,
  useDataProvider,
  useNotify,
  // useRedirect,
  useGetIdentity,
} from 'react-admin'
import FormData from './components/SCMQuoteEditForm'
import moment from 'moment'
// import ProductDetailsView from './components/ProductDetailsView'
// import useStyles from './styles'
import { uniqueId } from 'lodash'
// import { roundNumber } from '../../utils/utils'
import uploadFile from '../../dataProvider/upload'

const SCMQuoteEdit = (props) => (
  <Edit
    {...props}
    actions={null}
    component="div"
    id={props.match.params.id}
    mutationMode="pessimistic"
  >
    <SCMQuoteEditForm id={props.match.params.id} tab={props.match.params.tab} />
  </Edit>
)

const SCMQuoteEditForm = (props) => {
  const dataProvider = useDataProvider()
  const notify = useNotify()
  // const redirect = useRedirect()
  // const classes = useStyles()
  const { identity } = useGetIdentity()
  const [milestones, setMilestones] = useState([])
  const [config, setConfig] = useState()

  const { record } = props

  useEffect(() => {
    const getDataLists = async () => {
      const debugMode = false
      debugMode && console.log('GETTING DATA LISTS')
      try {
        const { data: milestonesTemp } = await dataProvider.getList('paymentMilestones', {})
        !abortController.signal.aborted &&
          setMilestones(milestonesTemp.filter((item) => item.type === 'client'))

        const { data: configTemp } = await dataProvider.globalConfig('globalConfig', {
          fields: [
            'estimated_lead_time_sea',
            'estimated_lead_time_air',
            'estimated_freight_rate_sea',
            'estimated_freight_rate_air',
            'estimated_container_rate_20ft',
            'estimated_container_rate_40ft',
            'estimated_container_rate_40ft_hc',
            'estimated_freight_rate_sea_duties_included',
          ],
        })
        const config = {}
        configTemp.global_preferences.forEach(
          (item) => (config[item.setting_key] = item.setting_value),
        )
        !abortController.signal.aborted && setConfig(config)
      } catch (error) {
        console.log(error)
        notify(error.message.split('.')[0], { type: 'warning' })

        debugMode && console.timeEnd('SAVING DATA TIMER')
        debugMode && console.groupEnd()
        return
      }
    }

    const abortController = new AbortController()
    getDataLists()
    return () => {
      abortController.abort()
    }
  }, [])

  /*
    Quantity (moq) / Investor Price Per Piece / Logistics Lead Time
    Retail Price
  */

  const handleCreateOffer = async ({ submission, sortedPrices, dutiesMultiplier }) => {
    console.log('SUB', submission)
    // console.log('PRD', productData)

    const finalPrice = submission.retailPrice
      ? submission.retailPrice
      : calcUnitPrice({
          quantity: submission.orderVariants[0].quantity,
          data: submission.orderVariants[0],
          prices: sortedPrices,
          dutiesMultiplier,
          pkgOptions: submission.packagingSizes,
          colourModifier: submission.standardColourPriceModifier,
        })

    const activePrice = submission.prices.find(
      (item) => submission.orderVariants[0].quantity >= item?.quantity,
    )

    const mfgLeadTime = activePrice.production_lead_time || 0
    const freightLeadTime = activePrice.lead_time_sea || 0

    const selectedPkgOpt = submission.packagingSizes.find(
      (el) =>
        el.packagingType === submission.orderVariants[0]?.poPackagingOption &&
        el.sizeName === submission.orderVariants[0]?.size,
    )
    // console.log('PKGOPT', selectedPkgOpt)
    // const colourOpt = submission.productColours.find(
    //   (el) => el.id === submission.orderVariants.colour,
    // )
    // console.log('COLOPT', colourOpt)

    // const totalLeadTime = mfgLeadTime + freightLeadTime

    try {
      const params = {
        name: submission.product_quote?.product?.name || '',
        short_description: submission.product_quote?.product?.short_description || null,
        long_description: submission.product_quote?.product?.short_description || null,
        primary_photo_id:
          submission.product_quote?.product?.primary_photo?.id ||
          submission.product_quote?.product?.thumbnail_photo?.id ||
          null,
        thumbnail_photo_id:
          submission.product_quote?.product?.thumbnail_photo?.id ||
          submission.product_quote?.product?.primary_photo?.id ||
          null,
        hts_code: submission.product_quote?.product?.hts_code || null,
        quote_id: submission.product_quote?.id,
        client_quote_id: submission.id,
        freight_method: 'Sea Freight',
        type: '^PUBLIC^',
        incoterms: 'DDP',
        moq: submission.orderVariants[0].quantity,
        investor_price_per_piece: Math.round(finalPrice * 100),
        status: '^PENDING_SUBMISSION^',
        marketing_agency_id: process.env.REACT_APP_DEFAULT_MKT || 1,
        sca_id: process.env.REACT_APP_DEFAULT_ADMIN || 377,
        manufacturing_lead_time: mfgLeadTime,
        logistics_lead_time: freightLeadTime,
        duty_rate: submission.dutyRate * 100,
        product_dimensions_id: selectedPkgOpt.product_dimensions_id,
        packaging_dimensions_id: selectedPkgOpt.packaging_dimensions_id,
        carton_dimensions_id: selectedPkgOpt.carton_dimensions_id,
        pieces_per_carton: selectedPkgOpt.pieces_per_carton,
        investor_income: Math.round(submission.grossMarginUnit * 100),
        investor_profit: Math.round(submission.clientCostUnit * 100),
      }

      console.log(params)

      await dataProvider.create('offer', { objects: params })

      // console.log('RESP', resp)
    } catch (error) {
      console.log(error)
      notify(error.message.split('.')[0], { type: 'warning' })
      return error
    }
  }

  const handleCreateOrder = async ({ submission }) => {
    // console.log('SUB', submission)
    // console.log('PRD', productData)

    try {
      const poFile = submission.poFile ? await uploadFile([submission.poFile]) : null

      const params = {
        buyerId: 152,
        clientId: submission.poClientId,
        billingAddressId: submission.billingAddressId,
        deliveryAddressId: submission.deliveryAddressId,
        notes: submission.notes || '',
        poFileURL: poFile?.data?.files[0]?.url || '',
        poFileName: submission.poFile?.filename || '',
        clientSKU: submission.clientSKU || '',
        ...(submission.poNumber && { poNumber: submission.poNumber }),
        platform: 'ADMIN',
        items: [
          {
            clientQuoteId: submission.id,
            variants: submission.orderVariants?.map((variant) => {
              const selectedPkgOpt = submission.packagingSizes.find(
                (el) =>
                  el.packagingType === variant?.poPackagingOption && el.sizeName === variant?.size,
              )
              // console.log('PKGOPT', selectedPkgOpt)
              const colourOpt = submission.productColours.find((el) => el.id === variant.colour)
              // console.log('COLOPT', colourOpt)
              const options = []
              selectedPkgOpt &&
                options.push({
                  option_id: selectedPkgOpt.pkgId,
                  type: 'Packaging',
                  value: selectedPkgOpt.packagingType,
                })
              selectedPkgOpt &&
                options.push({
                  option_id: selectedPkgOpt.sizeId,
                  type: 'Size',
                  value: selectedPkgOpt.sizeName,
                })
              colourOpt &&
                options.push({
                  option_id: colourOpt.componentId,
                  type: 'Colour',
                  value: colourOpt.value,
                })
              // console.log('OPTS', options)

              return {
                options,
                quantity: variant.quantity,
                isSampleOrder: variant.isSampleVariant,
                incoterms: variant.incoterms,
                shippingMethod: variant.incoterms === 'FOB' ? 'FOB' : variant.shippingMethod,
              }
            }),
          },
        ],
      }

      await dataProvider.getPaymentIntent('catalogue', params)

      // console.log('RESP', resp)
    } catch (error) {
      console.log(error)
      notify(error.message.split('.')[0], { type: 'warning' })
      return error
    }
  }

  const calcUnitPrice = ({
    data,
    prices,
    dutiesMultiplier,
    pkgOptions,
    colourModifier,
    quantity,
  }) => {
    const selectedPkgOpt = pkgOptions.find(
      (el) => el.packagingType === data?.poPackagingOption && el.sizeName === data?.size,
    )

    // console.log('DATA', data)

    const activePrice = prices.find((item) => quantity >= item?.quantity)?.fobPrice

    // console.log('ACTIVEFOBPRICE', activePrice)
    // console.log('SELPKGOPT', selectedPkgOpt)
    const basePrice =
      activePrice +
      (colourModifier || 0) +
      (selectedPkgOpt?.packagingModifier || 0) +
      (selectedPkgOpt?.sizeModifier || 0)
    // console.log('BASEPRICE', basePrice)

    const dutiesPrice = basePrice * dutiesMultiplier

    // console.log('DUTIESPRICE', dutiesPrice)

    const lclCost =
      data?.incoterms === 'FOB'
        ? 0
        : data?.shippingMethod === 'Air Freight'
        ? selectedPkgOpt?.lclAir || 0
        : selectedPkgOpt?.lclSea || 0

    // console.log('LCL', lclCost)

    const finalPrice = dutiesPrice + lclCost

    // console.log('FINALPRICE', finalPrice)

    return finalPrice
  }

  const factoryPriceList = record?.product_quote?.prices

  const factoryPrices = factoryPriceList?.map((item) => ({
    ...(item.id && { id: item.id }),
    factoryUnitPrice: item.unit_price / 100 || item.unitPrice,
    factoryLeadTime: item.lead_time || item.production_lead_time,
    factoryQuantity: item.quantity,
    factoryLandedAir: item.est_landed_cost_air / 100 || item.landed_air || null,
    factoryLandedSea: item.est_landed_cost_sea / 100 || item.landed_sea || null,
    factoryTotalCBM: item.total_cbm / 100 || item.totalCBM || null,
    factoryShipmentWeight: item.shipment_weight / 100 || item.shipmentWeight || null,
    factoryTotalNumCartons: item.total_cartons || item.totalNumCartons,
    factoryTotalDuties: item.total_duties / 100 || item.totalDuties || null,
    factoryFreightCostPerPieceAir:
      item.freight_cost_air_per_piece / 100 || item.freightCostPerPieceAir || null,
    factoryFreightCostPerPieceSea:
      item.freight_cost_sea_per_piece / 100 || item.freightCostPerPieceSea || null,
    factoryTotalFreightCostAir: item.freight_cost_air / 100 || item.totalFreightCostAir || null,
    factoryTotalFreightCostSea: item.freight_cost_sea / 100 || item.totalFreightCostSea || null,
    factoryContainers: item.containers,
  }))

  const cqOptionsList = []

  // clientQuoteData.options?.map((opt) => {
  //   cqOptionsList.push({
  //     id: opt.id,
  //   })
  // })

  const components = []

  // console.log(components)

  record?.product_quote?.components?.map((comp) => {
    // console.log(comp)
    const cqComp = record?.product_components.find(
      (el) => el.product_quote_component_id === comp.id,
    )
    if (!cqComp) {
      components.push({
        ...comp,
        id: `C${uniqueId()}`,
        product_quote_component_id: comp.id,
      })
    } else {
      components.push(cqComp)
    }
  })

  record?.product_quote?.options?.map((conf) => {
    // console.log('CONFIG', conf)
    // console.log('CQOPTS', record?.options)
    const cqOpt = record?.options?.find((el) => el.product_quote_option_id === conf.id)

    const baseOpt = cqOpt ? cqOpt : conf

    cqOptionsList.push({
      ...baseOpt,
      id: cqOpt?.id || `O${conf.id}`,
      additionalCostPerChoice: baseOpt.additional_cost_per_unique_choice / 100,
      product_quote_option_id: conf.id,
      values: conf.values.map((el) => {
        // console.log('PQVAL', el)

        // console.log('CQVALS', cqOpt?.values)
        const cqVal = cqOpt?.values?.find((item) => item.product_quote_option_value_id === el.id)

        const baseVal = cqVal || el

        return {
          ...baseVal,
          id: cqVal?.id || `V${el.id}`,
          product_quote_option_value_id: el.id,
          unitCost: cqVal?.price / 100 || el.cost / 100 || 0,
          setupCost: cqVal?.setup / 100 || el.setup / 100 || 0,
          components: components?.map((comp) => {
            // console.log('PQCOMP', comp)
            // console.log('CQVALCOMPS', cqVal?.component_options)

            const cqComp = cqVal?.component_options?.find((obj) => obj.component?.id === comp.id)

            // console.log('cqComp', cqComp)

            if (cqComp) {
              return {
                ...cqComp.component,
                componentRefId: cqComp.id,
                available: true,
              }
            } else {
              return {
                ...comp,
                available: false,
              }
            }
          }),
        }
      }),
    })
  })

  // console.log('OPTIONSLIST', cqOptionsList)

  const cqPkgSizesList = []

  record?.scm_quote_packaging_options?.map((pkgOpt) => {
    pkgOpt.scm_quote_product_size_packagings?.map((size) => {
      // console.log('cqpk', pkgOpt, 'cqsz', size)
      cqPkgSizesList.push({
        id: size.id,
        pkgId: pkgOpt.id,
        rowIndex: cqPkgSizesList.length,
        sizeId: size?.scm_quote_product_size?.id,
        lclSea: pkgOpt.sea_lcl / 100 || 0,
        lclAir: pkgOpt.air_lcl / 100 || 0,
        lclSeaBuffer: pkgOpt.sea_buffer / 100 || 0,
        lclAirBuffer: pkgOpt.air_buffer / 100 || 0,
        airModifier: pkgOpt.air_modifier / 100 || 0,
        seaModifier: pkgOpt.sea_modifier / 100 || 0,
        carton_dimensions_id: pkgOpt.carton_dimensions?.id,
        carton_dimensions: {
          id: pkgOpt.carton_dimensions?.id,
          length: pkgOpt.carton_dimensions?.length,
          height: pkgOpt.carton_dimensions?.height,
          width: pkgOpt.carton_dimensions?.width,
          gross_weight: pkgOpt.carton_dimensions?.gross_weight,
        },
        packaging_dimensions: {
          id: pkgOpt.packaging_dimensions?.id,
          length: pkgOpt.packaging_dimensions?.length,
          height: pkgOpt.packaging_dimensions?.height,
          width: pkgOpt.packaging_dimensions?.width,
          gross_weight: pkgOpt.packaging_dimensions?.gross_weight,
        },
        product_dimensions: {
          id: pkgOpt.product_dimensions?.id,
          length: pkgOpt.product_dimensions?.length,
          height: pkgOpt.product_dimensions?.height,
          width: pkgOpt.product_dimensions?.width,
          gross_weight: pkgOpt.product_dimensions?.gross_weight,
        },
        packaging_dimensions_id: pkgOpt.packaging_dimensions?.id,
        product_dimensions_id: size?.scm_quote_product_size?.dimensions?.id,
        sizeMOQ: size?.scm_quote_product_size?.moq,
        sizeModifier: size?.scm_quote_product_size?.price_modifier / 100,
        sizeName: size?.scm_quote_product_size?.name || 'Base Size',
        sizeShortName: size?.scm_quote_product_size?.short_name || 'Base',
        packagingType: pkgOpt.type,
        packagingLabel: pkgOpt.label,
        packagingMOQ: pkgOpt.moq,
        packagingModifier: pkgOpt.price_modifier / 100,
        pieces_per_carton: pkgOpt.pieces_per_carton,
        available: true,
        packagingFactoryReferenceId: pkgOpt.product_quote_packaging_option_id,
        sizeFactoryReferenceId: size?.scm_quote_product_size?.product_quote_product_size?.id,
        packagingFactoryMOQ: pkgOpt.product_quote_packaging_option?.moq,
        packagingFactoryModifier: pkgOpt.product_quote_packaging_option?.cost_modifier / 100,
        sizeFactoryMOQ: size?.scm_quote_product_size?.product_quote_product_size?.moq,
        sizeFactoryModifier:
          size?.scm_quote_product_size?.product_quote_product_size?.cost_modifier / 100,
        default: record?.default_packaging_option_id === size.id,
      })
    })
  })

  record?.product_quote?.packaging_options?.map((pkgOpt) => {
    // console.log(pkgOpt)
    pkgOpt.product_quote_product_size_packagings?.map((size) => {
      // console.log('pqpk', pkgOpt, 'pqsz', size)
      // console.log(size)
      if (
        !cqPkgSizesList.some(
          (cqPkg) =>
            cqPkg.sizeFactoryReferenceId === size?.product_size?.id &&
            cqPkg.packagingFactoryReferenceId === pkgOpt?.id,
        )
      ) {
        cqPkgSizesList.push({
          id: `N${uniqueId()}`,
          pkgId: pkgOpt.id || null,
          rowIndex: cqPkgSizesList.length,
          sizeId: size?.product_size?.id || null,
          lclSea: null,
          lclAir: null,
          airModifier: 0,
          seaModifier: 0,
          lclSeaBuffer: 0,
          lclAirBuffer: 0,
          carton_dimensions_id: pkgOpt.carton_dimensions?.id,
          carton_dimensions: {
            id: pkgOpt.carton_dimensions?.id,
            length: pkgOpt.carton_dimensions?.length,
            height: pkgOpt.carton_dimensions?.height,
            width: pkgOpt.carton_dimensions?.width,
            gross_weight: pkgOpt.carton_dimensions?.gross_weight,
          },
          packaging_dimensions: {
            id: pkgOpt.packaging_dimensions?.id,
            length: pkgOpt.packaging_dimensions?.length,
            height: pkgOpt.packaging_dimensions?.height,
            width: pkgOpt.packaging_dimensions?.width,
            gross_weight: pkgOpt.packaging_dimensions?.gross_weight,
          },
          product_dimensions: {
            id: pkgOpt.product_dimensions?.id,
            length: pkgOpt.product_dimensions?.length,
            height: pkgOpt.product_dimensions?.height,
            width: pkgOpt.product_dimensions?.width,
            gross_weight: pkgOpt.product_dimensions?.gross_weight,
          },
          packaging_dimensions_id: pkgOpt.packaging_dimensions?.id,
          product_dimensions_id: size?.product_size?.dimensions?.id,
          sizeMOQ: null,
          sizeModifier: null,
          sizeName: size?.product_size?.name || 'Base Size',
          sizeShortName: size?.product_size?.short_name || 'Base',
          packagingType: pkgOpt.type,
          packagingLabel: pkgOpt.label,
          packagingMOQ: null,
          packagingModifier: null,
          pieces_per_carton: pkgOpt.pieces_per_carton,
          available: false,
          packagingFactoryReferenceId: pkgOpt.id,
          sizeFactoryReferenceId: size?.product_size?.id,
          packagingFactoryMOQ: pkgOpt?.moq,
          packagingFactoryModifier: pkgOpt?.cost_modifier / 100,
          sizeFactoryMOQ: size?.product_size?.moq,
          sizeFactoryModifier: size?.product_size?.cost_modifier / 100,
          default: false,
        })
      }
    })
  })

  // console.log('SIZESLIST', cqPkgSizesList)

  const productColours =
    (record?.product_quote?.components?.length > 0 &&
      record?.product_quote.components[0].colours?.map((colour) => {
        const scColour =
          record?.product_components?.length > 0 &&
          record?.product_components[0].colours?.find((scColour) => scColour.value === colour.value)

        return {
          componentId:
            record?.product_components?.length > 0 ? record?.product_components[0].id : null,
          pqId: colour.id,
          id: scColour?.id || `N${uniqueId()}`,
          value: colour.value,
          available: !!scColour,
        }
      })) ||
    []

  const clientDecorations = record?.product_quote.decorations?.map((item) => {
    const cqDeco = record?.decorations?.find((el) => el.product_quote_decoration_id === item.id)

    if (cqDeco) {
      const imprintStyles = item.imprint_styles?.map((el) => {
        const cqImp = cqDeco.imprint_styles?.find((imp) => imp === el)
        return { name: el, available: !!cqImp }
      })
      return {
        ...cqDeco,
        unitPrice: cqDeco.price / 100,
        imprintStyles,
        available: true,
      }
    }

    return {
      ...item,
      id: `D${uniqueId()}`,
      unitPrice: item.cost / 100,
      refId: item.id,
      available: false,
    }
  })

  // console.log(productColours)

  const clientPrices =
    record?.product_quote?.prices?.length > 0
      ? record?.prices?.map((item) => {
          // console.log(item)

          // const freightRateAir = record?.freight_rate_air / 100
          // const freightRateSea = record?.freight_rate_sea / 100
          const unitPrice = item.price / 100
          const fobMargin = 1 - item.margin_fob / 10000

          const defaultPackagingOption = cqPkgSizesList.find(
            (obj) => obj.id === record?.default_packaging_option_id,
          )
          // console.log(defaultPackagingOption)

          // const grossWeight =
          //   defaultPackagingOption?.scm_quote_packaging_option?.carton_dimensions?.gross_weight /
          //     100 || defaultPackagingOption?.carton_dimensions?.gross_weight / 100
          // const cartonLength =
          //   defaultPackagingOption?.scm_quote_packaging_option?.carton_dimensions?.length / 100 ||
          //   defaultPackagingOption?.carton_dimensions?.length / 100
          // const cartonWidth =
          //   defaultPackagingOption?.scm_quote_packaging_option?.carton_dimensions?.width / 100 ||
          //   defaultPackagingOption?.carton_dimensions?.width / 100
          // const cartonHeight =
          //   defaultPackagingOption?.scm_quote_packaging_option?.carton_dimensions?.height / 100 ||
          //   defaultPackagingOption?.carton_dimensions?.height / 100
          // const piecesPerCarton =
          //   defaultPackagingOption?.scm_quote_packaging_option?.pieces_per_carton ||
          //   defaultPackagingOption?.pieces_per_carton
          const seaLCL =
            defaultPackagingOption?.scm_quote_packaging_option?.sea_lcl / 100 ||
            Number(defaultPackagingOption?.lclSea) ||
            0
          const airLCL =
            defaultPackagingOption?.scm_quote_packaging_option?.air_lcl / 100 ||
            Number(defaultPackagingOption?.lclAir) ||
            0

          const modifiers =
            (defaultPackagingOption?.packagingModifier || 0) +
            (defaultPackagingOption?.sizeModifier || 0) +
            (record?.standardColourPriceModifier || 0)

          // console.log(
          //   'unitPrice',
          //   unitPrice,
          //   'fobMargin',
          //   fobMargin,
          //   'modifiers',
          //   modifiers,
          //   'airLCL',
          //   airLCL,
          //   'seaLCL',
          //   seaLCL,
          // )

          const basePrice = Number(((unitPrice + modifiers) / fobMargin).toFixed(2))

          // const dutyRate = values.duty_rate / 10000

          // const dutiesPerPiece = basePrice * dutyRate
          // form.change(`prices[${index}].totalDuties`, dutiesPerPiece)

          // console.log('basePrice', basePrice) //'dutyRate', dutyRate, 'dutiesPerPiece', dutiesPerPiece)

          const fobPrice = Number(basePrice) // + dutiesPerPiece).toFixed(2))

          const seaAdjuster = item.seaAdjustment / 100 || 0
          const airAdjuster = item.airAdjustment / 100 || 0
          const seaAdditions = Number(seaLCL) + Number(seaAdjuster)
          const airAdditions = Number(airLCL) + Number(airAdjuster)

          const dutiesPerPiece = (record?.duty_rate / 10000) * fobPrice
          // const numCartons = item.quantity / piecesPerCarton

          // const seaWeight =
          //   parseInt((((cartonLength * cartonWidth * cartonHeight) / 6000) * 100).toFixed(2)) ||
          //   null

          // const chargeableSeaWeight = seaWeight / 100 > grossWeight ? seaWeight / 100 : grossWeight
          // const airWeight =
          //   parseInt((((cartonLength * cartonWidth * cartonHeight) / 5000) * 100).toFixed(2)) ||
          //   null

          // const chargeableAirWeight = airWeight / 100 > grossWeight ? airWeight / 100 : grossWeight

          // const airRate = chargeableAirWeight * numCartons * freightRateAir
          // const seaRate = chargeableSeaWeight * numCartons * freightRateSea

          const estLandedRateSea = Number(
            (fobPrice + Number(seaAdditions)) //+
              // (clientQuoteData.does_sea_freight_include_duties ? 0 : dutiesPerPiece) +
              // seaRate / item.quantity
              .toFixed(2),
          )
          const estLandedRateAir = Number(
            (fobPrice + Number(airAdditions) + dutiesPerPiece) //+
              // airRate / item.quantity
              .toFixed(2),
          )

          const estProfit = ((fobPrice - unitPrice) * item.quantity).toFixed(2)

          return {
            id: item.id,
            quantity: item.quantity,
            lead_time_air: item.lead_time_air,
            lead_time_sea: item.lead_time_sea,
            unitPrice: item.price / 100,
            formFobPrice: fobPrice,
            fobPrice,
            estProfit,
            estimatedFreightAir: estLandedRateAir,
            estimatedFreightSea: estLandedRateSea,
            savedFobPrice: item.fob_price / 100,
            savedEstimatedFreightAir: item.estimated_freight_air / 100,
            savedEstimatedFreightSea: item.estimated_freight_sea / 100,
            client_production_lead_time: item.production_lead_time,
            seaAdjustment: item.adjustment_sea / 100,
            airAdjustment: item.adjustment_air / 100,
            // fobLocked: item.fob_price ? true : false,
            fobLocked: false,
            fobMargin: item.margin_fob / 100 || 0,
            // estimatedFreightAirLocked: item.estimated_freight_air ? true : false,
            estimatedFreightAirLocked: false,
            // estimatedFreightSeaLocked: item.estimated_freight_sea ? true : false,
            estimatedFreightSeaLocked: false,
          }
        })
      : []

  // console.log(record?)

  const initSCM = {
    ...record,
    clientDecorations,
    clientOptions: cqOptionsList,
    // factorySizes: clientQuoteProductData.packagingOptions,
    productColours,
    standardColourPriceModifier: record?.standard_colour_option_price_modifier / 100,
    packagingSizes: cqPkgSizesList,
    launch_date: record?.launch_date || moment().format('YYYY/MM/DD'),
    prices: clientPrices,
    clientSamplePrice: record?.client_sample_price / 100 || null,
    clientCustomSamplePrice: record?.client_custom_sample_price / 100 || null,
    quote_notes: record?.notes,
    factoryPrices,
    freightRateAir: record?.freight_rate_air / 100 || null,
    freightRateSea: record?.freight_rate_sea / 100 || null,
    containerRate20ft: record?.container_rate_20ft / 100 || null,
    containerRate40ft: record?.container_rate_40ft / 100 || null,
    containerRate40ftHC: record?.container_rate_40ft_hc / 100 || null,
    dutyRate: record?.duty_rate === 0 ? 0 : record?.duty_rate / 100 || 0,
    does_sea_freight_include_duties: record?.does_sea_freight_include_duties || false,
    poClientId: record?.client_id,
    incoterms: 'DDP',
    orderVariants: [{}],
  }

  // console.log('init', initSCM, config)

  const initValsSCM = useMemo(() => initSCM, [initSCM])

  if (!config) {
    return null
  }

  return (
    <FormWithRedirect
      {...props}
      validate={(values) => {
        // console.log(values)
        const errors = {}
        values.prices?.map((item) => {
          if (!item.fobPrice) {
            errors.quotes = 'Missing fob price'
          }
        })
        values?.payment_terms?.length < 1 ? (errors.payment_terms = 'Missing payment terms') : null
        return errors
      }}
      initialValues={initValsSCM}
      render={(formProps) => (
        <Box>
          <FormData
            handleCreateOrder={handleCreateOrder}
            handleCreateOffer={handleCreateOffer}
            calcUnitPrice={calcUnitPrice}
            milestones={milestones}
            config={config}
            isManager={identity?.isManager}
            formProps={formProps}
          />
        </Box>
      )}
    />
  )
}

export default SCMQuoteEdit
